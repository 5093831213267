import {
  afterMapStationaryWithDebounceCallback,
  generateMockDataForEmptySource,
  generateQueryIds,
  isLayerExist,
  layerSuccessfullyLoadedInDomCallback,
  removeLayer,
} from "../utils";
import { useEffect, useRef, useState } from "react";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import { MapSetting } from "../../../../types/infrastructure/infrastructureTypes";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Legend from "@arcgis/core/widgets/Legend.js";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import LabelClass from "@arcgis/core/layers/support/LabelClass";
import TextSymbol from "@arcgis/core/symbols/TextSymbol";

export type TmmsRankLayerLstStudyLocations = {
  geoId?: string;
  rin?: string;
  smoothMMS?: number;
}[];

type TProps = {
  showLayer: boolean;
  legend: Legend | null;
  map: Map | null;
  view: MapView | null;
  initDataGis: MapSetting | null | undefined;
  lstStudyLocations: TmmsRankLayerLstStudyLocations;
  roadSegmentLayer: FeatureLayer | null;
};

export const useMmsRankLayer = ({
  showLayer,
  legend,
  map,
  view,
  initDataGis,
  lstStudyLocations,
  roadSegmentLayer,
}: TProps) => {
  const [isLayerLoading, setIsLayerLoading] = useState(false);
  const isUnderConstruction = useRef(false);
  const isTheLastMovement = useRef(true);
  const removeEndStationaryWatch = useRef<() => void | undefined>();
  const removeStartStationaryWatch = useRef<() => void | undefined>();

  const onError = (error: any) => {
    isUnderConstruction.current = false;
  };

  useEffect(() => {
    if (
      map &&
      view &&
      roadSegmentLayer &&
      initDataGis &&
      lstStudyLocations &&
      showLayer &&
      lstStudyLocations.length !== 0
    ) {
      if (isLayerExist(map, "mmsRankLayer")) return;
      setIsLayerLoading(true);
      let queryIds = generateQueryIds(
        initDataGis,
        "midblockGeoIdType",
        lstStudyLocations
      );

      const strRoadSegmentQuery = `${initDataGis.midblockGeoIdName} IN (${queryIds})`;

      const roadSegmentQuery = {
        outFields: ["*"],
        where: strRoadSegmentQuery,
        returnGeometry: true,
      };

      const queryingAndCreateLayer = () => {
        setIsLayerLoading(true);
        isUnderConstruction.current = true;
        view
          ?.whenLayerView(roadSegmentLayer)
          .then((layerView) => {
            reactiveUtils
              .whenOnce(() => !layerView.updating)
              .then((e) => {
                if (!isTheLastMovement.current) {
                  setIsLayerLoading(false);
                  isUnderConstruction.current = false;
                  return;
                }
                layerView
                  .queryFeatures(roadSegmentQuery)
                  .then((results) => {
                    if (!isTheLastMovement.current) {
                      setIsLayerLoading(false);
                      isUnderConstruction.current = false;
                      return;
                    }
                    const graphics = results.features;
                    graphics.forEach((graphic) => {
                      const matchedLocation = lstStudyLocations.find(
                        (loc) =>
                          loc.geoId &&
                          loc.geoId.toString() ===
                            graphic.attributes[
                              initDataGis.midblockGeoIdName
                            ].toString()
                      );
                      graphic.attributes["smoothMMS"] =
                        matchedLocation &&
                        matchedLocation?.smoothMMS &&
                        matchedLocation?.smoothMMS.toString();
                      graphic.attributes["rin"] = matchedLocation?.rin;
                    });

                    const mmsRankLayer = new FeatureLayer({
                      id: "mmsRankLayer",
                      title: "MMSRankLayer",
                      source: generateMockDataForEmptySource(graphics),
                      objectIdField: "ObjectId",
                      outFields: ["*"],
                      fields: [
                        {
                          alias: "Smooth MMS",
                          name: "smoothMMS",
                          type: "string",
                          editable: true,
                          nullable: true,
                        },
                        {
                          alias: initDataGis.midblockGeoIdName,
                          name: initDataGis.midblockGeoIdName,
                          type: "string",
                          editable: true,
                          nullable: false,
                        },
                        {
                          alias: "RIN",
                          name: "rin",
                          type: "string",
                          editable: false,
                          nullable: true,
                        },
                      ],
                      popupTemplate: {
                        title: "MMS Rank",
                        content: [
                          {
                            type: "fields",
                            fieldInfos: [
                              {
                                fieldName: initDataGis.midblockGeoIdName,
                                label: "Geo ID",
                              },
                              { fieldName: "mmsRank", label: "Total" },
                            ],
                          },
                        ],
                      },
                      labelingInfo: [
                        new LabelClass({
                          deconflictionStrategy: "none",
                          labelExpressionInfo: {
                            expression: "$feature.rin",
                          },
                          labelPlacement: "center-along",
                          symbol: new TextSymbol({
                            font: {
                              size: 7,
                              weight: "bold",
                            },
                            color: "#ffffff",
                            haloColor: "#1c1c1c",
                            haloSize: 1,
                          }),
                        }),
                      ],
                      renderer: new UniqueValueRenderer({
                        field: "smoothMMS",
                        uniqueValueInfos: [
                          {
                            value: 1,
                            symbol: new SimpleLineSymbol({
                              color: "#ff0000",
                              width: "3",
                            }),
                            label: "1",
                          },
                          {
                            value: 2,
                            symbol: new SimpleLineSymbol({
                              color: "#f5a30a",
                              width: "3",
                            }),
                            label: "2",
                          },
                          {
                            value: 3,
                            symbol: new SimpleLineSymbol({
                              color: "#a4f40f",
                              width: "3",
                            }),
                            label: "3",
                          },
                          {
                            value: 4,
                            symbol: new SimpleLineSymbol({
                              color: "#045add",
                              width: "3",
                            }),
                            label: "4",
                          },
                          {
                            value: 5,
                            symbol: new SimpleLineSymbol({
                              color: "#a306dd",
                              width: "3",
                            }),
                            label: "5",
                          },
                          {
                            value: 6,
                            symbol: new SimpleLineSymbol({
                              color: "#a26d08",
                              width: "3",
                            }),
                            label: "6",
                          },
                        ],
                      }),
                    });

                    mmsRankLayer
                      .load()
                      .then(() => {
                        if (!isTheLastMovement.current) {
                          setIsLayerLoading(false);
                          isUnderConstruction.current = false;
                          return;
                        }
                        removeLayer(map, "mmsRankLayer");
                        map.add(mmsRankLayer);
                        if (legend)
                          legend.layerInfos.push({
                            layer: mmsRankLayer,
                          });
                        // legendExpand.expanded = true;
                      })
                      .catch((error) => {
                        onError(error);
                      });

                    layerSuccessfullyLoadedInDomCallback(
                      view,
                      mmsRankLayer,
                      () => {
                        setIsLayerLoading(false);
                        isUnderConstruction.current = false;
                      }
                    );
                  })
                  .catch((error) => {
                    onError(error);
                  });
              })
              .catch((error) => {
                onError(error);
              });
          })
          .catch((error) => {
            onError(error);
          });
      };
      view.when(() => {
        if (isLayerExist(map, "mmsRankLayer") || isUnderConstruction.current) {
          return;
        }
        queryingAndCreateLayer();
      });
      removeStartStationaryWatch.current?.();
      removeStartStationaryWatch.current =
        afterMapStationaryWithDebounceCallback(
          view,
          () => {
            if (isLayerExist(map, "mmsRankLayer")) {
              isTheLastMovement.current = false;
            }
          },
          1
        );
      removeEndStationaryWatch.current?.();
      removeEndStationaryWatch.current = afterMapStationaryWithDebounceCallback(
        view,
        () => {
          isTheLastMovement.current = true;
          if (!isUnderConstruction.current) queryingAndCreateLayer();
        },
        700
      );
    }
  }, [map, view, initDataGis, lstStudyLocations, roadSegmentLayer, showLayer]);

  return {
    isMmsRankLayerLoading: isLayerLoading,
  };
};
