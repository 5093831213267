import i18n from "../../../../../react-i18next";

const tabTitles = [
  {
    id: 0,
    text: "details",
    icon: "fa-solid fa-ellipsis",
    content: "Details tab content",
  },
  {
    id: 1,
    text: "siteSubTypes",
    icon: "fa-solid fa-border-all",
    content: "Details tab content",
  }
];
export default tabTitles;