import React, { useEffect, useRef, useState } from "react";
import Tabs from "devextreme-react/tabs";
import tabTitles from "./data/index";
import { RequestErrorHandling, TesGet, TesPost } from "../../../utils/rest";
import { useNavigate, useParams } from "react-router-dom";

import notify from "devextreme/ui/notify";
import { Button, Form, Popup } from "devextreme-react";
import "./signalWarrantPredictionAnalysis.scss";
import { custom } from "devextreme/ui/dialog";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/auth";
import { RequestResponseResult } from "../../../types/general/generalTypes";
import { ResponseCode } from "../../../types/general/enums/generalEnums";
import Permission from "../../../components/permission/permision";
import Results from "./components/results/results";
import { TrafficApiUrl } from "../../../environment/routeSettings";
import SafetyPermissions from "../../../constants/Permissions/SafetyPermissions";
import { ISignalWarrantAnalysisId, SignalWarrantPredictionRequest, SignalWarrantsPredAnalysis } from "../../../types/systematicScreening/systematicScreeningTypes";
import { AnalysisType } from "../../../types/systematicScreening/enums/systematicScreeningEnums";
import { SimpleItem } from "devextreme-react/form";
import SystematicScreeningPermissions from "../../../constants/Permissions/systematicScreeningPermissions";
import TesTabs from "../../../components/tesTab/tesTab";

const SignalWarrantPredictionAnalysis = () => {
  const [initData, setInitData] = useState<SignalWarrantsPredAnalysis>(new SignalWarrantsPredAnalysis());
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const didMount = useRef(false);
  const history = useNavigate();
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const params = useParams<ISignalWarrantAnalysisId>();
  const [isLocked, setIsLocked] = useState(true);
  const [backPermission, setBackPermission] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (!didMount.current) {
          console.log(params.analysisId)
          await getInitData(params.analysisId!);
          if (activeLoading) activeLoading(false);
          return (didMount.current = true);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData(id: string) {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesGet(TrafficApiUrl() + "/api/SignalWarrantPredictionAnalysis/GetSignalWarrantPredictionAnalysis/" + id, true)) as RequestResponseResult<SignalWarrantsPredAnalysis>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results)
      } else {
        await RequestErrorHandling(res);;
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }



  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.moduleId !== "AddNew") {
                  onUpdate();
                  setDataChanged(false)
                } else {
                  // onAdd();
                  setDataChanged(false)
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  async function onUpdate() {
    try {
      if (activeLoading) activeLoading(true);
      var postObj: SignalWarrantPredictionRequest = {
        ...initData.analysisSettings,
        customerId: localStorage.getItem("selectedCustomerId") as string,
        projectId: params.projectId,
        analysisId: params.analysisId
      };
      const res = (await TesPost(
        TrafficApiUrl() + "/api/SignalWarrantPredictionAnalysis/Run",
        postObj,
        true
      )) as RequestResponseResult<any>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        notify(t("analysisMessage"), "success", 5000);
        setShowModal(true);
        history('/systemicScreening/projects')
        // setInitData({ ...initData, results: res.results.results })
      }
      else {
        await RequestErrorHandling(res);;
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
    setDataChanged(false)
  }




  return (
    <Permission
      allowed={[SystematicScreeningPermissions.SystematicScreening_D_SWP,
      SystematicScreeningPermissions.SystematicScreening_V_SWP,
      ]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <h2 className={"content-block"}>{t("signalWarrantPredictionAnalysis")}</h2>
        <div className={"content-block"}>
          <div className={"dx-card"}>

            <div className="row" style={{ marginTop: 15 }}>
              <div className="leftColumn">
                <Button
                  onClick={goBackPermission}
                  icon="fa-solid fa-arrow-left"
                  hint={t("goBack")}
                />
              </div>
              <div className="rightColumn">
                <Button
                  onClick={() => LockHandler()}
                  icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                  hint={isLocked ? t("unlock") : t("lock")}
                />
              </div>
              <Permission allowed={[SystematicScreeningPermissions.SystematicScreening_D_SWP]} hasFeedBackElement={true}>
                <div className="leftColumn">

                </div>
                <div className="rightColumn">
                  {/* {params.moduleId === "AddNew" && (
                <Button onClick={onAdd} icon="fa-solid fa-floppy-disk" hint={t("save")} />
              )} */}
                  {params.moduleId !== "AddNew" && isLocked === false && (
                    <Button onClick={onUpdate} icon="fa-solid fa-play" hint={t("saveAndRun")} />
                  )}
                </div>
              </Permission>
            </div>
          </div>
          <div className={"dx-card "}>
            <TesTabs
              width={"100%"}
              dataSource={tabTitles}
              selectedIndex={selectedIndex}
              onTabChange={handleTabChange}
            />

            {selectedIndex === 0 &&
              <Results
                initData={initData}
                setInitData={setInitData}
                isLocked={isLocked}
              />
            }


          </div>
        </div>


        <Popup
          width={"50%"}
          height={"auto"}
          visible={showModal}
          resizeEnabled={true}
          showTitle={true}
          title={t("signalWarrantPredictionAnalysis")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => setShowModal(false)}
        >
          <div >
            <Form colCount={2}>
              <SimpleItem colSpan={2} >
                <p className="title">{t('signalWarrantPredictionAnalysisMsg')}</p>
              </SimpleItem>
            </Form>
          </div>
          <div className="rightColumn" style={{ marginTop: "0.5rem" }}>
            <Button
              className="tes-modal-btn-add"
              onClick={() => setShowModal(false)}
              text={t("close")}
            />
          </div>
        </Popup>
      </React.Fragment>
    </Permission>
  );
};
export default SignalWarrantPredictionAnalysis;
