import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  PropsWithChildren,
  useCallback,
} from "react";

import {
  getFieldsData,
  getStoredFieldsData,
  storeFieldsData,
  storeFieldsDataKeyName,
} from "./utils";

import { FieldCategoryType } from "../../types/field/enums/fieldEnums";
import { VMField } from "../../types/field/dto/fieldDTO";
import { initialFieldsData } from "./constants";

export type TFieldsData = Record<FieldCategoryType, VMField[]>;
type TFieldsContext = {
  fieldsLoading: boolean;
  fieldsData: TFieldsData;
  clearStoredFieldsData: () => void;
  getFieldsByFieldCategoryType?: (type: FieldCategoryType) => VMField;
};
const initialData: TFieldsContext = {
  fieldsLoading: true,
  fieldsData: initialFieldsData,
  clearStoredFieldsData: () => {},
  getFieldsByFieldCategoryType: (type) => new VMField(),
};

export const FieldsContext = createContext(initialData);

const useFields = () => useContext(FieldsContext);

function FieldsProvider({ children }: PropsWithChildren) {
  const [fieldsData, setFieldsData] = useState<TFieldsData>(initialFieldsData);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAndStoreFieldsData = async () => {
    setIsLoading(true);
    //To prevent getting data on refresh, uncomment code below
    // const storedData = getStoredFieldsData();
    // if (storedData) {
    //   setFieldsData(storedData);
    //   setIsLoading(false);
    //   return;
    // }
    const data = await getFieldsData();
    const fields: TFieldsData = { ...initialFieldsData };
    if (data) {
      for (const item of data) {
        fields[item.fieldCategoryType].push(item);
      }
    }
    setFieldsData(fields);
    storeFieldsData(fields);
    setIsLoading(false);
  };

  // const getFieldsByFieldCategoryType = (
  //   fieldCategoryType: FieldCategoryType
  // ) => {
  //   if (fieldsData) {
  //     return fieldsData?.filter(
  //       (item) => item.fieldCategoryType === fieldCategoryType
  //     )[0];
  //   } else {
  //     return new VMField();
  //   }
  // };

  const clearStoredFieldsData = useCallback(() => {
    localStorage.removeItem(storeFieldsDataKeyName);
    setFieldsData(initialFieldsData);
  }, []);

  useEffect(() => {
    fetchAndStoreFieldsData();
  }, []);

  const value: TFieldsContext = {
    fieldsLoading: isLoading,
    fieldsData,
    clearStoredFieldsData,
    // getFieldsByFieldCategoryType,
  };
  return (
    <FieldsContext.Provider value={value}>{children}</FieldsContext.Provider>
  );
}

export { FieldsProvider, useFields };
