import { ITesBase } from "../infrastructureTypes";
import {
  LocationType,
  SectionDetailType,
  SectionType,
  TrafficSectionVolumeInheritType,
} from "./../enums/infrastructureEnums";

import {
  Location,
} from "../../../types/infrastructure/infrastructureTypes";

export class VMLocation {
  [key: string]: any;
  id?: string;
  customerId?: string;
  divisionId?: string;
  latitude?: number;
  longitude?: number;
  description?: string;
  geoId?: string;
  locationType?: LocationType;
  postedSpeed?: number;
  sectionDetailType?: SectionDetailType;
  sectionType?: SectionType;
  coordinate?: VMCoordination;
  jurisdictionId?: string;
  municipalityId?: string;
}

export class VMCoordination {
  id!: string;
  longitude!: number;
  latitude!: number;
}

export class VMLstGeoId {
  intersectionGeoIds!: string[];
  midblockGeoIds!: string[];
}
export class AreaDTO {
  constructor() {
    this.locations = [];
  }
  [key: string]: any;
  id!: string;
  customerId!: string;
  name!: string;
  userId!: string;
  userIds?: string[];
  groupIds?: string[];
  locations!: VMLocation[];
  locationCount!:number;
}

export class FromMapRequestDTO {
  customerId!: string;
  geoId!: string;
  locationType!: LocationType;
}

export class SimplifiedLocationDTO {
  id?: string;
  locationDescription?: string;
  geoId?: string;
  locationType?: LocationType;
  postedSpeed?: number;
  sectionDetailType?: SectionDetailType;
  sectionType?: SectionType;
  isIntersection?: boolean;
}

export class FromMapResponseDTO {
  simplifiedLocation?: SimplifiedLocationDTO;
}

export class MisDetailsDTO implements ITesBase {
  id!: string;
  tesId!: string;
  description!: string;
  geoId!: string;
  customerId!: string;
  divisionId!: string;
  locationType!: LocationType;
  siteSubTypeId!: string;
  trafficSectionId!: string;
  volumeInheritType!: TrafficSectionVolumeInheritType;
  permanentCountStationId!: string;
  aadtPatternId!: string;
  lhrs!: number;
  startOffset!: number;
  coordinate!: Coordinate;
  nextEndOffset!: number;
  nextStartOffset!: number;
  priorEndOffset!: number;
  priorStartOffset!: number;
  nextLHRS!: number;
  priorLHRS!: number;
  general!: any;
  simplifiedLocation!: SimplifiedLocationDTO
  roadSegments!: MisXRoadSegmentDetailsDTO[];
}

export class Coordinate {
  latitude!: number;
  longitude!: number;
}

export enum MisPositionType {
  Prior = 1,
  Next = 2,
}

export class MisXRoadSegmentDetailsDTO extends Location
{
  order!: number;
  misPositionType!: MisPositionType;
  startOffset!: number;
  endOffset!: number;
}
