import { INameId } from "../../general/generalTypes";
import {
  FieldCategoryType,
  FieldLocation2Show,
  FieldPermission,
  FieldServiceType,
  FieldType,
} from "../enums/fieldEnums";

export class VMAllFieldPermission {
  userId!: string;
  tesFieldId!: string;
  permission!: FieldPermission;
  currentUserPermission!: FieldPermission;
  fieldName!: string;
  fieldCategoryType!: FieldCategoryType;
  fieldServiceType!: FieldServiceType;
}

export class VMUpdateFieldPermission {
  customerId!: string;
  userId!: string;
  groupId!: string;
  fieldServiceType!: FieldServiceType;
  fieldPermissions!: VMAllFieldPermission[];
}
export class VMField {
  id!: string;
  customerId!: string;
  moduleOfModuleTabId?: string;
  fieldCategoryType!: FieldCategoryType;
  fieldType!: FieldType;
  name!: string;
  description!: string;
  permission?: FieldPermission;
  labelText!: string;
  isRequired!: boolean;
  fieldValues!: INameId[];
  // codeGroupId!: string;
  validationRule!: string;
  isUpperCase!: boolean;
  integrationAddress!: string;
  exactDT!: boolean;
  readOnlyField!: boolean;
  gridViewIndex?: number;
  detailViewIndex?: number;
  mobileAppIndex?: number;
  geocodingIndex!: number;
  fieldLocation2Show!: FieldLocation2Show;
  isCalculatedField?: boolean;
  isShowInDriver!: boolean;
  isShowInPassenger!: boolean;
  isShowInPedestrian!: boolean;
  fieldDefaultValue!: string;
}

export class VMWebField {
  id!: string;
  customerId!: string;
  fieldCategoryType!: FieldCategoryType;
  fieldType!: FieldType;
  index!: number;
  name!: string;
  description!: string;
  labelText!: string;
  codeGroupId!: string;
  isRequiredInApp!: boolean;
  isRequiredInWeb!: boolean;
  isShowInApp!: boolean;
  isShowInWeb!: boolean;
  isUpperCase!: boolean;
  isShowInDriver!: boolean;
  isShowInPassenger!: boolean;
  isShowInPedestrian!: boolean;
  isCalculatedField!: boolean;
  validationRule!: string;
  rule!: string;
  integrationAddress!: string;
  fieldLocation2Show?: FieldLocation2Show;
  moduleOfModuleTabId!: string;
  exactDT!: boolean;
}

export class VMCollisionGroupedField {
  [key: string]: any;
  generalFields!: VMField[];
  vehicleFields!: VMField[];
  personFields!: VMField[];
  roadFields!: VMField[];
  driverFields!: VMField[];
  pedestrianFields!: VMField[];
  passengerFields!: VMField[];
  remarkFields!: VMField[];
}

export class TableFieldRequestDTO {
  customerId!: string;
  categoryTypes!: FieldCategoryType[];
}

export class VMCodeValue {
  id!: string;
  name!: string;
  code!: string;
}

export class VMCodeGroup {
  id!: string;
  name!: string;
  codeValues!: VMCodeValue[];
}
