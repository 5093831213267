//#region imports
import React, { useEffect, useRef, useState } from "react";
import Tabs from "devextreme-react/tabs";
import tabTitles from "./data/index";
//component imports
import Patients from "./components/details/patients";
import { RequestErrorHandling, TesGet, TesPost } from "../../../utils/rest";
import { useNavigate } from "react-router-dom";
import notify from "devextreme/ui/notify";
import { Button } from "devextreme-react";
import { t } from "i18next";
import { custom } from "devextreme/ui/dialog";
import {
  Hospitalization,
  IHospParams,
} from "../../../types/hospitalization/hospitalizationTypes";
import { useParams } from "react-router-dom";
import "./hospitalizationDetails.scss";
import { useClientSetting } from "../../../contexts/clientSetting";
import Permission from "../../../components/permission/permision";
import { CollisionPermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { CollisionApiUrl, InfrastructureApiUrl } from "../../../environment/routeSettings";
import getTitle from "../../../components/title/getTitle";
import { ResponseCode } from "../../../types/general/enums/generalEnums";
import { MapSetting } from "../../../types/infrastructure/infrastructureTypes";
import { RequestResponseResult } from "../../../types/general/generalTypes";
import TesTabs from "../../../components/tesTab/tesTab";

//#endregion imports
const HospitalizationDetails = () => {
  //#region consts
  const history = useNavigate();
  const params = useParams<IHospParams>();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataChanged, setDataChanged] = useState(false);
  const [initData, setInitData] = useState<Hospitalization>(new Hospitalization());
  const { generalSetting } = useClientSetting();
  const { activeLoading } = useAuth();
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const headingParagraphRef = useRef<HTMLParagraphElement>(null);

  //#endregion consts

  //#region function

  const title = getTitle('/hospitalization/hospitalizationDetails', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  async function getInitialData() {
    const res = await TesGet(InfrastructureApiUrl() + "/api/Settings/GetInfrastructureSettings/" + localStorage.getItem('selectedCustomerId'), true) as RequestResponseResult<Hospitalization>
    if (res.responseCode === ResponseCode.OK) {
      setInitData(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  //permission functions
  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                onSave();
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setDataChanged(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  //save function
  async function onSave() {
    try {
      const res = await TesPost(
        CollisionApiUrl() + "/api/Hospitalizations/Update",
        initData,
        true
      );
      if (res.isChanged) {
        setDataChanged(true);
      }
      await getInitialData();
    } catch {
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  const copyTextToClipboard = () => {
    if (headingParagraphRef.current) {
      const textToCopy = headingParagraphRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  //#endregion functions
  return (
    <Permission
      allowed={[
        CollisionPermissions.Collision_V_Hospitalization,
        CollisionPermissions.Collision_D_Hospitalization,
      ]}
      hasFeedBackElement={true}
    >
      <div className={`hospitalizationDetails ${compactViewModel ? "compactStyle" : ""}`}>
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card"}>
              <div className="row" style={{ marginTop: 15 }}>
                <div className="leftColumn">
                  <Button
                    onClick={() => goBackPermission()}
                    icon="fa-solid fa-arrow-left"
                    hint={t("goBack")}
                  />
                </div>
                <div className="rightColumn">
                  {/* <Button
                                onClick={() => changeLockStatus()}
                                icon='key'
                                hint={t('lock')}
                            /> */}
                  <Permission
                    allowed={[CollisionPermissions.Collision_D_Hospitalization]}
                    hasFeedBackElement={false}
                  >
                    <Button
                      onClick={() => onSave()}
                      icon="fa-solid fa-floppy-disk"
                      hint={t("save")}
                    />
                  </Permission>
                </div>
              </div>
            </div>
            <div className={"dx-card "}>
              <TesTabs
                width={"100%"}
                dataSource={tabTitles}
                selectedIndex={selectedIndex}
                onTabChange={handleTabChange}
              />

              {selectedIndex === 0 && (
                <Patients generalSettings={generalSetting} />
              )}
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default HospitalizationDetails;
