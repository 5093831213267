import React, { useMemo } from "react";
import Tabs from "devextreme-react/tabs";
import { useTranslations } from "../../contexts/translations";
import { t } from "i18next";
import {ItemClickEvent} from "devextreme/ui/tabs";

interface TabTitle {
  id: number;
  text: string;
  icon?: string;
}

interface TesTabProps {
  width?: string;
  dataSource: TabTitle[];
  selectedIndex: number;
  onTabChange: (index: number) => void;
}

const TesTabs = ({ dataSource, width = "100%", selectedIndex, onTabChange }: TesTabProps) => {
  const { availableTranslations, selectedLanguage } = useTranslations();

  const memoisedTabTitles = useMemo(() => {
    return dataSource.map((i) => ({
      ...i,
      key: i.id,
      text: t(i.text),
    }));
  }, [availableTranslations, selectedLanguage, dataSource]);
  
  const onTabItemClick = (e: ItemClickEvent) => {
    onTabChange(e.itemIndex)
  }

  return (
    <Tabs
      width={width}
      dataSource={memoisedTabTitles}
      selectedIndex={selectedIndex}
      onItemClick={onTabItemClick}
    />
  );
};

export default TesTabs;