import { LocationType } from "../../../../types/infrastructure/enums/infrastructureEnums";
import { TesMapModule } from "../../../../types/general/enums/generalEnums";
import MapView from "@arcgis/core/views/MapView";
import { MapSetting } from "../../../../types/infrastructure/infrastructureTypes";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import i18next from "i18next";

type TPopupActionBTNsType =
  | "go-to-intersection"
  | "open-google-maps"
  | "street-view"
  | "intersection-show-all-data-of-geoId"
  | "select-intersection"
  | "roadSegment-show-all-data-of-geoId"
  | "select-roadSegment"
  | "go-to-roadSegment";

export type TLayerPopupAction = {
  id: TPopupActionBTNsType;
  image: string;
  title: string;
  type: "button" | "toggle";
};

export const intersectionPopupActionBTNs = (
  tesMapModule: TesMapModule
): TLayerPopupAction[] | undefined => {
  if (
    tesMapModule === TesMapModule.Infrastructure ||
    tesMapModule === TesMapModule.InfrastructureDetails ||
    tesMapModule === TesMapModule.TrafficSectionDetails ||
    tesMapModule === TesMapModule.HeatMap
  ) {
    return [
      {
        id: "go-to-intersection",
        image: "https://staticfile.tes.ca/gisMap/intersectionType.png",
        title: "Show intersection details",
        type: "button",
      },
      {
        id: "open-google-maps",
        image: "https://staticfile.tes.ca/gisMap/googleMaps.png",
        title: "Google Maps",
        type: "button",
      },
      {
        id: "street-view",
        image: "https://staticfile.tes.ca/gisMap/streetView.png",
        title: "Street View",
        type: "button",
      },
    ];
  } else if (tesMapModule === TesMapModule.Collision) {
    return [
      {
        id: "intersection-show-all-data-of-geoId",
        image: "https://staticfile.tes.ca/gisMap/intersectionType.png",
        title: "Show all collisions",
        type: "button",
      },
    ];
  } else if (
    tesMapModule === TesMapModule.CollisionGeoCode ||
    tesMapModule === TesMapModule.StudyDetails ||
    tesMapModule === TesMapModule.CollisionDetails
  ) {
    return [
      {
        id: "select-intersection",
        image: "https://staticfile.tes.ca/gisMap/select.png",
        title: "Select This Infrastructure",
        type: "button",
      },
    ];
  } else if (tesMapModule === TesMapModule.CollisionOverviewDashboard) {
    return [
      {
        id: "go-to-intersection",
        image: "https://staticfile.tes.ca/gisMap/intersectionType.png",
        title: "Show intersection details",
        type: "button",
      },
    ];
  }
};
export const roadSegmentPopupActionBTNs = (
  tesMapModule: TesMapModule
): TLayerPopupAction[] | undefined => {
  const t = i18next.t;
  const showAllDataOfGeoIdTitle = {
    [TesMapModule.Collision.toString()]: "Show all collisions",
    [TesMapModule.Infrastructure.toString()]: "Show all collisions",
    [TesMapModule.Study.toString()]: "Show all Studies",
    [TesMapModule.AADT.toString()]: "Show all AADTs",
    [TesMapModule.Support.toString()]: t("showSupports"),
    [TesMapModule.Sign.toString()]: t("showSigns"),
  };
  if (
    tesMapModule === TesMapModule.AADT ||
    tesMapModule === TesMapModule.Collision ||
    tesMapModule === TesMapModule.Sign ||
    tesMapModule === TesMapModule.Support
  ) {
    return [
      {
        id: "roadSegment-show-all-data-of-geoId",
        image: "https://staticfile.tes.ca/gisMap/midblock.png",
        title: showAllDataOfGeoIdTitle[tesMapModule.toString()],
        type: "button",
      },
    ];
  } else if (
    tesMapModule === TesMapModule.CollisionDetails ||
    tesMapModule === TesMapModule.StudyDetails ||
    tesMapModule === TesMapModule.CollisionGeoCode
  ) {
    return [
      {
        id: "select-roadSegment",
        image: "https://staticfile.tes.ca/gisMap/select.png",
        title: "Select This Infrastructure",
        type: "button",
      },
    ];
  } else if (tesMapModule === TesMapModule.CollisionOverviewDashboard) {
    return [
      {
        id: "go-to-roadSegment",
        image: "https://staticfile.tes.ca/gisMap/midBlock.png",
        title: "Show Road Segment details",
        type: "button",
      },
    ];
  } else if (
    tesMapModule === TesMapModule.Infrastructure ||
    tesMapModule === TesMapModule.InfrastructureDetails ||
    tesMapModule === TesMapModule.TrafficSectionDetails ||
    tesMapModule === TesMapModule.HeatMap
  ) {
    return [
      {
        id: "go-to-roadSegment",
        image: "https://staticfile.tes.ca/gisMap/midBlock.png",
        title: "Road Segment Details",
        type: "button",
      },
      {
        id: "open-google-maps",
        image: "https://staticfile.tes.ca/gisMap/googleMaps.png",
        title: "Google Maps",
        type: "button",
      },
      {
        id: "street-view",
        image: "https://staticfile.tes.ca/gisMap/streetView.png",
        title: "Street View",
        type: "button",
      },
    ];
  }
};

type TBaseArgs = {
  view: MapView;
  initDataGis: MapSetting;
  intersectionLayer: FeatureLayer;
  roadSegmentLayer: FeatureLayer;
};
type TActionBTNHandlers = {
  "go-to-intersection"?: {
    go2Intersection: (geoId: string) => void;
  };
  "open-google-maps"?: {
    openInGoogleMap: (selectedFeature: any) => void;
  };
  "street-view"?: {
    openStreetView: (selectedFeature: any) => void;
  };
  "intersection-show-all-data-of-geoId"?: {
    filterByGeoId: any;
  };
  "select-intersection"?: {
    selectLocation: any;
    selectedLatitude: any;
    selectedLongitude: any;
    selectedXCoord: any;
    selectedYCoord: any;
    setLstLocalSelectedIntersection: any;
    setLstLocalSelectedRoadSegment: any;
    removeAllHighlight: () => void;
    setIntersectionHighlightedRefs: any;
  };
  "roadSegment-show-all-data-of-geoId"?: {
    filterByGeoId: any;
  };
  "select-roadSegment"?: {
    selectLocation: any;
    selectedLatitude: any;
    selectedLongitude: any;
    selectedXCoord: any;
    selectedYCoord: any;
    setLstLocalSelectedIntersection: any;
    setLstLocalSelectedRoadSegment: any;
    removeAllHighlight: () => void;
    setRoadSegmentHighlightedRefs: any;
  };
  "go-to-roadSegment"?: {
    go2RoadSegment: (geoId: string) => void;
  };
  "go-to-collision"?: {
    go2Collision?: (id: string) => void;
  };
  "go-to-signSupport"?: {
    go2SignSupport?: (id: string) => void;
  };
};
export const registerPopupActionFunctions = async (
  baseArgs: TBaseArgs,
  actionBTNHandlers: TActionBTNHandlers
) => {
  const { view, initDataGis, intersectionLayer, roadSegmentLayer } = baseArgs;
  await view.when();
  view.popup.dockEnabled = true;
  reactiveUtils.on(
    () => view.popup?.viewModel,
    "trigger-action",
    (event: __esri.PopupTriggerActionEvent) => {
      const popup = view.popup;
      const attributes = popup.viewModel?.selectedFeature?.attributes;
      const content = popup.viewModel?.content;
      switch (event.action.id) {
        case "go-to-intersection":
          const go2Intersection =
            actionBTNHandlers["go-to-intersection"]?.go2Intersection;
          go2Intersection?.(attributes[initDataGis.intersectionGeoIdName]);
          break;
        case "open-google-maps":
          const openInGoogleMap =
            actionBTNHandlers["open-google-maps"]?.openInGoogleMap;
          openInGoogleMap?.(popup.viewModel.selectedFeature);
          break;
        case "street-view":
          const openStreetView =
            actionBTNHandlers["street-view"]?.openStreetView;
          openStreetView?.(popup.viewModel.selectedFeature);
          break;
        case "intersection-show-all-data-of-geoId":
          const filterByGeoId_i =
            actionBTNHandlers["intersection-show-all-data-of-geoId"]
              ?.filterByGeoId;
          filterByGeoId_i([
            {
              geoId: attributes[initDataGis.intersectionGeoIdName],
              locationType: LocationType.Intersection,
            },
          ]);
          break;
        case "select-intersection":
          const selectLocation_i =
            actionBTNHandlers["select-intersection"]?.selectLocation;
          const selectedLatitude_i =
            actionBTNHandlers["select-intersection"]?.selectedLatitude;
          const selectedLongitude_i =
            actionBTNHandlers["select-intersection"]?.selectedLongitude;
          const selectedXCoord_i =
            actionBTNHandlers["select-intersection"]?.selectedXCoord;
          const selectedYCoord_i =
            actionBTNHandlers["select-intersection"]?.selectedYCoord;
          const setIntersectionHighlightedRefs =
            actionBTNHandlers["select-intersection"]
              ?.setIntersectionHighlightedRefs;
          const setLstLocalSelectedIntersection_i =
            actionBTNHandlers["select-intersection"]
              ?.setLstLocalSelectedIntersection;
          const setLstLocalSelectedRoadSegment_i =
            actionBTNHandlers["select-intersection"]
              ?.setLstLocalSelectedRoadSegment;
          const removeAllHighlight_i =
            actionBTNHandlers["select-intersection"]?.removeAllHighlight;
          selectLocation_i(
            attributes[initDataGis.intersectionGeoIdName],
            LocationType.Intersection,
            {
              latitude: selectedLatitude_i,
              longitude: selectedLongitude_i,
              xCoord: selectedXCoord_i,
              yCoord: selectedYCoord_i,
            }
          );

          removeAllHighlight_i?.();

          view?.whenLayerView(intersectionLayer).then(function (layerView) {
            reactiveUtils
              .whenOnce(() => !layerView.updating)
              .then(() => {
                setIntersectionHighlightedRefs([
                  layerView.highlight(popup.viewModel.selectedFeature),
                ]);
              });
          });
          setLstLocalSelectedIntersection_i([
            attributes[initDataGis.intersectionGeoIdName],
          ]);
          setLstLocalSelectedRoadSegment_i([]);
          popup.close();
          break;
        case "roadSegment-show-all-data-of-geoId":
          const filterByGeoId =
            actionBTNHandlers["roadSegment-show-all-data-of-geoId"]
              ?.filterByGeoId;
          filterByGeoId([
            {
              geoId: attributes[initDataGis.midblockGeoIdName],
              locationType: LocationType.Midblock,
            },
          ]);
          break;
        case "select-roadSegment":
          const selectLocation_r =
            actionBTNHandlers["select-roadSegment"]?.selectLocation;
          const selectedLatitude_r =
            actionBTNHandlers["select-roadSegment"]?.selectedLatitude;
          const selectedLongitude_r =
            actionBTNHandlers["select-roadSegment"]?.selectedLongitude;
          const selectedXCoord_r =
            actionBTNHandlers["select-roadSegment"]?.selectedXCoord;
          const selectedYCoord_r =
            actionBTNHandlers["select-roadSegment"]?.selectedYCoord;
          const setRoadSegmentHighlightedRefs =
            actionBTNHandlers["select-roadSegment"]
              ?.setRoadSegmentHighlightedRefs;
          const setLstLocalSelectedIntersection_r =
            actionBTNHandlers["select-roadSegment"]
              ?.setLstLocalSelectedIntersection;
          const setLstLocalSelectedRoadSegment_r =
            actionBTNHandlers["select-roadSegment"]
              ?.setLstLocalSelectedRoadSegment;
          const removeAllHighlight_r =
            actionBTNHandlers["select-roadSegment"]?.removeAllHighlight;
          selectLocation_r(
            attributes[initDataGis.midblockGeoIdName],
            LocationType.Midblock,
            {
              latitude: selectedLatitude_r,
              longitude: selectedLongitude_r,
              xCoord: selectedXCoord_r,
              yCoord: selectedYCoord_r,
            }
          );

          removeAllHighlight_r?.();

          view?.whenLayerView(roadSegmentLayer).then(function (layerView) {
            reactiveUtils
              .whenOnce(() => !layerView.updating)
              .then(() => {
                setRoadSegmentHighlightedRefs([
                  layerView.highlight(popup.viewModel.selectedFeature),
                ]);
              });
          });
          setLstLocalSelectedRoadSegment_r([
            attributes[initDataGis.midblockGeoIdName],
          ]);
          setLstLocalSelectedIntersection_r([]);
          popup.close();

          break;
        case "go-to-roadSegment":
          const go2RoadSegment =
            actionBTNHandlers["go-to-roadSegment"]?.go2RoadSegment;
          go2RoadSegment?.(attributes[initDataGis.midblockGeoIdName]);
          break;
        case "go-to-collision":
          const go2Collision =
            actionBTNHandlers["go-to-collision"]?.go2Collision;
          go2Collision?.(content as string);
          break;
        case "go-to-signSupport":
          const go2SignSupport =
            actionBTNHandlers["go-to-signSupport"]?.go2SignSupport;
          go2SignSupport?.(content as string);
      }
    }
  );
};
