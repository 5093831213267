import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Permission from "../../components/permission/permision";
import { CustomerPermissions } from "../../constants/Permissions";
import { useAuth } from "../../contexts/auth";
import { useClientSetting } from "../../contexts/clientSetting";
import { AuthApiUrl } from "../../environment/routeSettings";
import { GridType } from "../../types/general/enums/generalEnums";
import { OnExporting } from "../../utils/dataGridTools";
import { Enum2Array } from "../../utils/enumTools";
import { TesGet } from "../../utils/rest";
import "./switchClient.scss";
import { useTranslations } from "../../contexts/translations";
import { useGridSettings } from "../../contexts/gridSettings";
import { useInfrastructure } from "../../contexts/infrastructure";
import {useFields} from "../../contexts/fields";

const SwitchClient = () => {
  const { getClientGeneralSetting } = useClientSetting();
  const { setClientNameId } = useAuth();
  const { clearStoredTranslations } = useTranslations();
  const { clearStoredGridSettings } = useGridSettings();
  const { clearStoredInfrastructureData } = useInfrastructure();
  const {clearStoredFieldsData} = useFields()
  const history = useNavigate();
  const [initData, setInitData] = useState({});
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useEffect(() => {
    GetClients();
  }, []);

  async function GetClients() {
    setInitData(
      await TesGet(AuthApiUrl() + "/api/CustomerXUsers/ClientsOfUser", true)
    );
  }

  async function onRowClicked(data: any) {
    var lstGridType = Enum2Array(GridType);
    lstGridType.forEach((element) => {
      localStorage.removeItem(element.value);
    });
    localStorage.setItem("selectedCustomerId", data.data.id);
    localStorage.setItem("selectedCustomerName", data.data.name);
    if (setClientNameId) setClientNameId(data.data.id, data.data.name);
    if (getClientGeneralSetting) {
      await getClientGeneralSetting(data.data.id);
    }
    clearStoredTranslations();
    clearStoredGridSettings();
    clearStoredInfrastructureData();
    clearStoredFieldsData();
    history(`/home`);
    window.location.reload();
  }

  function handleCopyToClipboard(id: string) {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        notify(t("idCopiedToClipboard"), "success", 2500);
      })
      .catch((err) => {
        notify(t("someErrorOccurred: " + err), "success", 2500);
      });
  }

  return (
    <Permission
      allowed={[CustomerPermissions.Customer_V_Web]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("clients")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className={"datagridTitle"}>
                Please choose the client you want to continue working with:
              </div>
              <DataGrid
                ref={dataGridRef}
                className={"dx-card wide-card"}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                onRowClick={onRowClicked}
                hoverStateEnabled={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                //contextMenuEnabled={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
              >
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <SearchPanel
                  visible={true}
                  width={285}
                  placeholder={t("search...")}
                />
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <Column dataField={"id"} caption={t("id")} visible={false} />
                <Column dataField={"name"} caption={t("name")} />
                <Column
                  type="buttons"
                  caption={t("actions")}
                  fixed={true}
                  fixedPosition="right"
                  width={85}
                >
                  <DevExpressButton
                    hint={t("copyId")}
                    onClick={(e) => handleCopyToClipboard(e.row!.data.id)}
                    icon="fa-solid fa-copy"
                  ></DevExpressButton>
                </Column>
              </DataGrid>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};

export default SwitchClient;
