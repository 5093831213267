import { LocationType } from "../../../../types/infrastructure/enums/infrastructureEnums";
import {
  afterMapStationaryWithDebounceCallback,
  generateMockDataForEmptySource,
  generateQueryIds,
  getAveragePaths,
  isLayerExist,
  layerSuccessfullyLoadedInDomCallback,
  removeLayer,
} from "../utils";
import { useEffect, useRef, useState } from "react";
import { StudyType } from "../../../../types/trafficStudy/enums/trafficStudyEnums";
import Expand from "@arcgis/core/widgets/Expand";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import { MapSetting } from "../../../../types/infrastructure/infrastructureTypes";
import { SimplifiedStudy } from "../../../../types/trafficStudy/trafficStudyTypes";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import Polyline from "@arcgis/core/geometry/Polyline";
import Graphic from "@arcgis/core/Graphic";
import Point from "@arcgis/core/geometry/Point";
import UniqueValueRenderer from "@arcgis/core/renderers/UniqueValueRenderer";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import notify from "devextreme/ui/notify";
import { useTranslation } from "react-i18next";

type TProps = {
  showLayer: boolean;
  legendExpand: Expand | null;
  map: Map | null;
  view: MapView | null;
  initDataGis: MapSetting | null | undefined;
  lstStudyLocations: SimplifiedStudy[] | undefined;
  roadSegmentLayer: FeatureLayer | null;
  reCreateLayerEvenItExists?: boolean;
};

export const useLatestVolumeStudyLayer = ({
  showLayer,
  legendExpand,
  map,
  view,
  initDataGis,
  lstStudyLocations,
  roadSegmentLayer,
  reCreateLayerEvenItExists = false,
}: TProps) => {
  const { t } = useTranslation();
  const [isLayerLoading, setIsLayerLoading] = useState(false);
  const isCancelled = useRef(false);
  const isUnderConstruction = useRef(false);
  const isTheLastMovement = useRef(true);
  const removeEndStationaryWatch = useRef<() => void | undefined>();
  const removeStartStationaryWatch = useRef<() => void | undefined>();

  const onError = (error: any) => {
    isUnderConstruction.current = false;
  };

  useEffect(() => {
    isCancelled.current = !showLayer;
    if (map) {
      if (!showLayer) {
        if (isLayerExist(map, "VolumeStudyLayer")) {
          removeLayer(map, "VolumeStudyLayer");
        }
      }
    }
  }, [map, showLayer]);
  useEffect(() => {
    if (
      map &&
      view &&
      showLayer &&
      initDataGis &&
      lstStudyLocations &&
      roadSegmentLayer
    ) {
      if (isLayerExist(map, "VolumeStudyLayer")) {
        if (reCreateLayerEvenItExists) {
          removeLayer(map, "VolumeStudyLayer");
        } else {
          return;
        }
      }
      setIsLayerLoading(true);

      if (isCancelled.current) {
        setIsLayerLoading(false);
        isUnderConstruction.current = false;
        return;
      }

      const midblockLocations = lstStudyLocations.filter(
        (loc) =>
          loc.locationType.toString() === LocationType.Midblock.toString()
      );

      if (midblockLocations.length === 0) {
        notify(
          `${t("noResultFoundForSelectedDate")} in ${t("volumeStudy")} ${t("layer")}`,
          "info",
          5000
        );
      }

      let queryIds = generateQueryIds(
        initDataGis,
        "midblockGeoIdType",
        midblockLocations
      );

      const strRoadSegmentQuery = `${initDataGis.midblockGeoIdName} IN (${queryIds})`;

      const roadSegmentQuery = {
        outFields: ["*"],
        where: strRoadSegmentQuery,
        returnGeometry: true,
      };

      const queryingAndCreateLayer = () => {
        setIsLayerLoading(true);
        isUnderConstruction.current = true;
        view
          ?.whenLayerView(roadSegmentLayer)
          .then(function (layerView) {
            reactiveUtils
              .whenOnce(() => !layerView.updating)
              .then(() => {
                if (isCancelled.current || !isTheLastMovement.current) {
                  setIsLayerLoading(false);
                  isUnderConstruction.current = false;
                  return;
                }
                layerView
                  .queryFeatures(roadSegmentQuery)
                  .then(function (results) {
                    console.log("!!length", results.features.length);
                    if (isCancelled.current || !isTheLastMovement.current) {
                      setIsLayerLoading(false);
                      isUnderConstruction.current = false;
                      return;
                    }
                    const lstNewRoadSegmentGraphics = results.features
                      .filter(
                        (feature) =>
                          (feature.geometry as Polyline)?.paths?.length > 0
                      )
                      .map((place) => {
                        const paths = (place.geometry as Polyline).paths[0];
                        const [avgX, avgY] = getAveragePaths(paths);
                        // Convert to Longitude/Latitude and back to Web Mercator coordinates
                        const [lng, lat] = webMercatorUtils.xyToLngLat(
                          avgX,
                          avgY
                        );
                        const [x, y] = webMercatorUtils.lngLatToXY(lng, lat);

                        // Find matching location's average speed
                        const matchingLocation = lstStudyLocations.find(
                          (loc) =>
                            loc.geoId.toString() ===
                            place.attributes[
                              initDataGis.midblockGeoIdName
                            ].toString()
                        );

                        return new Graphic({
                          attributes: {
                            [initDataGis.midblockGeoIdName]:
                              place.attributes[
                                initDataGis.midblockGeoIdName
                              ].toString(),
                            northApproachVolume:
                              matchingLocation?.volumeSummary[
                                "northApproachVolume"
                              ].toString(),
                            southApproachVolume:
                              matchingLocation?.volumeSummary[
                                "southApproachVolume"
                              ].toString(),
                            westApproachVolume:
                              matchingLocation?.volumeSummary[
                                "westApproachVolume"
                              ].toString(),
                            eastApproachVolume:
                              matchingLocation?.volumeSummary[
                                "eastApproachVolume"
                              ].toString(),
                            totalVolume:
                              matchingLocation?.volumeSummary[
                                "totalVolume"
                              ].toString(),
                            studyType: matchingLocation?.studyType.toString(),
                            locationType:
                              matchingLocation?.locationType.toString(),
                          },
                          geometry: new Point({
                            x,
                            y,
                            spatialReference: {
                              wkid: place.geometry?.spatialReference?.wkid,
                            },
                          }),
                        });
                      });

                    const renderer = new UniqueValueRenderer({
                      field: "studyType",
                      uniqueValueInfos: [
                        {
                          value: StudyType.Volume,
                          symbol: new PictureMarkerSymbol({
                            url: "http://staticfile.tes.ca/gisMap/latestStudies/car.png",
                            width: 25,
                            height: 25,
                          }),
                        },
                      ],
                    });

                    const volumeStudyLayer = new FeatureLayer({
                      id: "VolumeStudyLayer",
                      title: "Volume Study",
                      source: generateMockDataForEmptySource(
                        lstNewRoadSegmentGraphics
                      ),
                      objectIdField: "ObjectId",
                      outFields: ["*"],
                      fields: [
                        {
                          alias: initDataGis.midblockGeoIdName,
                          defaultValue: undefined,
                          editable: true,
                          length: -1,
                          name: initDataGis.midblockGeoIdName,
                          nullable: false,
                          type: "string",
                        },
                        {
                          alias: "study Type",
                          defaultValue: undefined,
                          editable: true,
                          length: -1,
                          name: "studyType",
                          nullable: true,
                          // type: "integer",
                          type: "string",
                        },
                        {
                          alias: "North Approach Volume",
                          defaultValue: undefined,
                          editable: true,
                          length: -1,
                          name: "northApproachVolume",
                          nullable: true,
                          // type: "integer",
                          type: "string",
                        },
                        {
                          alias: "South Approach Volume",
                          defaultValue: undefined,
                          editable: true,
                          length: -1,
                          name: "southApproachVolume",
                          nullable: true,
                          // type: "integer",
                          type: "string",
                        },
                        {
                          alias: "East Approach Volume",
                          defaultValue: undefined,
                          editable: true,
                          length: -1,
                          name: "eastApproachVolume",
                          nullable: true,
                          // type: "integer",
                          type: "string",
                        },
                        {
                          alias: "West Approach Volume",
                          defaultValue: undefined,
                          editable: true,
                          length: -1,
                          name: "westApproachVolume",
                          nullable: true,
                          // type: "integer",
                          type: "string",
                        },
                        {
                          alias: "Total Volume",
                          defaultValue: undefined,
                          editable: true,
                          length: -1,
                          name: "totalVolume",
                          nullable: true,
                          // type: "integer",
                          type: "string",
                        },
                        {
                          alias: "Location Type",
                          name: "locationType",
                          type: "string",
                          editable: true,
                          nullable: true,
                        },
                      ],
                      popupTemplate: {
                        title: "Latest Volume Study",
                        content: [
                          {
                            type: "fields",
                            fieldInfos: [
                              {
                                fieldName: initDataGis.midblockGeoIdName,
                                label: "Geo ID",
                              },
                              {
                                fieldName: "northApproachVolume",
                                label: "North Approach Volume",
                              },
                              {
                                fieldName: "southApproachVolume",
                                label: "South Approach Volume",
                              },
                              {
                                fieldName: "eastApproachVolume",
                                label: "East Approach Volume",
                              },
                              {
                                fieldName: "westApproachVolume",
                                label: "West Approach Volume",
                              },
                              {
                                fieldName: "totalVolume",
                                label: "Total Volume",
                              },
                            ],
                          },
                        ],
                      },
                      renderer: renderer,
                    });

                    volumeStudyLayer
                      .load()
                      .then(() => {
                        if (isCancelled.current || !isTheLastMovement.current) {
                          setIsLayerLoading(false);
                          isUnderConstruction.current = false;
                          return;
                        }
                        removeLayer(map, "VolumeStudyLayer");
                        map.add(volumeStudyLayer);
                        if (legendExpand) legendExpand.expanded = false;
                      })
                      .catch((error) => {
                        onError(error);
                      });
                    layerSuccessfullyLoadedInDomCallback(
                      view,
                      volumeStudyLayer,
                      () => {
                        setIsLayerLoading(false);
                        isUnderConstruction.current = false;
                      }
                    );
                  })
                  .catch((error) => {
                    onError(error);
                  });
              })
              .catch((error) => {
                onError(error);
              });
          })
          .catch((error) => {
            onError(error);
          });
      };
      view.when(() => {
        if (isCancelled.current || isLayerExist(map, "VolumeStudyLayer")) {
          setIsLayerLoading(false);
          return;
        }
        if (isUnderConstruction.current) {
          return;
        }
        queryingAndCreateLayer();
      });
      removeStartStationaryWatch.current?.();
      removeStartStationaryWatch.current =
        afterMapStationaryWithDebounceCallback(
          view,
          () => {
            if (isLayerExist(map, "VolumeStudyLayer")) {
              isTheLastMovement.current = false;
            }
          },
          1
        );

      removeEndStationaryWatch.current?.();
      removeEndStationaryWatch.current = afterMapStationaryWithDebounceCallback(
        view,
        () => {
          isTheLastMovement.current = true;
          if (!isCancelled.current && !isUnderConstruction.current) {
            queryingAndCreateLayer();
          }
        },
        700
      );
    }
  }, [map, view, showLayer, initDataGis, lstStudyLocations, roadSegmentLayer]);

  return {
    isLatestVolumeStudyLayerLoading: isLayerLoading,
  };
};
