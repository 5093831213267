import i18n from "../../../../../../../react-i18next";
i18n.loadNamespaces("tabs");

const tabTitles = [
  {
    id: 0,
    text: "urbanArterial",
    content: "urbanArterial",
  },
  {
    id: 1,
    text: "collectorRuralArterial",
    content: "collectorRuralArterial",
  },
  {
    id: 2,
    text: "local",
    content:  "local",
  },
  {
    id: 3,
    text: "collisionExperience",
    content:  "local",
  },
]

export default tabTitles;