const tabTitles = [
    {
        id: 0,
        text: 'general',
        icon: 'fa-solid fa-ellipsis',
        content: 'Details tab content',
    },
    {
        id: 1,
        text: 'location',
        icon: 'fa-solid fa-location-dot',
        content: 'Location tab content',
    },
    {
        id: 2,
        text: 'map',
        icon: 'fa-solid fa-map-location-dot',
        content: 'Map tab content',
    },
];
export default tabTitles;

