import {TFieldsData} from "./index";
import {FieldCategoryType} from "../../types/field/enums/fieldEnums";

export const initialFieldsData: TFieldsData = {
    [FieldCategoryType.CollisionGeneral]: [],
    [FieldCategoryType.CollisionVehicle]: [],
    [FieldCategoryType.CollisionPerson]: [],
    [FieldCategoryType.CollisionRoad]: [],
    [FieldCategoryType.CollisionDriver]: [],
    [FieldCategoryType.CollisionPedestrian]: [],
    [FieldCategoryType.CollisionPassenger]: [],
    [FieldCategoryType.CollisionRemark]: [],
    [FieldCategoryType.InfrastructureMidBlockGeneral]: [],
    [FieldCategoryType.InfrastructureMidBlockDetails]: [],
    [FieldCategoryType.InfrastructureMidBlockOperation]: [],
    [FieldCategoryType.InfrastructureMidBlockCrossSection]: [],
    [FieldCategoryType.InfrastructureIntersectionGeneral]: [],
    [FieldCategoryType.InfrastructureIntersectionDetails]: [],
    [FieldCategoryType.InfrastructureIntersectionApproachDetails]: [],
    [FieldCategoryType.InfrastructureIntersectionOperation]: [],
    [FieldCategoryType.InfrastructureStreetGeneral]: [],
    [FieldCategoryType.InfrastructureMISGeneral]: [],
    [FieldCategoryType.InfrastructureTrafficSectionGeneral]: [],
    [FieldCategoryType.CountingStationGeneral]: [],
    [FieldCategoryType.PermanentCountingStationGeneral]: [],
    [FieldCategoryType.InfrastructureIntersectionRoot]: [],
    [FieldCategoryType.InfrastructureMidBlockRoot]: [],
}