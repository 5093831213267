//imports
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary
} from "devextreme-react/data-grid";
import React, { useEffect, useRef, useState } from "react";

import { NumberBox, SelectBox, TagBox } from "devextreme-react";
import { Button } from "devextreme-react/button";
import { Form, SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import { Switch } from "devextreme-react/switch";
import TextBox from "devextreme-react/text-box";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/auth";
import {
  FieldCategoryType,
  FieldLocation2Show,
  FieldServiceType,
  FieldType,
} from "../../../../types/field/enums/fieldEnums";
import { CodeGroup, TesField } from "../../../../types/field/fieldType";
import {
  GridType,
  ModalMode,
} from "../../../../types/general/enums/generalEnums";
import { TesDelete, TesGet, TesPost, TesPut } from "../../../../utils/rest";
import "./fields.scss";

import Permission from "../../../../components/permission/permision";
import Rule from "../../../../components/rule/Rule";
import getTitle from "../../../../components/title/getTitle";
import { InfrastructurePermissions } from "../../../../constants/Permissions";
import { FieldApiUrl } from "../../../../environment/routeSettings";
import { NameValue } from "../../../../types/general/generalTypes";
import { SortObjectByPropName, SumIntArray } from "../../../../utils/arrayTools";
import { OnExporting } from "../../../../utils/dataGridTools";
import { Enum2Array, EnumFlag2Array } from "../../../../utils/enumTools";
import { useScreenSize } from "../../../../utils/media-query";
import LoadingScreen, { CheckLoadingStates } from "../../../../components/loadingScreen/loadingScreen";

const Fields = () => {
  const [initData, setInitData] = useState<TesField[]>([]);
  const [initDataCodeGroups, setInitDataCodeGroups] = useState<CodeGroup[]>([]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedData, setSelectedData] = useState<TesField>(new TesField());
  const [lstFieldLocation, setLstFieldLocation] = useState<NameValue[]>([]);
  const [lstFiledType, setLstFiledType] = useState<NameValue[]>([]);
  const [lstFiledCategoryType, setLstFiledCategoryType] = useState<NameValue[]>([]);
  const { t } = useTranslation();
  const [getLoading, setGetLoading] = useState<boolean>(false);
  const [addLoading, setAddLoading] = useState<boolean>(false);
  const [UpdateLoading, setUpdateLoading] = useState<boolean>(false);
  const { isXLarge, is2xLarge } = useScreenSize();
  const [formHeight, setFormHeight] = useState<number>(400)
  const [popupHeight, setPopupHeight] = useState<string>("80%")
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  const title = getTitle('/infrastructure/settings/fields', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    if (isXLarge) { setPopupHeight("65%"); setFormHeight(440) }
    else if (is2xLarge) { setPopupHeight("35%"); setFormHeight(600) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        setGetLoading(true);
        setLstFiledType(Enum2Array(FieldType));
        setLstFiledCategoryType(Enum2Array(FieldCategoryType));
        setLstFieldLocation(Enum2Array(FieldLocation2Show));
        await getInitData();
        await getInitDataCodeGroups();
        setGetLoading(false);
      } catch (ex) {
        setGetLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Get InitData
  async function getInitData() {
    const rawData = await TesGet(
      FieldApiUrl() +
      "/api/TesFields/GetAllFields/" +
      localStorage.getItem("selectedCustomerId") +
      "/" +
      FieldServiceType.Infrastructure,
      true
    );
  
    const refinedData = rawData.map((row: any) => ({
      ...row,
      fieldLocation2ShowDisplay: EnumFlag2Array(FieldLocation2Show, row.fieldLocation2Show)
        ?.map((flag: number) => FieldLocation2Show[flag])
        .join(", "),
    }));
  
    setInitData(refinedData);
  }

  async function getInitDataCodeGroups() {
    setInitDataCodeGroups(
      await TesGet(
        FieldApiUrl() +
        "/api/codeGroups/GetCodeGroups/" +
        localStorage.getItem("selectedCustomerId") +
        "/" +
        FieldServiceType.Infrastructure,
        true
      )
    );
    const obj = {} as CodeGroup;
    initDataCodeGroups.push(obj);
  }

  function onEdit(d: any) {
    let newData = new TesField();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    newData.fieldServiceType = FieldServiceType.Infrastructure;
    setSelectedData(newData);
    setShowModal(true);
  }

  function onNew() {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }

  const onValueChanged = (key: string, value: any) => {
    setSelectedData({ ...selectedData, [key]: value });
  };

  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setSelectedData(new TesField());
      setShowModal(false);
    } else {
      setSelectedData(new TesField());
      setShowModal(false);
    }
  }

  async function onAdd() {
    try {
      setAddLoading(true);
      selectedData.customerId =
        localStorage.getItem("selectedCustomerId") ?? "";
      selectedData.fieldServiceType = FieldServiceType.Infrastructure;
      await TesPost(FieldApiUrl() + "/api/TesFields", selectedData, true);
      await getInitData();
      onCloseModal();
      setAddLoading(false);
      notify(t("dataSuccessfullyAdded"), "success", 5000);
    } catch (ex) {
      setAddLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  async function onUpdate() {
    try {
      setUpdateLoading(true);
      await TesPut(
        FieldApiUrl() + "/api/TesFields/" + selectedData.id,
        selectedData,
        true
      );
      await getInitData();
      onCloseModal();
      notify(t("dataSuccessfullyUpdated"), "success", 5000);
      setUpdateLoading(false);
    } catch (ex) {
      setUpdateLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  async function onDelete(d: any) {
    let myDialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              await TesDelete(
                FieldApiUrl() + "/api/TesFields/" + d.row.data.id,
                true
              );
              await getInitData();
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }

            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  return (
    <Permission
      allowed={[InfrastructurePermissions.Infrastructure_D_Setups]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        {CheckLoadingStates([getLoading, addLoading, UpdateLoading]) && <LoadingScreen/>}
        <React.Fragment>
          <h2 className={"content-block"}>{t("fields")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button onClick={onNew} icon="fa-solid fa-circle-plus" text={t("add")} />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.InfrastructureField.toString()}
                  savingTimeout={500}
                />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={285}
                  placeholder={t("search")}
                />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="name" caption={t("name")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="fieldCategoryType"
                  alignment="left"
                  caption={t("categoryType")}
                >
                  <Lookup
                    dataSource={SortObjectByPropName(lstFiledCategoryType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />

                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="fieldType"
                  alignment="left"
                  caption={t("fieldType")}
                >
                  <Lookup
                    dataSource={SortObjectByPropName(lstFiledType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />

                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="labelText" caption={t("labelText")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="index" alignment="left" caption={t("index")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="description"
                  allowSorting={false}
                  caption={t("description")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="detailViewIndex"
                  allowSorting={true}
                  caption={t("detailViewIndex")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="gridViewIndex"
                  allowSorting={true}
                  caption={t("gridViewIndex")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="mobileAppIndex"
                  allowSorting={true}
                  caption={t("mobileAppIndex")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="exactDT"
                  visible={false}
                  caption={t("exactDT")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="fieldLocation2ShowDisplay"
                  caption={t("fieldLocation2Show")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="isCalculatedField"
                  caption={t("isCalculatedField")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="isRequiredInApp"
                  caption={t("isRequiredInApp")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="isRequiredInWeb"
                  caption={t("isRequiredInWeb")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="isShowInApp" caption={t("isShowInApp")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="isShowInWeb" caption={t("isShowInWeb")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="isUpperCase" caption={t("isUpperCase")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="integrationAddress"
                  caption={t("integrationAddress")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="validationRule" caption={t("validationRule")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="codeGroupId" caption={t("codeGroup")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup displayExpr="name" valueExpr="id" dataSource={SortObjectByPropName(initDataCodeGroups, "name")} />
                </Column>
                <Column
                  dataField="isShowInDriver"
                  visible={false}
                  caption={t("isShowInDriver")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="isShowInPassenger"
                  visible={false}
                  caption={t("isShowInPassenger")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="isShowInPedestrian"
                  visible={false}
                  caption={t("isShowInPedestrian")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  type="buttons"
                  caption={t("actions")}
                  fixed={true}
                  fixedPosition="right"
                  width={110}
                >
                  <DevExpressButton
                    hint={t("edit")}
                    onClick={onEdit}
                    icon="fa-solid fa-pencil"
                  ></DevExpressButton>
                  <DevExpressButton
                    hint={t("delete")}
                    onClick={onDelete}
                    icon="fa-solid fa-trash-can"
                  ></DevExpressButton>
                </Column>
              </DataGrid>
            </div>
          </div>

          <Popup
            width={"50%"}
            height={popupHeight}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={modalMode === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <div style={{ height: formHeight }}>
              <ScrollView width="100%" height="100%">
                <Form colCount={2} className="popupFields">
                  <SimpleItem colSpan={1}>
                    <SelectBox
                      placeholder=""
                      label={t("categoryType")}
                      labelMode='floating'
                      value={selectedData.fieldCategoryType}
                      onValueChange={(e) => onValueChanged("fieldCategoryType", e)}
                      className="modalInput"
                      valueExpr="value"
                      displayExpr="name"
                      items={SortObjectByPropName(lstFiledCategoryType.filter(x => x.name.includes("Infrastructure")), "name")}
                      showClearButton={true}
                      searchEnabled={true}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <SelectBox
                      placeholder=""
                      label={t("fieldType")}
                      labelMode='floating'
                      value={selectedData.fieldType}
                      onValueChange={(e) => onValueChanged("fieldType", e)}
                      className="modalInput"
                      valueExpr="value"
                      displayExpr="name"
                      items={SortObjectByPropName(lstFiledType, "name")}
                      showClearButton={true}
                      searchEnabled={true}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <TextBox
                      label={t("name")}
                      labelMode='floating'
                      value={selectedData.name}
                      onValueChange={(e) => onValueChanged("name", e)}
                      className="modalInput"
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <TextBox
                      label={t("labelText")}
                      labelMode='floating'
                      value={selectedData.labelText}
                      onValueChange={(e) => onValueChanged("labelText", e)}
                      className="modalInput"
                    />
                  </SimpleItem>


                  <SimpleItem colSpan={1}>
                    <TextBox
                      label={t("description")}
                      labelMode='floating'
                      value={selectedData.description}
                      onValueChange={(e) => onValueChanged("description", e)}
                      className="modalInput"
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <TextBox
                      label={t("integrationAddress")}
                      labelMode='floating'
                      value={selectedData.integrationAddress}
                      onValueChange={(e) => onValueChanged("integrationAddress", e)}
                      className="modalInput"
                    />
                  </SimpleItem>

                  {selectedData.fieldType?.toString() ===
                    FieldType.List.toString() && (
                      <SimpleItem colSpan={1}>
                        <SelectBox
                          placeholder=""
                          label={t("codeGroup")}
                          labelMode='floating'
                          value={selectedData.codeGroupId}
                          onValueChange={(e) => onValueChanged("codeGroupId", e)}
                          className="modalInput"
                          valueExpr="id"
                          displayExpr="name"
                          items={SortObjectByPropName(initDataCodeGroups, "name")}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </SimpleItem>
                    )}




                  {(selectedData.fieldType?.toString() ===
                    FieldType.DateTime.toString() || selectedData.fieldType?.toString() ===
                    FieldType.Date.toString() || selectedData.fieldType?.toString() ===
                    FieldType.Time.toString()) && (
                      <SimpleItem colSpan={1}>
                        <div>{t("exactDT")}</div>
                        <Switch
                          style={{ marginTop: 5 }}
                          hint={t("exactDT")}
                          value={selectedData.exactDT}
                          onValueChange={(e) => onValueChanged("exactDT", e)}
                        />
                      </SimpleItem>
                    )}

                  <SimpleItem colSpan={1}>
                    <NumberBox
                      label={t("detailViewIndex")}
                      labelMode='floating'
                      value={selectedData.detailViewIndex}
                      onValueChange={(e) => onValueChanged("detailViewIndex", e)}
                      className="modalInput"
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <NumberBox
                      label={t("gridViewIndex")}
                      labelMode='floating'
                      value={selectedData.gridViewIndex}
                      onValueChange={(e) => onValueChanged("gridViewIndex", e)}
                      className="modalInput"
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <NumberBox
                      label={t("mobileAppIndex")}
                      labelMode='floating'
                      value={selectedData.mobileAppIndex}
                      onValueChange={(e) => onValueChanged("mobileAppIndex", e)}
                      className="modalInput"
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <TagBox
                      label={t("fieldLocation2Show")}
                      labelMode='floating'
                      className="modalInput"
                      dataSource={SortObjectByPropName(lstFieldLocation, "name")}
                      valueExpr="value"
                      displayExpr="name"
                      value={EnumFlag2Array(FieldLocation2Show, selectedData.fieldLocation2Show)}
                      onValueChange={(e) => onValueChanged("fieldLocation2Show", SumIntArray(e))}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <div>{t("isRequiredInApp")}</div>
                    <Switch
                      style={{ marginTop: 5 }}
                      hint={t("isRequiredInApp")}
                      value={selectedData.isRequiredInApp}
                      onValueChange={(e) => onValueChanged("isRequiredInApp", e)}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <div>{t("isRequiredInWeb")}</div>
                    <Switch
                      style={{ marginTop: 5 }}
                      hint={t("isRequiredInWeb")}
                      value={selectedData.isRequiredInWeb}
                      onValueChange={(e) => onValueChanged("isRequiredInWeb", e)}
                    />
                  </SimpleItem>


                  <SimpleItem colSpan={1}>
                    <div>{t("isCalculatedField")}</div>
                    <Switch
                      style={{ marginTop: 5 }}
                      hint={t("isCalculatedField")}
                      value={selectedData.isCalculatedField}
                      onValueChange={(e) => onValueChanged("isCalculatedField", e)}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <div>{t("isUpperCase")}</div>
                    <Switch
                      style={{ marginTop: 5 }}
                      hint={t("isUpperCase")}
                      value={selectedData.isUpperCase}
                      onValueChange={(e) => onValueChanged("isUpperCase", e)}
                    />
                  </SimpleItem>
                  {selectedData.isCalculatedField &&
                    <SimpleItem>
                      <Rule
                        name="rule"
                        onValueChange={onValueChanged}
                        defaultRuleValue={selectedData.rule}
                        fieldServiceType={FieldServiceType.Infrastructure}
                      />
                    </SimpleItem>
                  }


                  <SimpleItem colSpan={1}>
                    <div>{t("readOnlyField")}</div>
                    <Switch
                      style={{ marginTop: 5 }}
                      hint={t("readOnlyField")}
                      value={selectedData.readOnlyField}
                      onValueChange={(e) => onValueChanged("readOnlyField", e)}
                    />
                  </SimpleItem>

                </Form>
              </ScrollView>
            </div>
            <div className="rightColumn" style={{ marginTop: "0.5rem" }}>
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={() => onCloseModal()}
                text={t("cancel")}
              />
              {modalMode === ModalMode.Add ? (
                <Button
                  className="tes-modal-btn-add"
                  onClick={onAdd}
                  text={t("add")}
                />
              ) : (
                <Button
                  className="tes-modal-btn-add"
                  onClick={onUpdate}
                  text={t("update")}
                />
              )}
            </div>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Fields;