import i18n from "../../../../../react-i18next";

const tabTitles = [
  {
    id: 0,
    text: "aadt",
    icon: "fa-solid fa-clock-rotate-left",
    content: "Details tab content",
  },
  {
    id: 1,
    text: 'location',
    icon: 'fa-solid fa-map',
    content: 'Location tab content',
  },
  {
    id: 2,
    text: "studies",
    icon: "fa-regular fa-file-lines",
    content: "Details tab content",
  },
];
export default tabTitles;