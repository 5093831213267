import { FieldType } from "../../../../types/field/enums/fieldEnums";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import Expand from "@arcgis/core/widgets/Expand.js";
import LayerList from "@arcgis/core/widgets/LayerList";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import Measurement from "@arcgis/core/widgets/Measurement.js";
import Print from "@arcgis/core/widgets/Print";
import TileLayer from "@arcgis/core/layers/TileLayer";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import Basemap from "@arcgis/core/Basemap";
import { MapSetting } from "../../../../types/infrastructure/infrastructureTypes";
import Graphic from "@arcgis/core/Graphic";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import { ILocationCollisionSummery } from "../../../../types/collision/collisionTypes";
import { SimplifiedStudy } from "../../../../types/trafficStudy/trafficStudyTypes";
import { LocationTrafficSummaryDTO } from "../../../../types/trafficStudy/dtos/studyDto";
import Polyline from "@arcgis/core/geometry/Polyline";
import Point from "@arcgis/core/geometry/Point";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import { TmmsRankLayerLstStudyLocations } from "../hookLayers/useMmsRankLayer";
import Geometry from "@arcgis/core/geometry/Geometry";
import Extent from "@arcgis/core/geometry/Extent";

export const adjustViewZooming = async ({
  expand,
  view,
  layers,
  finishedZoomingCallback = (isFinished: boolean) => {},
}: {
  expand: number;
  view: MapView;
  layers: FeatureLayer[];
  finishedZoomingCallback?: (isFinished: boolean) => void;
}) => {
  try {
    await view.when();

    const extents = await Promise.all(
      layers.map((layer) => layer.queryExtent())
    );

    const combinedExtent = extents.reduce((acc, extent) => {
      return acc ? acc.union(extent.extent) : extent.extent;
    }, null);

    if (combinedExtent) {
      await view.when();
      await view.goTo(combinedExtent.expand(expand)); // Expand to add some padding
      finishedZoomingCallback(true);
    }
  } catch (error) {
    console.error("Error adjusting view:", error);
    finishedZoomingCallback(false);
  }
};

export const addPrintWidget = async (view: MapView) => {
  try {
    await view.when();

    if (!view.ui.find("printTools")) {
      const printWidget = new Print({
        view: view,
        printServiceUrl:
          "https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task",
      });

      const printTools = new Expand({
        id: "printTools",
        content: printWidget,
        view: view,
        expanded: false,
      });

      view.ui.add(printTools, "top-right");
    } else {
      console.log("Print widget is already added to the view.");
    }
  } catch (error) {
    console.error("Error adding print feature:", error);
  }
};

export const addLayerListWidget = async (view: MapView) => {
  try {
    await view.when();

    if (!view.ui.find("layerList")) {
      const layerList = new Expand({
        content: new LayerList({
          view: view,
        }),
        view: view,
        expanded: false,
      });
      view.ui.add(layerList, "top-right");
    } else {
      console.log("Layer widget is already added to the view.");
    }
  } catch (error) {
    console.error("Error adding print feature:", error);
  }
};

export const addMeasurementWidget = async (view: MapView) => {
  try {
    await view.when();

    if (!view.ui.find("distance")) {
      const measurementWidget = new Measurement();
      measurementWidget.view = view;
      view.ui.add(measurementWidget, "bottom-right");

      return measurementWidget;
    } else {
      console.log("measurementWidget is already added to the view.");
    }
  } catch (error) {
    console.error("Error adding measurementWidget:", error);
  }
};

export const addBasemapGalleryWidget = async (
  view: MapView,
  initDataGis: MapSetting
) => {
  try {
    await view.when();

    const tileLayers = initDataGis?.baseMapLayers.map((baseMapLayer) => {
      return new TileLayer({
        url: baseMapLayer.url,
        title: baseMapLayer.title,
      });
    });

    const basemapGallery = new BasemapGallery({
      view: view,
      source: {
        query: {
          title: "Canada Basemaps",
          owner: "Esri_cy_CA",
        },
        updateBasemapsCallback: function (items) {
          tileLayers.forEach((layer) => {
            let customBasemap = new Basemap({
              title: layer.title,
              baseLayers: [layer],
            });
            items.push(customBasemap);
          });

          return items;
        },
      },
    });
    const basemapExpand = new Expand({
      content: basemapGallery,
      view: view,
      expanded: false,
      expandIcon: "basemap", // Optional: Change the icon
      expandTooltip: "Basemap", // Custom tooltip text
    });

    view.ui.add(basemapExpand, "bottom-right");

    setTimeout(function () {
      // Customizing the Basemap Gallery appearance (optional)
      const basemapGalleryItems = document.querySelectorAll(
        ".esri-basemap-gallery__basemap"
      );
      basemapGalleryItems.forEach(function (item) {
        const label = item.querySelector(
          ".esri-basemap-gallery__basemap-label"
        ) as HTMLElement;
        if (label) {
          label.style.display = "none"; // Hide text labels
        }
        const icon = item.querySelector(
          ".esri-basemap-gallery__basemap-thumbnail"
        ) as HTMLElement;
        if (icon) {
          icon.style.width = "40px"; // Adjust icon size if needed
          icon.style.height = "40px"; // Adjust icon size if needed
        }
      });
    }, 1000);
  } catch (error) {
    console.error("Error adding BasemapGallery feature:", error);
  }
};

export const customizeZoomingBehavior = async (
  view: MapView,
  options: {
    disableMouseWheel?: boolean;
    disableDoubleClickZoom?: boolean;
    disableDoubleClickWithControlPressed?: boolean;
    disableKeyboardZoom?: boolean;
    disablePopupZoom?: boolean;
  },
  withoutPermissionZoomTryingCallback = (isTrying: boolean) => {}
) => {
  if (!view) return;
  await view.when();
  const {
    disableMouseWheel = true,
    disableDoubleClickZoom = true,
    disableDoubleClickWithControlPressed = true,
    disableKeyboardZoom = true,
    disablePopupZoom = true,
  } = options;

  const withoutPermissionZoomTrying = () => {
    withoutPermissionZoomTryingCallback(true);
  };

  // Remove default popup zoom actions if required, if add another popup in any layer it isn't affected
  if (disablePopupZoom) {
    view.popup.actions = view.popup.actions?.filter(function (action) {
      return action.id !== "zoom-to";
    });
  }

  if (disableMouseWheel) {
    view.on("mouse-wheel", (event) => {
      if (!event.native.ctrlKey) {
        event.stopPropagation();
        withoutPermissionZoomTrying();
      } else {
        withoutPermissionZoomTryingCallback(false);
      }
    });
    view.on("key-down", function (event) {
      if (event.key === "Control") {
        withoutPermissionZoomTryingCallback(false);
      }
    });
    view.on("key-up", function () {
      view.on("mouse-wheel", function (event) {
        if (!event.native.ctrlKey) {
          event.stopPropagation();
          withoutPermissionZoomTrying();
        }
      });
    });
  }

  if (disableDoubleClickZoom) {
    view.on("double-click", (event) => {
      event.stopPropagation();
    });
  }
  if (disableDoubleClickWithControlPressed) {
    view.on("double-click", ["Control"], (event) => {
      event.stopPropagation();
    });
  }
  if (disableKeyboardZoom) {
    view.on("key-down", function (event) {
      const prohibitedKeys = ["+", "-", "Shift", "_", "="];
      if (prohibitedKeys.indexOf(event.key) !== -1) {
        event.stopPropagation();
      }
    });
  }
};

export const getAveragePaths = (paths: number[][]) => {
  let sumX = 0;
  let sumY = 0;
  for (let i = 0; i < paths.length; i++) {
    sumX = sumX + paths[i][0];
    sumY = sumY + paths[i][1];
  }
  return [sumX / paths.length, sumY / paths.length];
};

export const webMercatorToLatLng = (x: number, y: number) => {
  const originShift = (2 * Math.PI * 6378137) / 2.0;
  const lng = (x / originShift) * 180.0;
  let lat = (y / originShift) * 180.0;

  lat =
    (180 / Math.PI) *
    (2 * Math.atan(Math.exp((lat * Math.PI) / 180.0)) - Math.PI / 2.0);
  return [lat, lng];
};

export const openInGoogleMap = (selectedFeature: Graphic) => {
  let point = [0, 0];
  let coordinate = [0, 0];
  if ((selectedFeature.geometry as Polyline)?.paths) {
    point = getAveragePaths((selectedFeature.geometry as Polyline)?.paths[0]);
    coordinate = webMercatorToLatLng(point[0], point[1]);
  } else {
    coordinate = [
      (selectedFeature.geometry as Point)?.latitude,
      (selectedFeature.geometry as Point)?.longitude,
    ];
  }
  window.open(
    `https://www.google.com/maps?q=${coordinate[0]},${coordinate[1]}`,
    "_blank"
  );
};

export const openStreetView = (selectedFeature: Graphic) => {
  let point = [0, 0];
  let coordinate = [0, 0];
  if ((selectedFeature.geometry as Polyline)?.paths) {
    point = getAveragePaths((selectedFeature.geometry as Polyline)?.paths[0]);
    coordinate = webMercatorToLatLng(point[0], point[1]);
  } else {
    coordinate = [
      (selectedFeature.geometry as Point)?.latitude,
      (selectedFeature.geometry as Point)?.longitude,
    ];
  }
  window.open(
    `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${coordinate[0]},${coordinate[1]}`,
    "_blank"
  );
};

export const onSelectedFeature_v1 = (
  view: MapView,
  callback: (graphic: Graphic) => void
) => {
  reactiveUtils.watch(
    () => view.popup.selectedFeature,
    (selectedFeature) => {
      if (selectedFeature) {
        callback(selectedFeature);
      }
    }
  );
};

export const isLayerExist = (map: Map, layerId: string) => {
  return map.layers.some((layer) => layer.id === layerId);
};

export const removeLayer = (map: Map, layerId: string) => {
  if (map) {
    const layer = map.layers?.find((x) => x.id === layerId);
    layer && map.remove(layer);
  }
};

export const getLayerByLayerId = (map: Map, layerId: string) => {
  const foundLayer = map.layers.find((l) => l.id === layerId);
  return foundLayer ? foundLayer : null;
};

export const layerSuccessfullyLoadedInDomCallback = (
  view: MapView,
  layer: FeatureLayer,
  callback: () => void
) => {
  view.on("layerview-create", (event) => {
    if (event.layer === layer) {
      callback();
    }
  });
};

export const addClickOnMapTrigger = (
  view: MapView,
  callback: (event: __esri.ViewClickEvent, res: __esri.MapViewViewHit) => void
) => {
  view.when(() => {
    view.on("click", (event) => {
      // const screenPoint = event.screenPoint;
      const screenPoint = event;
      view.hitTest(screenPoint).then((response) => {
        if (response.results.length > 0) {
          callback(event, response.results[0]);
        }
      });
    });
  });
};

export const addDoubleClickWithControlOnMapTrigger = (
  view: MapView,
  callback: (event: __esri.ViewDoubleClickEvent) => void
) => {
  view.when(() => {
    view.on("double-click", ["Control"], function (event) {
      callback(event);
      event.stopPropagation();
    });
  });
};

export const createGraphic = (
  view: MapView,
  lat: number,
  long: number,
  id: string | null
) => {
  view.when(() => {
    const pointGraphic = new Graphic({
      geometry: new Point({
        longitude: long,
        latitude: lat,
      }),
      symbol: new SimpleMarkerSymbol({
        color: "#12492f",
        size: 8,
      }),
      attributes: {
        id: id,
      },
    });

    view.graphics.add(pointGraphic);
  });
};

export const findRoadSegments = ({
  view,
  roadSegmentLayer,
  initDataGis,
  shouldGoToFirstFoundItem,
  lstRoadSegmentGeoIds,
  setRoadSegmentHighlightedRefs,
  foundRoadSegmentLatLongCallback,
  callback,
}: {
  view: MapView;
  roadSegmentLayer: FeatureLayer;
  initDataGis: MapSetting;
  shouldGoToFirstFoundItem: boolean;
  lstRoadSegmentGeoIds: string[];
  setRoadSegmentHighlightedRefs: (refs: __esri.Handle[]) => void;
  foundRoadSegmentLatLongCallback?: (value: {
    lat: number;
    long: number;
    x: number;
    y: number;
  }) => void;
  callback?: (foundResult: Graphic[]) => void;
}) => {
  if (!view || !roadSegmentLayer || lstRoadSegmentGeoIds.length === 0) return;

  const strRoadSegmentQuery = lstRoadSegmentGeoIds
    .map((s) =>
      initDataGis.midblockGeoIdType.toString() === FieldType.String.toString()
        ? `${initDataGis.midblockGeoIdName}='${s}'`
        : `${initDataGis.midblockGeoIdName}=${s}`
    )
    .join(" OR ");

  if (!strRoadSegmentQuery) {
    return;
  }
  const query = {
    outFields: ["*"],
    where: strRoadSegmentQuery,
    returnGeometry: true,
  };

  //because of querying locally, need all features appear in screen, in esri v4 api can handle it without zoom out
  roadSegmentLayer.load().then(() => {
    // Set the view extent to the data extent
    view.extent = roadSegmentLayer.fullExtent;
  });

  setTimeout(() => {
    // queries when view and layer has been loaded
    view?.whenLayerView(roadSegmentLayer).then(function (layerView) {
      reactiveUtils
        .whenOnce(() => !layerView.updating)
        .then(() => {
          layerView
            .queryFeatures(query)
            .then(function (results) {
              const graphics = results.features;
              if (graphics.length > 0) {
                const path = (graphics[0]?.geometry as Polyline)?.paths[0]; // Get the first path of the polyline
                const numPoints = path.length;
                // Calculate the midpoint by averaging all x and y coordinates
                const avgX =
                  path.reduce((sum, point) => sum + point[0], 0) / numPoints;
                const avgY =
                  path.reduce((sum, point) => sum + point[1], 0) / numPoints;
                const point = new Point({
                  x: avgX,
                  y: avgY,
                  spatialReference: view.spatialReference,
                });
                foundRoadSegmentLatLongCallback?.({
                  lat: point.latitude,
                  long: point.longitude,
                  x: point.x,
                  y: point.y,
                });
                callback?.(results.features);

                const highlight = layerView.highlight(graphics);
                setRoadSegmentHighlightedRefs([highlight]);

                if (shouldGoToFirstFoundItem) {
                  view.goTo(
                    {
                      target: graphics[0].geometry,
                    },
                    {
                      animate: true,
                      duration: 2000,
                      easing: "ease-out",
                    }
                  );
                }
              }
            })

            .catch();
        });
    });
  }, 0);
};

export const findIntersections = ({
  view,
  intersectionLayer,
  initDataGis,
  shouldGoToFirstFoundItem,
  lstIntersectionGeoIds,
  setIntersectionHighlightedRefs,
  foundIntersectionLatLongCallback,
  callback,
}: {
  view: MapView;
  intersectionLayer: FeatureLayer;
  initDataGis: MapSetting;
  shouldGoToFirstFoundItem: boolean;
  lstIntersectionGeoIds: string[];
  setIntersectionHighlightedRefs: (refs: __esri.Handle[]) => void;
  foundIntersectionLatLongCallback?: (value: {
    lat: number;
    long: number;
    x: number;
    y: number;
  }) => void;
  callback?: (foundResult: Graphic[]) => void;
}) => {
  if (!view || !intersectionLayer || lstIntersectionGeoIds.length === 0) return;

  const strIntersectionQuery = lstIntersectionGeoIds
    .map((s) =>
      initDataGis.intersectionGeoIdType.toString() ===
      FieldType.String.toString()
        ? `${initDataGis.intersectionGeoIdName}='${s}'`
        : `${initDataGis.intersectionGeoIdName}=${s}`
    )
    .join(" OR ");

  if (!strIntersectionQuery) {
    return;
  }
  const query = {
    outFields: ["*"],
    where: strIntersectionQuery,
    returnGeometry: true,
  };

  //because of querying locally, need all features appear in screen, in esri v4 api can handle it without zoom out
  intersectionLayer.load().then(() => {
    // Set the view extent to the data extent
    view.extent = intersectionLayer.fullExtent;
  });

  setTimeout(() => {
    // queries when view and layer has been loaded
    view?.whenLayerView(intersectionLayer).then(function (layerView) {
      reactiveUtils
        .whenOnce(() => !layerView.updating)
        .then(() => {
          layerView
            .queryFeatures(query)
            .then(function (results) {
              const graphics = results.features;

              foundIntersectionLatLongCallback?.({
                lat: (graphics?.[0]?.geometry as Point)?.latitude,
                long: (graphics?.[0]?.geometry as Point)?.longitude,
                x: (graphics?.[0]?.geometry as Point)?.x,
                y: (graphics?.[0]?.geometry as Point)?.y,
              });
              callback?.(results.features);

              const highlight = layerView.highlight(graphics);
              setIntersectionHighlightedRefs([highlight]);

              if (shouldGoToFirstFoundItem) {
                view.goTo(
                  {
                    target: graphics[0].geometry,
                    zoom: 15, // Optionally set the zoom level
                  },
                  {
                    animate: true,
                    duration: 2000,
                    easing: "ease-out",
                  }
                );
              }
            })
            .catch();
        });
    });
  }, 0);
};

function debounce(func: (...args: any[]) => void, delay: number) {
  let timeout: any;
  return function (...args: any[]) {
    clearTimeout(timeout);
    // @ts-ignore
    const context: any = this;
    timeout = setTimeout(() => {
      return func.apply(context, args);
    }, delay);
  };
}

export function afterMapStationaryWithDebounceCallback(
  view: MapView,
  callback: () => void,
  delay: number
): () => void {
  const handle = view.watch(
    "stationary",
    debounce((isStationary: any) => {
      if (isStationary) {
        callback();
      }
    }, delay)
  );
  return handle.remove;
}

export const generateQueryIds = (
  initDataGis: MapSetting,
  geoIdTypeKey: keyof MapSetting,
  locations:
    | ILocationCollisionSummery[]
    | SimplifiedStudy[]
    | TmmsRankLayerLstStudyLocations
    | LocationTrafficSummaryDTO[]
) => {
  return locations
    .map((loc) => {
      if (
        initDataGis?.[geoIdTypeKey]?.toString() === FieldType.String.toString()
      ) {
        return `'${loc.geoId}'`;
      } else if (
        initDataGis?.[geoIdTypeKey]?.toString() === FieldType.Number.toString()
      ) {
        return Number(loc.geoId) ? loc.geoId : 0;
      } else {
        return loc.geoId;
      }
    })
    .join(",");
};

export const generateMockDataForEmptySource = (data: Graphic[]) => {
  if (data && data.length > 0) {
    return data;
  } else {
    const mockData = new Graphic({
      attributes: {
        uniqueId: "undefined",
        globalId: "undefined",
        objectId: "undefined",
        geoId: "undefined",
      },
      geometry: new Point({
        x: 0,
        y: 0,
      }),
    });
    return [mockData];
  }
};

export const zoomToAreaOfGeometries = (
  view: MapView,
  geometries: Geometry[]
) => {
  const combinedExtent = geometries.reduce<Extent | null>((acc, geometry) => {
    const geomExtent = geometry.extent;
    return acc ? acc.union(geomExtent) : geomExtent;
  }, null);

  if (combinedExtent) {
    view.goTo(combinedExtent.expand(1.2)); // Expand the extent slightly for better visualization
  }
};
