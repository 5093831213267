import { RequestResponseResult } from "../../types/general/generalTypes";
import { TesGet } from "../../utils/rest";
import { SettingApiUrl } from "../../environment/routeSettings";
import {
  CustomerDefaultGridSetting,
  GridProfile,
} from "../../types/setting/gridSettingTypes";
import { ResponseCode } from "../../types/general/enums/generalEnums";

export const storeDefaultGridSettingsKeyName = "TESGridSettings";
export const storeUserGridSettingsKeyName = "TESUserGridSettings";

export const fetchDefaultGridSettings = async () => {
  const customerId = localStorage.getItem("selectedCustomerId");
  if (!customerId) return [];
  try {
    const res: RequestResponseResult<CustomerDefaultGridSetting[]> =
      await TesGet(
        `${SettingApiUrl()}/api/CustomerDefaultGridSettings/GetByCustomerId/${customerId}`,
        true,
        false
      );
    if (res.responseCode === ResponseCode.OK) {
      return res.results;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching languages:", error);
    return [];
  }
};
export const fetchUserGridSettings = async () => {
  const customerId = localStorage.getItem("selectedCustomerId");
  if (!customerId) return [];
  try {
    const res: RequestResponseResult<GridProfile[]> = await TesGet(
      `${SettingApiUrl()}/api/GridProfiles/GetUserProfiles/${customerId}`,
      true,
      false
    );
    if (res.responseCode === ResponseCode.OK) {
      return res.results;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching languages:", error);
    return [];
  }
};

export const getStoredDefaultGridSettings = ():
  | CustomerDefaultGridSetting[]
  | [] => {
  return JSON.parse(
    localStorage.getItem(storeDefaultGridSettingsKeyName) || "[]"
  );
};
export const getStoredUserGridSettings = (): GridProfile[] | [] => {
  return JSON.parse(localStorage.getItem(storeUserGridSettingsKeyName) || "[]");
};
export const storeDefaultGridSettings = (
  defaultSettings: CustomerDefaultGridSetting[]
) => {
  localStorage.setItem(
    storeDefaultGridSettingsKeyName,
    JSON.stringify(defaultSettings)
  );
};
export const storeUserGridSettings = (userSettings: GridProfile[]) => {
  localStorage.setItem(
    storeUserGridSettingsKeyName,
    JSON.stringify(userSettings)
  );
};
