import React, { useEffect, useState } from 'react';
import Tabs from 'devextreme-react/tabs';
import tabTitles from './data/index';
import Users from './components/users/users';
import { TesGet, TesPost } from '../../../utils/rest';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import notify from 'devextreme/ui/notify';
import { Button } from 'devextreme-react';
import { t } from 'i18next';
import { custom } from 'devextreme/ui/dialog';
import './divisioindetails.scss'
import { IDivisionParams } from '../../../types/identity/identityTypes';
import { VMAssignUsers2Division, VMFindUser } from '../../../types/identity/dto/identityDTO';
import { RequestResponseResult } from '../../../types/general/generalTypes';
import { ResponseCode } from '../../../types/general/enums/generalEnums';
import { useAuth } from '../../../contexts/auth';
import { AuthApiUrl } from '../../../environment/routeSettings';
import ShareURL from '../../../components/shareURL/shareURL';
import TesTabs from '../../../components/tesTab/tesTab';

const DivisionDetails = () => {
    const history = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [dataChanged] = useState(false);
    const [, setBackPermission] = useState(false);
    const [isLocked, setIsLocked] = useState(false);
    const [initData, setInitData] = useState<VMFindUser[]>();
    const params = useParams<IDivisionParams>();
    const { activeLoading } = useAuth();
    const location = useLocation();
    const url = `${window.location.origin}${location.pathname}`;

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getInitialData();
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    async function getInitialData() {
        setInitData(await TesGet(AuthApiUrl() + "/api/Divisions/GetUsersInDivision/" + params.divisionId, true));
    };

    function handleTabChange(index: number) {
        setSelectedIndex(index);
    }
    //lock functions
    function changeLockStatus() {
        setIsLocked(!isLocked)
    }



    //permission functions
    function goBackPermission() {
        if (dataChanged === true) {
            //dialog to show if you want to save the changed the data or discard it.
            let myDialog = custom({
                title: t("warning"),
                messageHtml: t("unsavedDataWarningText"),
                buttons: [{
                    text: t("yes"),
                    onClick: (e) => {
                        try {
                            add();
                            notify(t("dataSuccessfullyUpdated"), "success", 5000);

                        } catch {
                            notify(t("someErrorOccurred"), "error", 5000);
                        }
                        return { buttonText: e.component.option("text") }
                    }
                },
                {
                    text: t("no"),
                    onClick: (e) => {
                        history(-1);
                        return { buttonText: e.component.option("text") }
                    }
                },
                {
                    text: t("cancel"),
                    onClick: (e) => {
                        setBackPermission(false)
                        return { buttonText: e.component.option("text") }
                    }
                },
                ]
            });
            myDialog.show();

        } else {
            history(-1);
        }
    }

    //add function 
    async function add() {
        try {
            if (activeLoading) activeLoading(true);

            const postObj: VMAssignUsers2Division = {
                divisionId: params.divisionId!,
                userIds: initData?.map(x => x.id),

            }
            const res = (await (TesPost(AuthApiUrl() + "/api/Divisions/AssignUsers2Division", postObj, true))) as RequestResponseResult<null>
            if (res.responseCode === ResponseCode.OK) {
                if (activeLoading) activeLoading(false);
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
            } else if (res.responseCode === ResponseCode.UserNotPermitted) {
                notify(t("userNotPermitted"), "error", 5000);

            }
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred" + ex), "error", 5000);
        }
    }
    //User functions
    async function addUsers(data: VMFindUser[]) {
        setInitData(data);
    }
    async function removeUser(userId: string) {
        setInitData(initData?.filter(x => x.id !== userId));
    }
    return (
        <React.Fragment>
            <div className={'content-block'}>
                <div className={'dx-card'}>
                    <div className="row" style={{ marginTop: 15 }}>
                        <div className='leftColumn'>
                            <Button
                                onClick={() => goBackPermission()}
                                icon="fa-solid fa-arrow-left"
                                hint={t("goBack")}
                            />
                        </div>
                        <div className='rightColumn'>
                            {params.divisionId !== "AddNew" && (
                                <ShareURL
                                    url={url}
                                />
                            )}
                            <Button
                                onClick={() => add()}
                                icon="fa-solid fa-floppy-disk"
                                hint={t('save')}
                            />
                            <Button
                                onClick={() => changeLockStatus()}
                                icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                                hint={isLocked ? t("unlock") : t("lock")}
                            />
                        </div>
                    </div>
                </div>
                <div className={'dx-card '}>
                    <TesTabs
                        width={'100%'}
                        dataSource={tabTitles}
                        selectedIndex={selectedIndex}
                        onTabChange={handleTabChange}
                    />
                    {selectedIndex === 0 &&
                        <Users
                            initData={initData}
                            addUsers={addUsers}
                            removeUser={removeUser}
                        />
                    }
                </div>
            </div>
        </React.Fragment >
    );
}
export default DivisionDetails;
