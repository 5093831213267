import { useState, useEffect } from "react";
import {
    NumberBox,
    SelectBox, TagBox,
} from "devextreme-react";
import { Form, SimpleItem } from "devextreme-react/form";
import { Button } from "devextreme-react/button";
import { t } from "i18next";
import React from "react";
import { TesGet, TesPost } from "../../../../../utils/rest";
import { useAuth } from "../../../../../contexts/auth";
import notify from "devextreme/ui/notify";
import { NameValue } from "../../../../../types/general/generalTypes";
import { AADTFactorType, AADTTableCalculationSettingsType, DayDefinitionType, SignalWarrantAlgorithmType } from "../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { Enum2Array, EnumFlag2Array } from '../../../../../utils/enumTools';
import { TrafficStudyGeneralSetting } from "../../../../../types/trafficStudy/trafficStudyTypes";
import { TrafficApiUrl } from "../../../../../environment/routeSettings";
import { SortObjectByPropName, SumIntArray } from "../../../../../utils/arrayTools";
import CollapsibleCard from "../../../../../components/collapsibleCard/collapsibleCard";
interface IProps {
    clientId: string | undefined
}
const TrafficStudy = (props: IProps) => {
    const { activeLoading } = useAuth();
    const [initData, setInitData] = useState<TrafficStudyGeneralSetting>(new TrafficStudyGeneralSetting());
    const [lstDayDefinition, setLstDayDefinition] = useState<NameValue[]>([]);
    const [lstSignalWarrantAlgorithmType, setLstSignalWarrantAlgorithmType] = useState<NameValue[]>([]);
    const [lstAADTTableCalculationSettingsType, setLstAADTTableCalculationSettingsType] = useState<NameValue[]>([]);
    const [lstAADTFactorType, setLstAADTFactorType] = useState<NameValue[]>([]);
    const lstPercentile = [{ key: 55, value: 55 }, { key: 60, value: 60 }, { key: 65, value: 65 }, { key: 70, value: 70 }, { key: 75, value: 75 }, { key: 80, value: 80 }, { key: 90, value: 90 }]
    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getInitData();
                setLstDayDefinition(Enum2Array(DayDefinitionType))
                setLstSignalWarrantAlgorithmType(Enum2Array(SignalWarrantAlgorithmType))
                setLstAADTTableCalculationSettingsType(Enum2Array(AADTTableCalculationSettingsType))
                setLstAADTFactorType(Enum2Array(AADTFactorType))
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getInitData() {
        setInitData(await TesGet(TrafficApiUrl() + "/api/Setups/GetCustomerGeneralSettings/" + props.clientId, true));
    }

    async function onSave() {
        try {
            const postObj = {
                ...initData,
                customerId: props.clientId,
            };
            if (activeLoading) activeLoading(true);
            await TesPost(
                TrafficApiUrl() + "/api/Setups/AddUpdateGeneralSettings",
                postObj,
                true
            );
            await getInitData();
            if (activeLoading) activeLoading(false);
            notify(t("dataSuccessfullyAdded"), "success", 5000);
        } catch {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred"), "error", 5000);
        }
    }

    const onValueChanged = (key: string, value: any) => {
        setInitData({ ...initData, [key]: value });
    };

    const onAADTTableCalculationSelectBoxValueChanged = (key: string, value: any) => {
        setInitData({ ...initData, aadtTableCalculationSettings: {...initData.aadtTableCalculationSettings, [key]: value}});
    };

    const onAADTTableCalculationTagBoxValueChanged = (key: string, value: any) => {
        setInitData({ ...initData, aadtTableCalculationSettings: {...initData.aadtTableCalculationSettings, [key]: SumIntArray(value) }});
    };

    return (
        <React.Fragment>
            <div className="row" style={{ marginTop: 20, padding: 10 }}>
                <Form colCount={2} className='popupFields'>
                    <SimpleItem colSpan={1}>
                        <SelectBox
                            placeholder=""
                            label={t('tmcDayDefinition')}
                            valueExpr="value"
                            displayExpr="name"
                            labelMode='floating'
                            value={initData?.tmcDayDefinition}
                            onValueChange={(e) => onValueChanged("tmcDayDefinition", e)}
                            className="modalInput"
                            items={SortObjectByPropName(lstDayDefinition, "name")}
                            showClearButton={true}
                            searchEnabled={true}
                        />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                        <SelectBox
                            placeholder=""
                            label={t('signalWarrantAlgorithmType')}
                            valueExpr="value"
                            displayExpr="name"
                            labelMode='floating'
                            value={initData?.signalWarrantAlgorithmType}
                            onValueChange={(e) => onValueChanged("signalWarrantAlgorithmType", e)}
                            className="modalInput"
                            items={SortObjectByPropName(lstSignalWarrantAlgorithmType, "name")}
                            showClearButton={true}
                            searchEnabled={true}
                        />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                        <SelectBox
                            placeholder=""
                            label={t('speedDayDefinition')}
                            valueExpr="value"
                            displayExpr="name"
                            labelMode='floating'
                            value={initData?.speedDayDefinition}
                            onValueChange={(e) => onValueChanged("speedDayDefinition", e)}
                            className="modalInput"
                            items={SortObjectByPropName(lstDayDefinition, "name")}
                            showClearButton={true}
                            searchEnabled={true}
                        />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                        <SelectBox
                            placeholder=""
                            label={t('volumeDayDefinition')}
                            valueExpr="value"
                            displayExpr="name"
                            labelMode='floating'
                            value={initData?.volumeDayDefinition}
                            onValueChange={(e) => onValueChanged("volumeDayDefinition", e)}
                            className="modalInput"
                            items={SortObjectByPropName(lstDayDefinition, "name")}
                            showClearButton={true}
                            searchEnabled={true}
                        />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                        <NumberBox
                            style={{ marginBottom: 20 }}
                            label={t("volumeChangeThresholdError")}
                            labelMode='floating'
                            value={initData?.volumeChangeThresholdError}
                            onValueChange={(e) => onValueChanged("volumeChangeThresholdError", e)}
                        />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                        <NumberBox
                            style={{ marginBottom: 20 }}
                            label={t("minuteAllowedConsecutiveZeroInterval")}
                            labelMode='floating'
                            value={initData?.minuteAllowedConsecutiveZeroInterval}
                            onValueChange={(e) => onValueChanged("minuteAllowedConsecutiveZeroInterval", e)}
                        />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                        <TagBox
                            items={SortObjectByPropName(lstPercentile, "key")}
                            searchEnabled={true}
                            label={t('studyTypes')}
                            valueExpr="value"
                            displayExpr="key"
                            value={initData?.speedPercentiles}
                            onValueChange={(e) => onValueChanged("speedPercentiles", e)}
                        >
                        </TagBox>
                    </SimpleItem>
                </Form>
                <div style={{margin:"1rem"}} id="aadtTableCalculationSettingsCollapse">
                    <CollapsibleCard title={t('aadtTableCalculationSettings')} isOpen={false}>
                        <Form colCount={3}>
                          <SimpleItem colSpan={1}>
                            <SelectBox
                                placeholder=""
                                label={t('intersectionCalculationType')}
                                valueExpr="value"
                                displayExpr="name"
                                labelMode='floating'
                                value={initData?.aadtTableCalculationSettings?.intersectionCalculationType}
                                onValueChange={(e) => onAADTTableCalculationSelectBoxValueChanged("intersectionCalculationType", e)}
                                className="modalInput"
                                items={SortObjectByPropName(lstAADTTableCalculationSettingsType, "name")}
                                showClearButton={true}
                                searchEnabled={true} 
                            />
                          </SimpleItem>
                          <SimpleItem colSpan={1}>
                            <TagBox
                                items={SortObjectByPropName(lstAADTFactorType, "name")}
                                searchEnabled={true}
                                label={t('intersectionAADTFactorType')}
                                valueExpr="value"
                                displayExpr="name"
                                value={EnumFlag2Array(AADTFactorType, initData?.aadtTableCalculationSettings?.intersectionAADTFactorType)}
                                onValueChange={(e) => onAADTTableCalculationTagBoxValueChanged("intersectionAADTFactorType", e)}
                            >
                            </TagBox>
                          </SimpleItem>
                          <SimpleItem colSpan={1}>
                            <SelectBox
                              placeholder=""
                              label={t('roadSegmentCalculationType')}
                              valueExpr="value"
                              displayExpr="name"
                              labelMode="floating"
                              value={initData?.aadtTableCalculationSettings?.roadSegmentCalculationType}
                              onValueChange={(e) => onAADTTableCalculationSelectBoxValueChanged("roadSegmentCalculationType", e)}
                              className="modalInput"
                              items={SortObjectByPropName(lstAADTTableCalculationSettingsType, "name")}
                              showClearButton={true}
                              searchEnabled={true}
                            />
                          </SimpleItem>
                          <SimpleItem colSpan={1}>
                            <TagBox
                              items={SortObjectByPropName(lstAADTFactorType, "name")}
                              searchEnabled={true}
                              label={t('roadSegmentAADTFactorType')}
                              valueExpr="value"
                              displayExpr="name"
                              value={EnumFlag2Array(AADTFactorType, initData?.aadtTableCalculationSettings?.roadSegmentAADTFactorType)}
                              onValueChange={(e) => onAADTTableCalculationTagBoxValueChanged("roadSegmentAADTFactorType", e)}
                            />
                          </SimpleItem>
                          <SimpleItem colSpan={1}>
                            <SelectBox
                              placeholder=""
                              label={t('rampCalculationType')}
                              valueExpr="value"
                              displayExpr="name"
                              labelMode="floating"
                              value={initData?.aadtTableCalculationSettings?.rampCalculationType}
                              onValueChange={(e) => onAADTTableCalculationSelectBoxValueChanged("rampCalculationType", e)}
                              className="modalInput"
                              items={SortObjectByPropName(lstAADTTableCalculationSettingsType, "name")}
                              showClearButton={true}
                              searchEnabled={true}
                            />
                          </SimpleItem>
                          <SimpleItem colSpan={1}>
                            <TagBox
                              items={SortObjectByPropName(lstAADTFactorType, "name")}
                              searchEnabled={true}
                              label={t('rampAADTFactorType')}
                              valueExpr="value"
                              displayExpr="name"
                              value={EnumFlag2Array(AADTFactorType, initData?.aadtTableCalculationSettings?.rampAADTFactorType)}
                              onValueChange={(e) => onAADTTableCalculationTagBoxValueChanged("rampAADTFactorType", e)}
                            />
                          </SimpleItem>                         
                          <SimpleItem colSpan={1}>
                            <SelectBox
                              placeholder=""
                              label={t('rampTerminalCalculationType')}
                              valueExpr="value"
                              displayExpr="name"
                              labelMode="floating"
                              value={initData?.aadtTableCalculationSettings?.rampTerminalCalculationType}
                              onValueChange={(e) => onAADTTableCalculationSelectBoxValueChanged("rampTerminalCalculationType", e)}
                              className="modalInput"
                              items={SortObjectByPropName(lstAADTTableCalculationSettingsType, "name")}
                              showClearButton={true}
                              searchEnabled={true}
                            />
                          </SimpleItem>
                          <SimpleItem colSpan={1}>
                            <TagBox
                              items={SortObjectByPropName(lstAADTFactorType, "name")}
                              searchEnabled={true}
                              label={t('rampTerminalAADTFactorType')}
                              valueExpr="value"
                              displayExpr="name"
                              value={EnumFlag2Array(AADTFactorType, initData?.aadtTableCalculationSettings?.rampTerminalAADTFactorType)}
                              onValueChange={(e) => onAADTTableCalculationTagBoxValueChanged("rampTerminalAADTFactorType", e)}
                            />
                          </SimpleItem>
                          <SimpleItem colSpan={1}>
                            <SelectBox
                              placeholder=""
                              label={t('sideRoadCalculationType')}
                              valueExpr="value"
                              displayExpr="name"
                              labelMode="floating"
                              value={initData?.aadtTableCalculationSettings?.sideRoadCalculationType}
                              onValueChange={(e) => onAADTTableCalculationSelectBoxValueChanged("sideRoadCalculationType", e)}
                              className="modalInput"
                              items={SortObjectByPropName(lstAADTTableCalculationSettingsType, "name")}
                              showClearButton={true}
                              searchEnabled={true}
                            />
                          </SimpleItem>
                          <SimpleItem colSpan={1}>
                            <TagBox
                              items={SortObjectByPropName(lstAADTFactorType, "name")}
                              searchEnabled={true}
                              label={t('sideRoadAADTFactorType')}
                              valueExpr="value"
                              displayExpr="name"
                              value={EnumFlag2Array(AADTFactorType, initData?.aadtTableCalculationSettings?.sideRoadAADTFactorType)}
                              onValueChange={(e) => onAADTTableCalculationTagBoxValueChanged("sideRoadAADTFactorType", e)}
                            />
                          </SimpleItem>
                          <SimpleItem colSpan={1}>
                            <SelectBox
                              placeholder=""
                              label={t('misCalculationType')}
                              valueExpr="value"
                              displayExpr="name"
                              labelMode="floating"
                              value={initData?.aadtTableCalculationSettings?.misCalculationType}
                              onValueChange={(e) => onAADTTableCalculationSelectBoxValueChanged("misCalculationType", e)}
                              className="modalInput"
                              items={SortObjectByPropName(lstAADTTableCalculationSettingsType, "name")}
                              showClearButton={true}
                              searchEnabled={true}
                            />
                          </SimpleItem>
                          <SimpleItem colSpan={1}>
                            <TagBox
                              items={SortObjectByPropName(lstAADTFactorType, "name")}
                              searchEnabled={true}
                              label={t('misAADTFactorType')}
                              valueExpr="value"
                              displayExpr="name"
                              value={EnumFlag2Array(AADTFactorType, initData?.aadtTableCalculationSettings?.misAADTFactorType)}
                              onValueChange={(e) => onAADTTableCalculationTagBoxValueChanged("misAADTFactorType", e)}
                            />
                          </SimpleItem>
                        </Form>
                    </CollapsibleCard>
                </div>
                <div className="rightColumn">
                    <Button
                        className="tes-modal-btn-add saveButton"
                        onClick={onSave}
                        text={t("save")}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};
export default TrafficStudy;
