import React, { useEffect, useRef, useState } from 'react';
import Tabs from 'devextreme-react/tabs';
import tabTitles from './data/index';
import Users from './components/users/users';
import Permissions from './components/permissions/permissions';
import { TesGet } from '../../../utils/rest';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import notify from 'devextreme/ui/notify';
import { Button } from 'devextreme-react';
import { t } from 'i18next';
import { custom } from 'devextreme/ui/dialog';
import './roledetails.scss'
import { IRoleParams } from '../../../types/identity/identityTypes';
import { VMGroupProfile } from '../../../types/identity/dto/identityDTO';
import { useAuth } from '../../../contexts/auth';
import { ApprovalLevel, ApprovalLevelXUser } from '../../../types/collision/collisionTypes';
import FieldPermissions from './components/fieldPermissions/fieldPermissions';
import { VMAllFieldPermission } from '../../../types/field/dto/fieldDTO';
import templateSettings from '../../../constants/template-settings';
import { AuthApiUrl, CollisionApiUrl, FieldApiUrl } from '../../../environment/routeSettings';
import ShareURL from '../../../components/shareURL/shareURL';
import TesTabs from '../../../components/tesTab/tesTab';

const RoleDetails = () => {
    const history = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [dataChanged] = useState(false);
    const [, setBackPermission] = useState(false);
    const [initData, setInitData] = useState<VMGroupProfile>(new VMGroupProfile());
    const [initDataLevel, setInitDataLevel] = useState<ApprovalLevel[]>([]);
    const [initDataGroupLevel, setInitDataGroupLevel] = useState<Partial<ApprovalLevelXUser>>(new ApprovalLevelXUser());
    const [initDataFieldPermissions, setInitDataFieldPermissions] = useState<VMAllFieldPermission[]>([new VMAllFieldPermission()]);
    const params = useParams<IRoleParams>();
    const { activeLoading } = useAuth();
    const headingParagraphRef = useRef<HTMLParagraphElement>(null);
    const location = useLocation();
    const url = `${window.location.origin}${location.pathname}`;

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getInitialData();
                await getInitialDataLevels();
                await getInitialDataGroupLevels();
                await getInitialDataFieldPermissions();
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getInitialData() {
        setInitData(await TesGet(AuthApiUrl() + "/api/groups/GetGroupProfile/" + params.roleId, true));
    };
    async function getInitialDataLevels() {
        setInitDataLevel(await TesGet(CollisionApiUrl() + "/api/ApprovalLevels/" + localStorage.getItem('selectedCustomerId'), true));
    };
    async function getInitialDataGroupLevels() {
        setInitDataGroupLevel(await TesGet(CollisionApiUrl() + "/api/ApprovalLevels/GetGroupLevel/" + params.roleId + "/" + localStorage.getItem('selectedCustomerId'), true));
    };
    async function getInitialDataFieldPermissions() {
        setInitDataFieldPermissions(await TesGet(FieldApiUrl() + "/api/FieldPermissions/GetGroupFields/" + params.roleId + "/" + localStorage.getItem('selectedCustomerId'), true));
    };

    function handleTabChange(index: number) {
        setSelectedIndex(index);
    }

    //permission functions
    function goBackPermission() {
        if (dataChanged === true) {
            //dialog to show if you want to save the changed the data or discard it.
            let myDialog = custom({
                title: t("warning"),
                messageHtml: t("unsavedDataWarningText"),
                buttons: [{
                    text: t("yes"),
                    onClick: (e) => {
                        try {
                            //add();
                            notify(t("dataSuccessfullyUpdated"), "success", 5000);

                        } catch {
                            notify(t("someErrorOccurred"), "error", 5000);
                        }
                        return { buttonText: e.component.option("text") }
                    }
                },
                {
                    text: t("no"),
                    onClick: (e) => {
                        history(-1);
                        return { buttonText: e.component.option("text") }
                    }
                },
                {
                    text: t("cancel"),
                    onClick: (e) => {
                        setBackPermission(false)
                        return { buttonText: e.component.option("text") }
                    }
                },
                ]
            });
            myDialog.show();

        } else {
            history(-1);
        }
    }

    const copyTextToClipboard = () => {
        if (headingParagraphRef.current) {
            const textToCopy = headingParagraphRef.current.innerText;
            navigator.clipboard.writeText(textToCopy);
            notify(t("textCopiedToClipboard"), "success", 2000);
        }
    };

    return (
        <React.Fragment>
            <div className={'content-block'}>
                <div className={'dx-card'}>
                    {params.intersectionId !== "AddNew" && (
                        <div style={{ marginTop: "1rem" }}>
                            <p ref={headingParagraphRef} className={"detailsHeading"} style={{ display: "inline" }}>
                                <span>{t('name')}: {initData?.name}</span>
                            </p>
                            <Button
                                onClick={() => copyTextToClipboard()}
                                icon="fa-solid fa-copy"
                                hint={t("copy")}
                            />
                            <div className='rightColumn'>
                                {params.roleId !== "AddNew" && (
                                    <ShareURL
                                        url={url}
                                    />
                                )}
                            </div>
                            <hr className="line" style={{ display: "block", marginTop: "1rem" }}></hr>
                        </div>
                    )}
                    <div className="row" style={{ marginTop: 15 }}>
                        <div className='leftColumn'>
                            <Button
                                onClick={() => goBackPermission()}
                                icon="fa-solid fa-arrow-left"
                                hint={t("goBack")}
                            />
                            <label style={{ color: 'black', fontSize: 18 }}>{t('youAreNowWorkingWith')}: <span style={{ color: templateSettings.tesMainColor, fontWeight: 'bold' }}>{initData.name}</span> </label>
                        </div>

                    </div>
                </div>
                <div className={'dx-card '}>
                    <TesTabs
                        width={'100%'}
                        dataSource={tabTitles}
                        selectedIndex={selectedIndex}
                        onTabChange={handleTabChange}
                    />
                    {selectedIndex === 0 &&
                        <Users
                            initData={initData}
                            roleId={params.roleId!}
                            setInitData={setInitData}
                        />
                    }
                    {selectedIndex === 1 &&
                        <Permissions
                            initData={initData}
                            roleId={params.roleId!}
                            setInitData={setInitData}
                            initDataLevel={initDataLevel}
                            initDataGroupLevel={initDataGroupLevel}
                            setInitDataGroupLevel={setInitDataGroupLevel}
                        />
                    }

                    {selectedIndex === 2 &&
                        <FieldPermissions
                            initData={initData}
                            initDataFieldPermissions={initDataFieldPermissions}
                            setInitDataFieldPermissions={setInitDataFieldPermissions}
                        />
                    }

                </div>

                <div>

                </div>
            </div>
        </React.Fragment >
    );
}
export default RoleDetails;
