import React, { useEffect, useState } from 'react';
import { Button, DropDownButton, Tabs, ValidationGroup } from 'devextreme-react';

import { useTranslation } from 'react-i18next';
import { AllWayStopWarrants, FlatRawDataDTO, StudyBaseDTO, StudyVolumeWebDetailsDTO } from '../../../../../types/trafficStudy/dtos/studyDto';
import { RequestResponseResult } from '../../../../../types/general/generalTypes';
import { ResponseCode } from '../../../../../types/general/enums/generalEnums';
import { GeneralSetting } from '../../../../../contexts/clientSetting';
import { RequestErrorHandling, TesPost } from '../../../../../utils/rest';
import { useAuth } from '../../../../../contexts/auth';
import notify from 'devextreme/ui/notify';
import tabTitles from './data/data';
import Details from './components/details';
import Diagram from './components/diagram';
import { TrafficApiUrl } from '../../../../../environment/routeSettings';
import { SignalWarrantAnalysisDetails } from '../../../../../types/systematicScreening/systematicScreeningTypes';
import TesTabs from '../../../../../components/tesTab/tesTab';

// props
interface IPros {
  initData: SignalWarrantAnalysisDetails,
  generalSettings: GeneralSetting | null;
}
const VolumeResults = (props: IPros) => {
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  useEffect(() => {
    async function fetchMyAPI() {
    }
    fetchMyAPI()
  }, []);

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }


  return (
    <div className={`studyDetails-resultSummary ${compactViewModel ? "compactStyle" : ""}`}>
      <React.Fragment>
        {props.initData?.analysisResult?.notPredicted === false ?

          <div className='row' style={{ margin: "1rem" }}>
            <div className={"dx-card"} id="iconsCard" style={{ margin: "0rem 1rem 2rem 1rem" }}>
            </div>
            <div className={"dx-card "} style={{ margin: "1rem" }}>
              <TesTabs
                width={"100%"}
                dataSource={tabTitles}
                selectedIndex={selectedIndex}
                onTabChange={handleTabChange}
              />
              {selectedIndex === 0 && (
                <Diagram
                  generalSettings={props.generalSettings}
                  initDataBase={props.initData}
                />)}


            </div>
          </div>
          :
          <div style={{ margin: "2rem 0rem" }}>
            <span style={{ margin: "0 2rem 0 1rem", fontWeight: "bolder" }}>{t("latestStudyGuide")}</span>
            <Button
              className='tes-modal-btn-add'
              onClick={e => window.open(`/trafficStudy/studies/studyDetails/2/${props.initData.analysisResult.latestStudyId}`, "_blank")?.focus()}
              text={t('openLatestStudy')}
            />
          </div>
        }
      </React.Fragment>
    </div>
  );
}
export default VolumeResults;