import { Form, SelectBox } from "devextreme-react";
import { Button } from "devextreme-react/button";
import {
  DataGridRef,
} from "devextreme-react/data-grid";
import DropDownButton from "devextreme-react/drop-down-button";
import { SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ExportCollision from "../../../components/exportCollision/exportCollision";
import GeneralGisMapManager from "../../../components/gisMap/generalGisMapManager";
import GridFilter from "../../../components/gridFilter/gridFilter";
import GridProfilePicker from "../../../components/gridProfilePicker/gridProfilePicker";
import Permission from "../../../components/permission/permision";
import TesReportViewer from "../../../components/reportViewer/ReportViewer";
import getTitle from "../../../components/title/getTitle";
import ValidationReport from "../../../components/validationReport/validationReport";
import {
  CollisionPermissions,
  TMSPermissions,
} from "../../../constants/Permissions";
import reportSettings from "../../../constants/report-settings";
import reportLocations from "../../../constants/reportLocations";
import { useAuth } from "../../../contexts/auth";
import {
  CollisionApiUrl,
  ReportApiUrl,
} from "../../../environment/routeSettings";
import { UpdateGeoCodeStatusDTO } from "../../../types/collision/dto/collisionDtos";
import {
  GeoCodeStatus,
} from "../../../types/collision/enums/collisionEnums";
import {
  GridType,
  ResponseCode,
  TesMapModule,
} from "../../../types/general/enums/generalEnums";
import {
  GridSetting,
  NameValue,
} from "../../../types/general/generalTypes";
import {
  ClientReport,
  ReportLocation,
  UiReportRequest,
} from "../../../types/report/reportTypes";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import MaxDropdownItemWidthCalculator from "../../../utils/dropDownWidthCalculator";
import { Enum2Array } from "../../../utils/enumTools";
import { useScreenSize } from "../../../utils/media-query";
import { RequestErrorHandling, TesPost } from "../../../utils/rest";
import "./collisions.scss";
import {CollisionGrid} from "../../../components/tesGrid/grids/collisionsGrid";

const Collisions = () => {
  const history = useNavigate();
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const { is2xLarge } = useScreenSize();

  const [showMap, setShowMap] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [showGeoCodeStatusUpdater, setShowGeoCodeStatusUpdater] =
    useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

  const [showValidationReport, setShowValidationReport] =
    useState<boolean>(false);
  const [initDataReports, setInitDataReports] = useState<ClientReport[]>([]);
  const [selectedReport, setSelectedReport] = useState<ClientReport>(
    new ClientReport()
  );
  const [lstSelectedIds, setLstSelectedIds] = useState<string[]>([]);
  const [geoCodeStatus, setGeoCodeStatus] = useState<any>();
  const [lstGeoCodeStatus, setLstGeoCodeStatus] = useState<NameValue[]>([]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [reportPopupHeight, setReportPopupHeight] = useState<string>("80%");
  const [gridSetting, setGridSetting] = useState<GridSetting>(
    JSON.parse(localStorage.getItem("GridSetting")!) || new GridSetting()
  );

  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";


  const title = getTitle("/collision/collisions", "");
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    if (is2xLarge) {
      setReportPopupHeight("55%");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialDataReports();
        setLstGeoCodeStatus(Enum2Array(GeoCodeStatus));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigateToCollisionDetails = useCallback(() => {
    history("/collision/collisionDetails/AddNew");
  }, [history]);

  async function getInitialDataReports() {
    try {
      var postObj: UiReportRequest = {
        customerId: localStorage.getItem("selectedCustomerId")!,
        lstLocations: [reportLocations.Collision_Collisions],
      };

      var res: ClientReport[] = await TesPost(
        ReportApiUrl() + "/api/ClientReports/GetPageReports",
        postObj,
        true
      );
      var validationReport = new ClientReport();
      validationReport.id = "validationReport";
      validationReport.name = t("validationReport");
      var reportLoc = new ReportLocation();
      reportLoc.name = reportLocations.Collision_Collisions;
      validationReport.reportLocations = [reportLoc];
      res.push(validationReport);
      setInitDataReports(res);
    } catch (ex) {
      notify(t("errorInFetchReports") + ex, "error", 5000);
    }
  }

  async function onOpenReport(d: any) {
    var gridData = await dataGridRef.current?.instance().getSelectedRowKeys();
    if (gridData?.length === 0) {
      notify(t("pleaseSelectSomeData"), "warning", 5000);
      return;
    }
    if ((gridData?.length ?? 0) > reportSettings.noMaxItems) {
      notify(t("selectCorrectNumberOfGrid"), "warning", 5000);
      return;
    }
    if (d !== null) {
      setSelectedReport(d.itemData);
    }
    if (d.itemData.id === "validationReport") {
      setShowValidationReport(true);
    } else {
      setShowReportModal(true);
      setLstSelectedIds(gridData!.map((x) => x) as string[]);
    }
  }

  function onCloseReportModal() {
    setShowReportModal(false);
    dataGridRef.current?.instance().deselectAll();
  }

  function onChangeGeoCodeStatus(value: any) {
    setGeoCodeStatus(value);
  }

  async function UpdateGeoCodeStatus() {
    try {
      if (activeLoading) activeLoading(true);
      var gridData = await dataGridRef.current?.instance().getSelectedRowKeys();
      if (gridData?.length === 0) {
        notify(t("pleaseSelectSomeData"), "warning", 5000);
        return;
      }
      const postOb: UpdateGeoCodeStatusDTO = {
        collisionIds: gridData as string[],
        geoCodeStatus: geoCodeStatus,
      };
      const res = await TesPost(
        CollisionApiUrl() + "/api/Collisions/UpdateGeoCodeStatus",
        postOb,
        true
      );
      dataGridRef.current?.instance().deselectAll();
      dataGridRef.current?.instance().refresh();
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        onCloseGeoCodeStatus();
      } else {
        await RequestErrorHandling(res);
        onCloseGeoCodeStatus();
      }
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("errorInFetchReports") + ex, "error", 5000);
      onCloseGeoCodeStatus();
    }
  }

  function onCloseGeoCodeStatus() {
    setShowGeoCodeStatusUpdater(false);
    setGeoCodeStatus({});
  }

  async function onOpenShowDelete() {
    var gridData = await dataGridRef.current?.instance().getSelectedRowKeys();
    if (gridData?.length === 0) {
      notify(t("pleaseSelectSomeData"), "warning", 5000);
      return;
    }
    setShowDeletePopup(true);
  }
  async function onCloseShowDelete() {
    dataGridRef.current?.instance().deselectAll();
    setShowDeletePopup(false);
  }

  async function onDeleteCollisions() {
    var gridData = await dataGridRef.current?.instance().getSelectedRowKeys();
    await TesPost(
      CollisionApiUrl() +
        "/api/Collisions/DeleteCollisions/" +
        localStorage.getItem("selectedCustomerId"),
      gridData,
      true
    );
  }

  //#endregion functions
  return (
    <Permission
      allowed={[
        CollisionPermissions.Collision_D,
        CollisionPermissions.Collision_V,
      ]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("collisions")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="leftColumn" style={{ display: "flex" }}>
                  <Button
                    onClick={() => setShowMap(true)}
                    icon="fa-solid fa-map-location-dot"
                    style={{ marginRight: 10 }}
                    hint={t("showMap")}
                  />
                  <GridProfilePicker
                    customerId={localStorage.getItem("selectedCustomerId")!}
                    gridType={GridType.Collision}
                    dataGridRef={dataGridRef}
                    setGridSetting={setGridSetting}
                  />

                  <Button
                    style={{ marginLeft: 10 }}
                    onClick={() => setShowFilter(true)}
                    icon="fa-solid fa-filter"
                    hint={t("filter")}
                  />

                  <DropDownButton
                    style={{ marginLeft: 10 }}
                    icon="fa-solid fa-chart-line"
                    hint={t("report")}
                    items={SortObjectByPropName(
                      initDataReports?.filter((x: ClientReport) =>
                        [reportLocations.Collision_Collisions].some(
                          (a) =>
                            x.reportLocations?.map((x) => x.name).indexOf(a) >=
                            0
                        )
                      ),
                      "name"
                    )}
                    dropDownOptions={{
                      width: MaxDropdownItemWidthCalculator(
                        SortObjectByPropName(
                          initDataReports?.filter((x: ClientReport) =>
                            [reportLocations.Collision_Collisions].some(
                              (a) =>
                                x.reportLocations
                                  ?.map((x) => x.name)
                                  .indexOf(a) >= 0
                            )
                          ),
                          "name"
                        )
                      ),
                    }}
                    displayExpr="name"
                    onItemClick={(d: any) => onOpenReport(d)}
                  />

                  <Permission
                    allowed={[
                      TMSPermissions.TMS_Admin,
                      CollisionPermissions.Collision_GeoCode,
                    ]}
                    hasFeedBackElement={false}
                  >
                    <Button
                      style={{ marginLeft: 10 }}
                      onClick={() => setShowGeoCodeStatusUpdater(true)}
                      icon="fa-solid fa-location"
                      hint={t("geoCodeStatusUpdater")}
                    />
                  </Permission>

                  <Permission
                    allowed={[TMSPermissions.TMS_Admin]}
                    hasFeedBackElement={false}
                  >
                    <Button
                      style={{ marginLeft: 10 }}
                      onClick={() => onOpenShowDelete()}
                      icon="fa-solid fa-trash"
                      hint={t("delete")}
                    />
                  </Permission>

                  {/* <Permission
                  allowed={[CollisionPermissions.Collision_E, CollisionPermissions.Collision_D]}
                  hasFeedBackElement={false}> */}
                  {/*<RecalculateCollision datagridRef={dataGridRef} />*/}
                  {/* </Permission> */}

                  <Permission
                    allowed={[TMSPermissions.TMS_Admin]}
                    hasFeedBackElement={false}
                  >
                    <ExportCollision dataGridRef={dataGridRef} />
                  </Permission>
                </div>
                <div className="rightColumn">
                  <Permission
                    allowed={[CollisionPermissions.Collision_D]}
                    hasFeedBackElement={false}
                  >
                    <Button
                      onClick={navigateToCollisionDetails}
                      icon="fa-solid fa-circle-plus"
                      text={t("collision")}
                    />
                  </Permission>
                </div>
              </div>
              <CollisionGrid ref={dataGridRef}/>
            </div>
          </div>
          {/* validation report */}
          <ValidationReport
            collisionIds={lstSelectedIds}
            showValidationReport={showValidationReport}
            setShowValidationReport={setShowValidationReport}
          />

          {/*Grid Filter*/}
          <GridFilter
            dataGridRef={dataGridRef}
            setShowModal={setShowFilter}
            showModal={showFilter}
            tesModule={TesMapModule.Collision}
          />

          {/* Map Popup */}
          <Popup
            width={"50%"}
            height={"auto"}
            // maxHeight={"90%"}
            visible={showMap}
            resizeEnabled={true}
            showTitle={true}
            title={t("map")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowMap(false)}
          >
            <GeneralGisMapManager
              tesModule={TesMapModule.Collision}
              dataGridRef={dataGridRef}
              showGis={showMap}
              setShowMap={setShowMap}
              locId="LocationId"
            />
          </Popup>

          {/* Report Popup */}
          <Popup
            visible={showReportModal}
            width={"auto"}
            height={reportPopupHeight}
            resizeEnabled={true}
            showTitle={true}
            title={t("reportViewer")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseReportModal()}
          >
            <TesReportViewer
              selectedReport={selectedReport}
              lstSelectedIds={lstSelectedIds}
              isOpen={showReportModal}
            />
          </Popup>

          {/* Geo code status updater */}
          <Popup
            visible={showGeoCodeStatusUpdater}
            width={"30%"}
            height={"60%"}
            resizeEnabled={true}
            showTitle={true}
            title={t("geoCodeStatusUpdater")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseGeoCodeStatus()}
          >
            <Form>
              <SimpleItem>
                <SelectBox
                  placeholder=""
                  label={t("geoCodeStatus")}
                  labelMode="floating"
                  onValueChange={(e) => onChangeGeoCodeStatus(e)}
                  items={lstGeoCodeStatus}
                  displayExpr="name"
                  valueExpr="value"
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
            </Form>
            <div>
              <div className="rightColumn" style={{ marginTop: 30 }}>
                <Button
                  className="tes-modal-btn-add"
                  onClick={() => UpdateGeoCodeStatus()}
                  text={t("update")}
                />
              </div>
            </div>
          </Popup>

          {/* Delete Collisions */}
          <Popup
            visible={showDeletePopup}
            width={"30%"}
            height={"40%"}
            resizeEnabled={true}
            showTitle={true}
            title={t("deleteConfirmation")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowDeletePopup(false)}
          >
            <div style={{ marginTop: "10px" }}>
              <div style={{ marginBottom: "20px" }}>
                <p
                  style={{ margin: "10px 0", fontSize: "16px", color: "#333" }}
                >
                  {t("areYouSureDeleteCollisions")}
                </p>
                <p
                  style={{ margin: "10px 0", fontSize: "14px", color: "#666" }}
                >
                  {t("thisActionCannotBeUndone")}
                </p>
              </div>
              <div className="rightColumn">
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20 }}
                  onClick={() => onCloseShowDelete()}
                  text={t("cancel")}
                />
                <Button
                  className="tes-modal-btn-add"
                  onClick={onDeleteCollisions}
                  text={t("delete")}
                />
              </div>
            </div>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Collisions;
