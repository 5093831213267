import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/auth";
import {
  InfrastructureLinearSystemType
} from "../../types/infrastructure/infrastructureTypes";
import {
  ResponseCode,
  TesMapModule,
} from "../../types/general/enums/generalEnums";
import {
  RequestErrorHandling,
  TesGet,
  TesPost,
  TesPut,
} from "../../utils/rest";
import notify from "devextreme/ui/notify";
import { useTranslation } from "react-i18next";
import {
  VMCoordination,
  VMLocation,
} from "../../types/infrastructure/dto/locationdDto";
import {
  LocationPickerMode,
  LocationType,
} from "../../types/infrastructure/enums/infrastructureEnums";
import { Button, NumberBox, ScrollView, TextBox } from "devextreme-react";
import { Tooltip } from "devextreme-react/tooltip";

import { VMMapLocation } from "../../types/collision/dto/collisionDtos";
import { Popup } from "devextreme-react/popup";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Pager,
  Paging,
  Selection,
  Lookup,
  FilterPanel,
  FilterBuilderPopup,
  Export,
  Search,
  DataGridRef,
} from "devextreme-react/data-grid";
import {
  FieldApiUrl,
  InfrastructureApiUrl,
} from "../../environment/routeSettings";
import { useNavigate } from "react-router-dom";
import {
  INameId,
  LazyLoadingRequest,
  NameValue,
  RequestResponseResult,
} from "../../types/general/generalTypes";
import CustomStore from "devextreme/data/custom_store";
import design from "./infrastructureLocationPicker.module.scss";
import { Enum2Array, EnumFlag2Array } from "../../utils/enumTools";
import { useLocation } from "react-router-dom";
import { useScreenSize } from "../../utils/media-query";
import { TableFieldRequestDTO } from "../../types/field/dto/fieldDTO";
import {
  FieldCategoryType,
  FieldLocation2Show,
  FieldType,
} from "../../types/field/enums/fieldEnums";
import { TesField } from "../../types/field/fieldType";
import { SortObjectByPropName } from "../../utils/arrayTools";
import { OnExporting } from "../../utils/dataGridTools";
import { GridActualDateCalculator } from "../../utils/dateTimeTools";
import { useClientSetting } from "../../contexts/clientSetting";
import { GeneralGisMap_v3 } from "../gisMap/generalGisMap_v3";
import { useInfrastructure } from "../../contexts/infrastructure";

interface IPros {
  handleLocationChange?: (loc: VMMapLocation) => void;
  setInfrastructureData?: (inData: VMLocation) => void;
  onAssignLocations?: (locations: VMLocation[]) => void;
  tesModule: TesMapModule;
  tempMapLocation?: VMMapLocation;
  selectedMapLocation?: VMMapLocation;
  didMount: boolean;
  initLocationData?: VMLocation;
  renderMap: boolean;
  renderCoordinate: boolean;
  componentMode?: number;
  buttonIcon?: string;
  isMultipleSelection?: boolean;
  disableLocationSelection?: boolean;
  isLocked?: boolean;
  locationType?: LocationType;
  showIcons: boolean;
  DataChanger?: any;
}

const InfrastructureLocationPicker = (props: IPros) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);

  const [selectedInfrastructureData, setSelectedInfrastructureData] =
    useState<VMLocation>();
  const [locationDescription, setLocationDescription] = useState<string>("");
  const [selectedLocations, setSelectedLocations] = useState<VMLocation[]>([]);
  const [lstCoordinations, setLstCoordinations] = useState<VMCoordination[]>(
    []
  );
  const [currentLocation, setCurrentLocation] = useState<VMLocation>();
  const [lstLocationType, setLstLocationType] = useState<NameValue[]>([]);
  const [gridHeight, setGridHeight] = useState<number>(450);
  const [popupHeight, setPopupHeight] = useState<string>("85%");
  const { isXLarge, is2xLarge } = useScreenSize();
  const [initDataFields, setInitDataFields] = useState<TesField[]>([]);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const googlMapIcon = require("../../assets/img/icons/google-maps.png");
  const googlStreetIcon = require("../../assets/img/icons/street-view.png");
  const bingMapIcon = require("../../assets/img/icons/bing-map.png");
  const bingStreetIcon = require("../../assets/img/icons/bing-street.png");
  const currentUrl = useLocation().pathname;
  const { generalSetting } = useClientSetting();
  const { infrastructureData } = useInfrastructure();
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const user = useAuth();
  const [lstVolumeInheritType, setLstVolumeInheritType] = useState<NameValue[]>(
    []
  );
  const [initDataSiteSubTypes, setInitDataSiteSubTypes] = useState<INameId[]>(
    []
  );

  const dataSource = new CustomStore({
    load: async (loadOption) => {
      if (showModal) {
        return await TesPost(
          `${InfrastructureApiUrl()}/api/Locations/GetLocations`,
          {
            customerId: localStorage.getItem("selectedCustomerId"),
            divisionId: localStorage.getItem("defaultDivisionId"),
            loadOptions: loadOption,
            filter: { type: props.locationType },
          } as LazyLoadingRequest,
          true
        );
      }
    },
  });

  useEffect(() => {
    if (isXLarge) {
      setPopupHeight("auto");
      setGridHeight(530);
    } else if (is2xLarge) {
      setPopupHeight("auto");
      setGridHeight(900);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      props.initLocationData?.id !== null ||
      props.initLocationData?.id !== "00000000-0000-0000-0000-000000000000" ||
      props.initLocationData?.id !== undefined
    ) {
      setLocationDescription("");
      setCurrentLocation({});
      if (props.setInfrastructureData) props.setInfrastructureData!({});
      setSelectedInfrastructureData({});
    }
  }, [props.DataChanger]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (props.renderMap) {
          // await getInitDataGisMap();
          await getInitDataSiteSubTypes();
        }

        setLstLocationType(Enum2Array(LocationType));
        setLstVolumeInheritType(Enum2Array(lstVolumeInheritType));

        if (props.locationType === LocationType.Intersection) {
          await getInitialDataIntersectionFields();
        } else if (props.locationType === LocationType.Midblock) {
          await getInitialDataRoadSegmentFields();
        }
      } catch (ex) {
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.tempMapLocation &&
      props.tempMapLocation.latitude &&
      props.tempMapLocation.latitude !== 0
    ) {
      var newCoord: VMCoordination = {
        id: "",
        latitude: props.tempMapLocation.latitude,
        longitude: props.tempMapLocation.longitude,
      };
      setLstCoordinations([newCoord]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tempMapLocation]);

  useEffect(() => {
    // console.log("props.initLocationData" + props.initLocationData?.id)
    if (
      props.initLocationData?.id !== null &&
      props.initLocationData?.id !== "00000000-0000-0000-0000-000000000000" &&
      props.initLocationData?.id !== undefined
    ) {
      getLocationDataById(props.initLocationData?.id);
    } else {
      if (
        props.initLocationData?.geoId == null ||
        props.initLocationData?.geoId === "" ||
        props.initLocationData?.geoId === undefined
      ) {
        setLocationDescription("");
      } else {
        setLocationDescription(
          "(" +
            (props.initLocationData?.locationType === LocationType.Intersection
              ? "Intersection - "
              : "Road Segment - ") +
            props.initLocationData?.geoId +
            ") " +
            props.initLocationData?.description
        );
      }
      //console.log("Res 22222 " + JSON.stringify(props.initLocationData))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initLocationData?.id]);

  async function getInitDataSiteSubTypes() {
    var res = (await TesGet(
      InfrastructureApiUrl() +
        "/api/SiteTypes/GetAllSiteSubTypesName/" +
        localStorage.getItem("selectedCustomerId"),
      true
    )) as RequestResponseResult<INameId[]>;
    if (res.responseCode === ResponseCode.OK) {
      setInitDataSiteSubTypes(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  async function getInitialDataIntersectionFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem("selectedCustomerId")!,
      categoryTypes: [
        FieldCategoryType.InfrastructureIntersectionGeneral,
        FieldCategoryType.InfrastructureIntersectionDetails,
        FieldCategoryType.InfrastructureIntersectionApproachDetails,
        FieldCategoryType.InfrastructureIntersectionOperation,
      ],
    };
    var res = (await TesPost(
      FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory",
      postOb,
      true
    )) as TesField[];
    res = res.sort(function (a, b) {
      return a.gridViewIndex - b.gridViewIndex;
    });
    setInitDataFields(res);
  }
  async function getInitialDataRoadSegmentFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem("selectedCustomerId")!,
      categoryTypes: [
        FieldCategoryType.InfrastructureMidBlockGeneral,
        FieldCategoryType.InfrastructureMidBlockDetails,
        FieldCategoryType.InfrastructureMidBlockCrossSection,
        FieldCategoryType.InfrastructureMidBlockOperation,
        FieldCategoryType.InfrastructureMidBlockRoot,
      ],
    };
    var res = (await TesPost(
      FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory",
      postOb,
      true
    )) as TesField[];
    res = res.sort(function (a, b) {
      return a.gridViewIndex - b.gridViewIndex;
    });
    setInitDataFields(res);
  }

  async function getLocationDataById(locationId?: string) {
    if (locationId === undefined || locationId === "" || locationId === null)
      return;
    var postObj: VMLocation = {
      customerId: props.initLocationData?.customerId,
      //divisionId: localStorage.getItem("selectedDivisionId"),
      id: locationId,
    };
    var res = (await TesPost(
      InfrastructureApiUrl() + "/api/Locations/GetLocationById",
      postObj,
      true
    )) as RequestResponseResult<VMLocation>;
    if (res.responseCode === ResponseCode.OK) {
      if (props.setInfrastructureData) props.setInfrastructureData(res.results);
      setSelectedInfrastructureData(res.results);
      setLocationDescription(
        "(" +
          (res.results.locationType === LocationType.Intersection
            ? "Intersection - "
            : "Road Segment - ") +
          res.results.geoId +
          ") " +
          res.results.description
      );
      setCurrentLocation(res.results);
      setShowModal(false);
    } else {
      setLocationDescription("");
      setCurrentLocation({});
      setSelectedInfrastructureData({});
      if (props.setInfrastructureData) props.setInfrastructureData!({});
      await RequestErrorHandling(res);
      setShowModal(false);
    }
  }

  function onRowClick(selectedRowData: any) {
    getLocationDataById(selectedRowData.data?._id);
  }

  async function selectLocation(
    geoId: string,
    locationType: LocationType,
    coordinate: VMMapLocation
  ) {
    if (geoId == null || geoId == "" || geoId == undefined) return;
    var postObj: VMLocation = {
      customerId: localStorage.getItem("selectedCustomerId")!,
      divisionId: user.user?.singleDivision?.id,
      geoId: geoId,
      locationType: locationType,
    };
    var res = (await TesPost(
      InfrastructureApiUrl() + "/api/Locations/GetLocationById",
      postObj,
      true
    )) as RequestResponseResult<VMLocation>;
    if (res.responseCode === ResponseCode.OK) {
      props.setInfrastructureData!(res.results);
      // props.handleLocationChange!(coordinate);
      setLocationDescription(
        "(" +
          (res.results.locationType === LocationType.Intersection
            ? "Intersection - "
            : "Road Segment - ") +
          res.results.geoId +
          ") " +
          res.results.description
      );
    } else {
      await RequestErrorHandling(res);
    }
  }

  function onAdd() {
    props.onAssignLocations!(selectedLocations);
    onCloseModal();
  }

  function onCloseModal() {
    dataGridRef.current?.instance().deselectAll();
    setShowModal(false);
  }

  async function go2Intersection(geoId: string) {
    var res = await getLocationData(geoId, LocationType.Intersection);
    if (res) {
      window.open(`/infrastructure/intersectionDetails/${res.id}`, "_blank");
    }
  }
  async function go2RoadSegment(geoId: string) {
    var res = await getLocationData(geoId, LocationType.Midblock);
    if (res) {
      window.open(`/infrastructure/roadSegmentDetails/${res.id}`, "_blank");
    }
  }

  async function getLocationData(geoId: string, locationType: LocationType) {
    try {
      if (activeLoading) activeLoading(true);
      var postObj: VMLocation = {
        locationType: locationType,
        geoId: geoId,
        customerId: localStorage.getItem("selectedCustomerId") ?? "",
      };
      var res = (await TesPost(
        InfrastructureApiUrl() + "/api/Locations/GetLocationIdByGeoId",
        postObj,
        true
      )) as RequestResponseResult<VMLocation>;
      if (res.responseCode === ResponseCode.OK) {
        activeLoading?.(false);
        return res.results;
      } else if (res.responseCode === ResponseCode.InfrastructureNotExists) {
        if (activeLoading) activeLoading(false);
        await RequestErrorHandling(res);
      }
      if (activeLoading) activeLoading(false);
      await RequestErrorHandling(res);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  function go2SignSupport(id: string) {
    if (props.tesModule === TesMapModule.Sign) {
      window.open(`/sign/signDetails/${id}`, "_blank");
    }
    if (props.tesModule === TesMapModule.Support) {
      window.open(`/sign/supportDetails/${id}`, "_blank");
    }
  }

  function filterByLocationType() {
    if (props.locationType !== undefined) {
      dataGridRef?.current?.instance().deselectAll();
      dataGridRef?.current?.instance().beginUpdate();
      dataGridRef?.current
        ?.instance()
        .columnOption("LocationType", "selectedFilterOperation", "equal");
      dataGridRef?.current
        ?.instance()
        .columnOption(
          "LocationType",
          "filterValues",
          props.locationType.toString()
        );
      dataGridRef?.current?.instance().endUpdate();
    }
  }

  function onOpenShowModal() {
    if (props.disableLocationSelection || props.isLocked) {
      return;
    }
    setShowModal(true);
    filterByLocationType();
  }

  function onOpenGoogleMap() {
    var lat = !props.selectedMapLocation?.latitude
      ? currentLocation?.coordinate?.latitude
      : props.selectedMapLocation?.latitude;
    var lng = !props.selectedMapLocation?.longitude
      ? currentLocation?.coordinate?.longitude
      : props.selectedMapLocation?.longitude;
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
    );
  }

  function onOpenGoogleStreetView() {
    var lat = !props.selectedMapLocation?.latitude
      ? currentLocation?.coordinate?.latitude
      : props.selectedMapLocation?.latitude;
    var lng = !props.selectedMapLocation?.longitude
      ? currentLocation?.coordinate?.longitude
      : props.selectedMapLocation?.longitude;
    window.open(
      `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}&heading=250&pitch=0&fov=250`
    );
  }
  function onOpenBingMap() {
    var lat = !props.selectedMapLocation?.latitude
      ? currentLocation?.coordinate?.latitude
      : props.selectedMapLocation?.latitude;
    var lng = !props.selectedMapLocation?.longitude
      ? currentLocation?.coordinate?.longitude
      : props.selectedMapLocation?.longitude;
    window.open(
      `https://www.bing.com/maps?v=2&cp=32.040313~34.823399&lvl=15.0&sp=point.${lat}_${lng}_Location`
    );
  }
  function onOpenBingStreetView() {
    var lat = !props.selectedMapLocation?.latitude
      ? currentLocation?.coordinate?.latitude
      : props.selectedMapLocation?.latitude;
    var lng = !props.selectedMapLocation?.longitude
      ? currentLocation?.coordinate?.longitude
      : props.selectedMapLocation?.longitude;
    window.open(
      `https://www.bing.com/mapspreview?cp=${lat}~${lng}&lvl=17&dir=113.8911&pi=-0.284&style=x&v=2&sV=1`
    );
  }
  function onLocationDetails() {
    if (currentLocation?.locationType === LocationType.Intersection) {
      const newTabUrl =
        "/infrastructure/intersectionDetails/" + currentLocation.id;
      window.open(newTabUrl, "_blank"); // Opens the URL in a new tab
    } else if (currentLocation?.locationType === LocationType.Midblock) {
      const newTabUrl =
        "/infrastructure/roadSegmentDetails/" + currentLocation.id;
      window.open(newTabUrl, "_blank"); // Opens the URL in a new tab
    }
  }

  function onCancelLocation() {
    setLocationDescription("");
    setSelectedInfrastructureData({});
    props.setInfrastructureData!({});
  }

  const shouldRenderMapLinksButtons = () => {
    const a =
      (props.selectedMapLocation?.latitude &&
        props.selectedMapLocation?.longitude) ||
      (selectedInfrastructureData?.coordinate?.latitude &&
        selectedInfrastructureData?.coordinate?.longitude);
    return Boolean(a);
  };
  const shouldRenderOpenLocationDetailsButton = () => {
    return (
      selectedInfrastructureData?.id &&
      !currentUrl.includes("intersectionDetails") &&
      !currentUrl.includes("roadSegmentDetails")
    );
  };

  const LHRSLinearSystemColumns = [
    { dataField: "EndLHRS", caption: t("endLHRS") },
    { dataField: "EndOffset", caption: t("endOffset") },
    { dataField: "LHRS", caption: t("lhrs") },
    { dataField: "NextEndOffset", caption: t("nextEndOffset") },
    { dataField: "NextLHRS", caption: t("nextLHRS") },
    { dataField: "NextStartOffset", caption: t("nextStartOffset") },
    { dataField: "PriorEndOffset", caption: t("priorEndOffset") },
    { dataField: "PriorLHRS", caption: t("priorLHRS") },
    { dataField: "PriorStartOffset", caption: t("priorStartOffset") },
    {
      dataField: "StartOffset",
      caption: t("StartOffset"),
      format: { type: "fixedPoint", precision: 2 },
    },
  ];
  const shouldRenderLHRSColumns =
    infrastructureData?.linearSystemType ===
    InfrastructureLinearSystemType.LHRS;

  return (
    <div
      className={`infrastructureLocationPicker ${compactViewModel ? "compactStyle" : ""}`}
    >
      <div
        onClick={() =>
          props.disableLocationSelection === true ? null : onOpenShowModal()
        }
      >
        {props.componentMode === LocationPickerMode.TextBox && (
          <TextBox
            label={t("location")}
            labelMode="floating"
            value={locationDescription}
            disabled={props.disableLocationSelection || props.isLocked}
            showClearButton={true}
            buttons={[
              {
                name: "cancel",
                location: "after",
                options: {
                  icon: "fas fa-times",
                  stylingMode: "text",
                  disabled: props.disableLocationSelection || props.isLocked,
                  onClick: () => {
                    onCancelLocation();
                  },
                },
              },
              {
                name: "search",
                location: "after",
                options: {
                  icon: "fas fa-search",
                  stylingMode: "text",
                  disabled: props.disableLocationSelection || props.isLocked,
                  onClick: () => {
                    onOpenShowModal();
                  },
                },
              },
            ]}
          />
        )}
        {props.componentMode === LocationPickerMode.Button && (
          <Button
            text={t("location")}
            icon="fa-solid fa-link"
            onClick={() => onOpenShowModal()}
          />
        )}
      </div>
      {props.showIcons === true && (
        <div className={`${design.main}`} id="iconsContainer">
          {shouldRenderMapLinksButtons() && (
            <>
              <div className={`${design.iconsContainer}`}>
                <Button
                  icon={googlMapIcon}
                  id="googlMapIcon"
                  onClick={onOpenGoogleMap}
                />
                <Tooltip
                  target="#googlMapIcon"
                  showEvent="mouseenter"
                  hideEvent="mouseleave"
                  hideOnOutsideClick={false}
                >
                  <div>{t("googleMap")}</div>
                </Tooltip>
              </div>
              <div className={`${design.iconsContainer}`}>
                <Button
                  icon={googlStreetIcon}
                  id="googlStreetIcon"
                  onClick={onOpenGoogleStreetView}
                />
                <Tooltip
                  target="#googlStreetIcon"
                  showEvent="mouseenter"
                  hideEvent="mouseleave"
                  hideOnOutsideClick={false}
                >
                  <div>{t("googleStreet")}</div>
                </Tooltip>
              </div>
              <div className={`${design.iconsContainer}`}>
                <Button
                  icon={bingMapIcon}
                  id="bingMapIcon"
                  onClick={onOpenBingMap}
                />
                <Tooltip
                  target="#bingMapIcon"
                  showEvent="mouseenter"
                  hideEvent="mouseleave"
                  hideOnOutsideClick={false}
                >
                  <div>{t("bingMap")}</div>
                </Tooltip>
              </div>
              <div className={`${design.iconsContainer}`}>
                <Button
                  icon={bingStreetIcon}
                  id="bingStreetIcon"
                  onClick={onOpenBingStreetView}
                />
                <Tooltip
                  target="#bingStreetIcon"
                  showEvent="mouseenter"
                  hideEvent="mouseleave"
                  hideOnOutsideClick={false}
                >
                  <div>{t("bingStreet")}</div>
                </Tooltip>
              </div>
            </>
          )}
          {shouldRenderOpenLocationDetailsButton() && (
            <div className={`${design.iconsContainer}`}>
              <Button
                icon="fa-solid fa-location-dot"
                id="locationDetails"
                onClick={onLocationDetails}
              />
              <Tooltip
                target="#locationDetails"
                showEvent="mouseenter"
                hideEvent="mouseleave"
                hideOnOutsideClick={false}
              >
                <div>{t("openLocationDetails")}</div>
              </Tooltip>
            </div>
          )}
        </div>
      )}

      {(currentUrl.includes("collisionDetails") ||
        currentUrl.includes("studyDetails")) && (
        <div className={`${design.row}`}>
          <p>{t("locationPickerText")}</p>
        </div>
      )}
      {props.renderMap && (
        <div style={{ width: "100%", height: "auto" }}>
          {infrastructureData && (
            // {initDataGis !== null && (
            // <GeneralGisMap
            //     tesModule={props.tesModule}
            //     initDataGis={initDataGis}
            //     addCollisionFromMap={null}
            //     // lstFindIntersectionGeoId={( selectedLocationData.locationType === LocationType.Intersection) ? [selectedLocationData.geoId] : []}
            //     // lstFindMidblockGeoId={(selectedLocationData.locationType === LocationType.Midblock) ? [selectedLocationData.geoId] : []}
            //     lstFindIntersectionGeoId={lstIntersectionGeoId}
            //     lstFindMidblockGeoId={lstRoadSegmentGeoId}
            //     lstLatLng={[]}
            //     go2Collision={null}
            //     filterByGeoId={null}
            //     go2Intersection={go2Intersection}
            //     go2RoadSegment={go2RoadSegment}
            //     go2SignSupport={null}
            //     filterByLatLong={null}
            //     mapLocation={props.tempMapLocation}
            //     handleLocationChangeDirectly={props.handleLocationChange}
            //     selectLocation={selectLocation}
            //     customerId={props.initLocationData?.customerId}
            //     showGis={true}
            //     findIntersectionGeoId={selectedIntersectionGeoId}
            //     findMidblockGeoId={selectedRoadSegmentGeoId}
            // />

            // <GeneralGisMapNew
            //     tesModule={props.tesModule}
            //     initDataGis={initDataGis}
            //     lstIntersectionGeoIds={lstIntersectionGeoId}
            //     lstRoadSegmentGeoIds={lstRoadSegmentGeoId}
            //     lstLatLng={lstCoordinations}
            //     lstRanking={[]}
            //     //addCollisionFromMap={addCollisionFromMap}
            //     //lstFindIntersectionGeoId={lstIntersectionGeoId}
            //     //lstFindMidblockGeoId={lstRoadSegmentGeoId}
            //     go2Collision={null}
            //     filterByGeoId={null}
            //     go2Intersection={go2Intersection}
            //     go2RoadSegment={go2RoadSegment}
            //     go2SignSupport={go2SignSupport}
            //     filterByLatLong={null}
            //     // mapLocation={null}
            //     handleLocationChangeDirectly={props.handleLocationChange}
            //     selectLocation={selectLocation}
            //     addLocationFromMap={null}
            //     isLocked={props.isLocked}
            // //  showGis={props.showGis}
            // //  findIntersectionGeoId=''
            // // findMidblockGeoId=''
            // />

            // <GeneralGisMap_v1
            //     tesModule={props.tesModule}
            //     initDataGis={initDataGis}
            //     specifiedInfrastructureData={selectedInfrastructureData}
            //     lstLatLng={lstCoordinations}
            //     go2Collision={null}
            //     filterByGeoId={null}
            //     go2Intersection={go2Intersection}
            //     go2RoadSegment={go2RoadSegment}
            //     go2SignSupport={go2SignSupport}
            //     filterByLatLong={null}
            //     handleLocationChangeDirectly={props.handleLocationChange}
            //     selectLocation={selectLocation}
            //     addLocationFromMap={null}
            //     geoCodingBehavior={props.tesModule === TesMapModule.CollisionGeoCode}
            // />
            <GeneralGisMap_v3
              tesModule={props.tesModule}
              initDataGis={infrastructureData}
              specifiedInfrastructureData={selectedInfrastructureData}
              lstLatLng={lstCoordinations}
              go2Collision={undefined}
              filterByGeoId={undefined}
              go2Intersection={go2Intersection}
              go2RoadSegment={go2RoadSegment}
              go2SignSupport={go2SignSupport}
              filterByLatLong={undefined}
              handleLocationChangeDirectly={props.handleLocationChange}
              selectLocation={selectLocation}
              addLocationFromMap={undefined}
              geoCodingBehavior={
                props.tesModule === TesMapModule.CollisionGeoCode ||
                props.tesModule === TesMapModule.StudyDetails ||
                props.tesModule === TesMapModule.CollisionDetails ||
                props.tesModule === TesMapModule.InfrastructureDetails
              }
            />
          )}
        </div>
      )}
      {props.renderCoordinate && (
        <div className="halfColumn" style={{ marginTop: 20 }}>
          <NumberBox
            id="lati"
            style={{ marginBottom: 25 }}
            step={0}
            label={t("latitude")}
            labelMode="floating"
            value={props.selectedMapLocation?.latitude}
            disabled={props.isLocked}
            // onValueChange={e => onChangeLocationField("latitude", e)}
          />
          <NumberBox
            id="xCord"
            step={0}
            label={t("longitude")}
            labelMode="floating"
            value={props.selectedMapLocation?.longitude}
            disabled={props.isLocked}
            //onValueChange={e => onChangeLocationField("longitude", e)}
          />
          <NumberBox
            id="long"
            style={{ marginBottom: 25 }}
            step={0}
            label={t("xCoord")}
            labelMode="floating"
            value={props.selectedMapLocation?.xCoord}
            disabled={props.isLocked}
            //  onValueChange={e => onChangeLocationField("xCoord", e)}
          />
          <NumberBox
            id="yCord"
            step={0}
            label={t("yCoord")}
            labelMode="floating"
            value={props.selectedMapLocation?.yCoord}
            disabled={props.isLocked}
            // onValueChange={e => onChangeLocationField("yCoord", e)}
          />
        </div>
      )}
      {props.componentMode !== LocationPickerMode.None && (
        <Popup
          width={"60%"}
          height={popupHeight}
          visible={showModal}
          resizeEnabled={true}
          showTitle={true}
          title={t("location")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModal()}
        >
          <ScrollView width="100%" height="100%">
            {((props.locationType == undefined && props.locationType == null) ||
              initDataFields) && (
              <DataGrid
                ref={dataGridRef}
                key="_id"
                dataSource={dataSource}
                rowAlternationEnabled={true}
                showBorders={true}
                onRowClick={onRowClick}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                height={gridHeight}
                onExporting={OnExporting}
                //onContentReady={e => { e.component.deselectAll() }}
                //onFilterValueChange={e => { dataGridRef?.current?.instance().deselectAll() }}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                {props.isMultipleSelection && (
                  <Selection
                    mode="multiple"
                    selectAllMode={"allPages"}
                    showCheckBoxesMode={"always"}
                    deferred={true}
                    // @ts-ignore
                    maxFilterLengthInRequest={10000}
                  />
                )}

                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />

                <FilterRow visible={true} applyFilter="auto" />

                <HeaderFilter visible={true} />

                {/* <SearchPanel visible={true}
                                    width={285}
                                    placeholder={t("search...")} /> */}

                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>

                <Column
                  alignment="left"
                  dataField="GeoId"
                  allowSorting={true}
                  caption={t("geoId")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="Description"
                  allowSorting={true}
                  caption={t("description")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="Inactive"
                  allowSorting={true}
                  caption={t("inactive")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="Latitude"
                  allowSorting={true}
                  allowHeaderFiltering={false}
                  caption={t("latitude")}
                  format={{ type: "fixedPoint", precision: 4 }}
                  visible={false}
                >
                  <Search enabled />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="Longitude"
                  allowSorting={true}
                  allowHeaderFiltering={false}
                  caption={t("longitude")}
                  format={{ type: "fixedPoint", precision: 4 }}
                  visible={false}
                >
                  <Search enabled />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="SiteSubTypeId"
                  allowSorting={true}
                  visible={false}
                  caption={t("siteSubType")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(
                      initDataSiteSubTypes,
                      "name"
                    )}
                    valueExpr="id"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="IsReviewNeeded"
                  allowSorting={true}
                  allowHeaderFiltering={false}
                  caption={t("isReviewNeeded")}
                  visible={false}
                >
                  <Search enabled />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="OverwriteAutoGenerateDescription"
                  allowSorting={true}
                  allowHeaderFiltering={false}
                  caption={t("overwriteAutoGenerateDescription")}
                  visible={false}
                >
                  <Search enabled />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="ReviewNote"
                  allowSorting={true}
                  allowHeaderFiltering={false}
                  caption={t("reviewNote")}
                  visible={false}
                >
                  <Search enabled />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="TesId"
                  allowSorting={true}
                  allowHeaderFiltering={false}
                  caption={t("TesId")}
                  visible={false}
                >
                  <Search enabled />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="LocationType"
                  allowHeaderFiltering={true}
                  caption={t("locationType")}
                >
                  <Search enabled />
                  <Lookup
                    dataSource={SortObjectByPropName(lstLocationType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="VolumeInheritType"
                  allowHeaderFiltering={true}
                  caption={t("volumeInheritType")}
                  visible={false}
                >
                  <Search enabled />
                  <Lookup
                    dataSource={SortObjectByPropName(
                      lstVolumeInheritType,
                      "name"
                    )}
                    valueExpr="value"
                    displayExpr="name"
                  />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                {shouldRenderLHRSColumns &&
                  LHRSLinearSystemColumns.map((col) => (
                    <Column
                      key={col.dataField}
                      dataField={col.dataField}
                      allowSorting={true}
                      allowHeaderFiltering={false}
                      caption={col.caption}
                      format={col.format}
                      visible={false}
                    >
                      <Search enabled />
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  ))}
                {initDataFields.map((d) => {
                  if (d.fieldType === FieldType.List) {
                    return (
                      <Column
                        dataField={d.name}
                        caption={d.labelText}
                        allowFiltering={true}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <Search enabled />
                        <Lookup
                          dataSource={SortObjectByPropName(
                            d.fieldValues,
                            "name"
                          )}
                          valueExpr="id"
                          displayExpr="name"
                        />
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Number) {
                    return (
                      <Column
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="number"
                        alignment="left"
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Date) {
                    return (
                      <Column
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="date"
                        calculateCellValue={(e: any) =>
                          GridActualDateCalculator(e, d, generalSetting)
                        }
                        format={generalSetting?.dateFormat}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Time) {
                    return (
                      <Column
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="datetime"
                        calculateCellValue={(e: any) =>
                          GridActualDateCalculator(e, d, generalSetting)
                        }
                        format={generalSetting?.timeFormat}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.DateTime) {
                    return (
                      <Column
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="datetime"
                        calculateCellValue={(e: any) =>
                          GridActualDateCalculator(e, d, generalSetting)
                        }
                        format={generalSetting?.dateTimeFormat}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Boolean) {
                    return (
                      <Column
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="boolean"
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else {
                    return (
                      <Column
                        dataField={d.name}
                        caption={d.labelText}
                        allowHeaderFiltering={false}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <Search enabled />
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  }
                })}
              </DataGrid>
            )}
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-add"
                onClick={onAdd}
                text={t("assign")}
              />
            </div>
          </ScrollView>
        </Popup>
      )}
    </div>
  );
};

export default InfrastructureLocationPicker;
