import { TDataTableColumn } from "../types";
import { VMField } from "../../../types/field/dto/fieldDTO";
import {
  FieldCategoryType,
  FieldLocation2Show,
  FieldType,
} from "../../../types/field/enums/fieldEnums";
import { useTranslations } from "../../../contexts/translations";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { GridActualDateCalculator } from "../../../utils/dateTimeTools";
import { useClientSetting } from "../../../contexts/clientSetting";
import { EnumFlag2Array } from "../../../utils/enumTools";

export function useColumnDef(data: {
  staticColumns?: Array<TDataTableColumn>;
  fieldsData?: VMField[];
}): { columns: TDataTableColumn[] } {
  const { staticColumns, fieldsData } = data;

  const { t } = useTranslation();
  const { selectedLanguage, availableTranslations } = useTranslations();
  const { generalSetting } = useClientSetting();

  const columns: TDataTableColumn[] = useMemo(() => {
    //todo should override undefined values for default behavior

    const _columns: TDataTableColumn[] = [];
    if (staticColumns && staticColumns.length > 0) {
      for (const item of staticColumns) {
        _columns.push({
          ...item,
          caption: t(`${item.caption}`),
          visible: item.visible === undefined ? true : item.visible,
          ...(item.dataType === "datetime" && {
            format: generalSetting?.dateTimeFormat,
          }),
          ...(item.dataType === "date" && {
            format: generalSetting?.dateFormat,
          }),
          ...(item.dataType === "time" && {
            format: generalSetting?.timeFormat,
          }),
        });
      }
    }
    if (fieldsData && fieldsData?.length > 0) {
      for (const item of fieldsData) {
        const _visible = EnumFlag2Array(
          FieldLocation2Show,
          item.fieldLocation2Show
        ).some((x) => x === FieldLocation2Show.GridView);
        if (item.fieldType === FieldType.List) {
          _columns.push({
            key: item.name,
            dataField: item.name,
            caption: t(`${item.labelText}`),
            allowFiltering: true,
            //todo calculate visibility in which layer?!
            // visible: true,
            visible: _visible,
            lookup: {
              dataSource: SortObjectByPropName(item.fieldValues, "name"),
              valueExpr: "id",
              displayExpr: "name",
            },
            headerFilter: { allowSearch: true },
          });
        } else if (item.fieldType === FieldType.String) {
          _columns.push({
            key: item.name,
            dataField: item.name,
            caption: t(`${item.labelText}`),
            dataType: "string",
            alignment: "left",
            //todo calculate visibility in which layer?!
            // visible: true,
            visible: _visible,
            headerFilter: { allowSearch: true },
          });
        } else if (item.fieldType === FieldType.Number) {
          _columns.push({
            key: item.name,
            dataField: item.name,
            caption: t(`${item.labelText}`),
            dataType: "number",
            alignment: "left",
            //todo calculate visibility in which layer?!
            // visible: true,
            visible: _visible,
            headerFilter: { allowSearch: true },
          });
        } else if (item.fieldType === FieldType.Date) {
          _columns.push({
            key: item.name,
            dataField: item.name,
            caption: t(`${item.labelText}`),
            dataType: "date",
            calculateCellValue: (row) =>
              GridActualDateCalculator(row, item, generalSetting),
            format: generalSetting?.dateFormat,
            //todo calculate visibility in which layer?!
            // visible: true,
            visible: _visible,
            headerFilter: { allowSearch: true },
          });
        } else if (item.fieldType === FieldType.Time) {
          _columns.push({
            key: item.name,
            dataField: item.name,
            caption: t(`${item.labelText}`),
            dataType: "datetime",
            allowHeaderFiltering: false,
            allowGrouping: false,
            calculateCellValue: (row) =>
              GridActualDateCalculator(row, item, generalSetting),
            format: generalSetting?.timeFormat,
            //todo calculate visibility in which layer?!
            // visible: true,
            visible: _visible,
            headerFilter: { allowSearch: true },
          });
        } else if (item.fieldType === FieldType.DateTime) {
          _columns.push({
            key: item.name,
            dataField: item.name,
            caption: t(`${item.labelText}`),
            dataType: "datetime",
            calculateCellValue: (row) =>
              GridActualDateCalculator(row, item, generalSetting),
            format: generalSetting?.dateTimeFormat,
            //todo calculate visibility in which layer?!
            // visible: true,
            visible: _visible,
            headerFilter: { allowSearch: true },
          });
        } else if (item.fieldType === FieldType.Boolean) {
          _columns.push({
            key: item.name,
            dataField: item.name,
            caption: t(`${item.labelText}`),
            dataType: "boolean",
            //todo calculate visibility in which layer?!
            // visible: true,
            visible: _visible,
            headerFilter: { allowSearch: true },
          });
        } else {
          _columns.push({
            key: item.name,
            dataField: item.name,
            caption: t(`${item.labelText}`),
            allowHeaderFiltering: false,
            //todo calculate visibility in which layer?!
            // visible: true,
            visible: _visible,
            headerFilter: { allowSearch: true },
          });
        }
      }
    }
    return _columns;
  }, [staticColumns, fieldsData, selectedLanguage, availableTranslations]);

  return { columns };
}
