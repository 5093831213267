import { LocationType } from "../../../../types/infrastructure/enums/infrastructureEnums";
import { useEffect, useRef, useState } from "react";
import Expand from "@arcgis/core/widgets/Expand";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import { MapSetting } from "../../../../types/infrastructure/infrastructureTypes";
import { LocationTrafficSummaryDTO } from "../../../../types/trafficStudy/dtos/studyDto";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import Polyline from "@arcgis/core/geometry/Polyline";
import Graphic from "@arcgis/core/Graphic";
import Point from "@arcgis/core/geometry/Point";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import LabelClass from "@arcgis/core/layers/support/LabelClass";
import TextSymbol from "@arcgis/core/symbols/TextSymbol";
import {
  afterMapStationaryWithDebounceCallback,
  generateMockDataForEmptySource,
  generateQueryIds,
  getAveragePaths,
  isLayerExist,
  layerSuccessfullyLoadedInDomCallback,
  removeLayer,
} from "../utils";
import { useTranslation } from "react-i18next";
import notify from "devextreme/ui/notify";

type TProps = {
  showLayer: boolean;
  legendExpand: Expand | null;
  map: Map | null;
  view: MapView | null;
  initDataGis: MapSetting | null | undefined;
  lstStudyLocations: LocationTrafficSummaryDTO[] | undefined;
  roadSegmentLayer: FeatureLayer | null;
  reCreateLayerEvenItExists?: boolean;
};
export const useTrafficAverageSpeedLayer = ({
  showLayer,
  legendExpand,
  map,
  view,
  initDataGis,
  lstStudyLocations,
  roadSegmentLayer,
  reCreateLayerEvenItExists = false,
}: TProps) => {
  const { t } = useTranslation();
  const [isLayerLoading, setIsLayerLoading] = useState(false);
  const isCancelled = useRef(false);
  const isUnderConstruction = useRef(false);
  const isTheLastMovement = useRef(true);
  const removeEndStationaryWatch = useRef<() => void | undefined>();
  const removeStartStationaryWatch = useRef<() => void | undefined>();

  const onError = (error: any) => {
    isUnderConstruction.current = false;
  };

  useEffect(() => {
    isCancelled.current = !showLayer;
    if (map) {
      if (!showLayer) {
        if (isLayerExist(map, "AverageSpeedLayer")) {
          removeLayer(map, "AverageSpeedLayer");
        }
      }
    }
  }, [map, showLayer]);
  useEffect(() => {
    if (
      map &&
      view &&
      showLayer &&
      initDataGis &&
      lstStudyLocations &&
      roadSegmentLayer
    ) {
      if (isLayerExist(map, "AverageSpeedLayer")) {
        if (reCreateLayerEvenItExists) {
          removeLayer(map, "AverageSpeedLayer");
        } else {
          return;
        }
      }
      setIsLayerLoading(true);

      if (isCancelled.current) {
        setIsLayerLoading(false);
        isUnderConstruction.current = false;
        return;
      }

      const midblockLocations = lstStudyLocations.filter(
        (loc) =>
          loc.locationType.toString() === LocationType.Midblock.toString() &&
          loc.averageSpeed > 0
      );

      if (midblockLocations.length === 0) {
        notify(
          `${t("noResultFoundForSelectedDate")} in ${t("averageSpeed")} ${t("layer")}`,
          "info",
          5000
        );
      }

      let queryIds = generateQueryIds(
        initDataGis,
        "midblockGeoIdType",
        midblockLocations
      );

      const strRoadSegmentQuery = `${initDataGis.midblockGeoIdName} IN (${queryIds})`;

      const roadSegmentQuery = {
        outFields: ["*"],
        where: strRoadSegmentQuery,
        returnGeometry: true,
      };

      const queryingAndCreateLayer = () => {
        setIsLayerLoading(true);
        isUnderConstruction.current = true;
        view
          ?.whenLayerView(roadSegmentLayer)
          .then((layerView) => {
            reactiveUtils
              .whenOnce(() => !layerView.updating)
              .then(() => {
                if (isCancelled.current || !isTheLastMovement.current) {
                  setIsLayerLoading(false);
                  isUnderConstruction.current = false;
                  return;
                }
                layerView
                  .queryFeatures(roadSegmentQuery)
                  .then((results) => {
                    if (isCancelled.current || !isTheLastMovement.current) {
                      setIsLayerLoading(false);
                      isUnderConstruction.current = false;
                      return;
                    }
                    console.log("!!length", results.features.length);
                    const lstNewRoadSegmentGraphics = results.features
                      .filter(
                        (feature) =>
                          feature.geometry &&
                          feature.geometry.type === "polyline" &&
                          (feature.geometry as Polyline).paths?.length > 0
                      )
                      .map((place) => {
                        const paths = (place.geometry as Polyline).paths[0];
                        const [avgX, avgY] = getAveragePaths(paths);

                        const [lng, lat] = webMercatorUtils.xyToLngLat(
                          avgX,
                          avgY
                        );
                        const [x, y] = webMercatorUtils.lngLatToXY(lng, lat);

                        const matchingLocation = lstStudyLocations.find(
                          (loc) =>
                            loc.geoId.toString() ===
                            place.attributes[
                              initDataGis.midblockGeoIdName
                            ].toString()
                        );

                        return new Graphic({
                          attributes: {
                            averageSpeed: matchingLocation?.averageSpeed,
                          },
                          geometry: new Point({
                            x,
                            y,
                            spatialReference: {
                              wkid: place.geometry?.spatialReference?.wkid,
                            },
                          }),
                        });
                      });

                    const averageSpeedLayer = new FeatureLayer({
                      id: "AverageSpeedLayer",
                      title: "Average Speed",
                      source: generateMockDataForEmptySource(
                        lstNewRoadSegmentGraphics
                      ),
                      objectIdField: "ObjectId",
                      outFields: ["*"],
                      fields: [
                        {
                          alias: "Average Speed",
                          defaultValue: undefined,
                          editable: true,
                          length: -1,
                          name: "averageSpeed",
                          nullable: true,
                          type: "double",
                        },
                      ],
                      featureReduction: {
                        type: "selection", // Adjust this to "selection" or "cluster" as needed
                      },
                      labelingInfo: [
                        new LabelClass({
                          labelExpressionInfo: {
                            expression:
                              "Round($feature.averageSpeed) + ' Km/h';",
                          },
                          labelPlacement: "above-center",
                          symbol: new TextSymbol({
                            font: {
                              size: 12,
                              weight: "bold",
                            },
                            color: "#fdd3d2",
                            haloColor: "#8e0000",
                            haloSize: 1.2,
                          }),
                          deconflictionStrategy: "static",
                        }),
                      ],
                      renderer: new SimpleRenderer({
                        symbol: new SimpleMarkerSymbol({
                          size: 12,
                          color: [0, 0, 0, 0],
                          outline: {
                            width: 0,
                          },
                        }),
                      }),
                      popupTemplate: {
                        title: "Average Speed",
                        content: [
                          {
                            type: "fields",
                            fieldInfos: [
                              {
                                fieldName: initDataGis.midblockGeoIdName,
                                label: "Geo ID",
                              },
                              {
                                fieldName: "averageSpeed",
                                label: "Average Speed",
                              },
                            ],
                          },
                        ],
                      },
                    });

                    averageSpeedLayer
                      .load()
                      .then(() => {
                        if (isCancelled.current || !isTheLastMovement.current) {
                          setIsLayerLoading(false);
                          isUnderConstruction.current = false;
                          return;
                        }
                        removeLayer(map, "AverageSpeedLayer");
                        map.add(averageSpeedLayer);
                        if (legendExpand) legendExpand.expanded = false;
                      })
                      .catch((error) => {
                        onError(error);
                      });

                    layerSuccessfullyLoadedInDomCallback(
                      view,
                      averageSpeedLayer,
                      () => {
                        setIsLayerLoading(false);
                        isUnderConstruction.current = false;
                      }
                    );
                  })
                  .catch((error) => {
                    onError(error);
                  });
              })
              .catch((error) => {
                onError(error);
              });
          })
          .catch((error) => {
            onError(error);
          });
      };
      view.when(() => {
        if (isCancelled.current || isLayerExist(map, "AverageSpeedLayer")) {
          setIsLayerLoading(false);
          return;
        }
        if (isUnderConstruction.current) {
          return;
        }
        queryingAndCreateLayer();
      });
      removeStartStationaryWatch.current?.();
      removeStartStationaryWatch.current =
        afterMapStationaryWithDebounceCallback(
          view,
          () => {
            if (isLayerExist(map, "AverageSpeedLayer")) {
              isTheLastMovement.current = false;
            }
          },
          1
        );
      removeEndStationaryWatch.current?.();
      removeEndStationaryWatch.current = afterMapStationaryWithDebounceCallback(
        view,
        () => {
          isTheLastMovement.current = true;
          if (!isCancelled.current && !isUnderConstruction.current)
            queryingAndCreateLayer();
        },
        700
      );
    }
  }, [map, view, showLayer, initDataGis, lstStudyLocations, roadSegmentLayer]);

  return {
    isTrafficAverageSpeedLayerLoading: isLayerLoading,
  };
};
