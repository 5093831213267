export const attachmentTypeFilterDataSource = [
  {
    text: "none",
    value: ["CollisionAttachmentType", "=", 0],
  },
  {
    text: "xml",
    value: ["CollisionAttachmentType", "=", 1],
  },
  {
    text: "pdf",
    value: ["CollisionAttachmentType", "=", 2],
  },
  {
    text: "jpg",
    value: ["CollisionAttachmentType", "=", 4],
  },
  {
    text: "png",
    value: ["CollisionAttachmentType", "=", 8],
  },
  {
    text: "xml" + "," + "pdf",
    value: ["CollisionAttachmentType", "=", 3],
  },
  {
    text: "xml" + "," + "jpg",
    value: ["CollisionAttachmentType", "=", 5],
  },
  {
    text: "xml" + "," + "png",
    value: ["CollisionAttachmentType", "=", 9],
  },
  {
    text: "pdf" + "," + "jpg",
    value: ["CollisionAttachmentType", "=", 6],
  },
  {
    text: "pdf" + "," + "png",
    value: ["CollisionAttachmentType", "=", 10],
  },
  {
    text: "jpg" + "," + "png",
    value: ["CollisionAttachmentType", "=", 12],
  },
  {
    text: "xml" + "," + "pdf" + "," + "jpg",
    value: ["CollisionAttachmentType", "=", 7],
  },
  {
    text: "xml" + "," + "pdf" + "," + "png",
    value: ["CollisionAttachmentType", "=", 11],
  },
  {
    text: "xml" + "," + "jpg" + "," + "png",
    value: ["CollisionAttachmentType", "=", 13],
  },
  {
    text: "pdf" + "," + "jpg" + "," + "png",
    value: ["CollisionAttachmentType", "=", 14],
  },
  {
    text: "xml" + "," + "pdf" + "," + "jpg" + "," + "png",
    value: ["CollisionAttachmentType", "=", 15],
  },
];
export const attachmentTypeHeaderLookupDataSource = [
  {
    name: "none", // Represents no attachments
    value: 0,
  },
  {
    name: "xml",
    value: 1,
  },
  {
    name: "pdf",
    value: 2,
  },
  {
    name: "jpg",
    value: 4,
  },
  {
    name: "png",
    value: 8,
  },
  {
    name: "xml" + "," + "pdf",
    value: 3,
  },
  {
    name: "xml" + "," + "jpg",
    value: 5,
  },
  {
    name: "xml" + "," + "png",
    value: 9,
  },
  {
    name: "pdf" + "," + "jpg",
    value: 6,
  },
  {
    name: "pdf" + "," + "png",
    value: 10,
  },
  {
    name: "jpg" + "," + "png",
    value: 12,
  },
  {
    name: "xml" + "," + "pdf" + "," + "jpg",
    value: 7,
  },
  {
    name: "xml" + "," + "pdf" + "," + "png",
    value: 11,
  },
  {
    name: "xml" + "," + "jpg" + "," + "png",
    value: 13,
  },
  {
    name: "pdf" + "," + "jpg" + "," + "png",
    value: 14,
  },
  {
    name: "xml" + "," + "pdf" + "," + "jpg" + "," + "png",
    value: 15,
  },
];
