import React, { useRef, useState, useEffect, useCallback } from "react";
import "../../../../../components/gisMap/v3/gisMap.scss";
import { ResponseCode } from "../../../../../types/general/enums/generalEnums";
import LoadPanel from "devextreme-react/load-panel";
import { LocationType } from "../../../../../types/infrastructure/enums/infrastructureEnums";
import { RequestErrorHandling, TesPost } from "../../../../../utils/rest";
import { InfrastructureApiUrl } from "../../../../../environment/routeSettings";
import { RequestResponseResult } from "../../../../../types/general/generalTypes";
import { useTranslation } from "react-i18next";
import { VMLocation } from "../../../../../types/infrastructure/dto/locationdDto";
import notify from "devextreme/ui/notify";
import { useNavigate } from "react-router-dom";
import { useInitializeMap } from "../../../../../components/gisMap/v3/hooks/useInitializeMap";
import { useIntersectionLayer } from "../../../../../components/gisMap/v3/hooks/useIntersectionsLayer";
import { useRoadSegmentLayer } from "../../../../../components/gisMap/v3/hooks/useRoadSegmentLayer";
import { useCustomLayers } from "../../../../../components/gisMap/v3/hooks/useCustomLayers";
import {
  adjustViewZooming,
  findIntersections,
  findRoadSegments,
  openInGoogleMap,
  openStreetView,
  zoomToAreaOfGeometries,
} from "../../../../../components/gisMap/v3/utils";
import { registerPopupActionFunctions } from "../../../../../components/gisMap/v3/utils/popupsActions";
import { useInfrastructure } from "../../../../../contexts/infrastructure";
import { Location } from "../../../../../types/infrastructure/infrastructureTypes";
import Graphic from "@arcgis/core/Graphic";
import style from "./style.module.scss";

type TProps = {
  tesModule: any;
  locations: Location[];
};
export function GisMap({ tesModule, locations }: TProps) {
  const { t } = useTranslation();
  const history = useNavigate();
  const { infrastructureData: initDataGis } = useInfrastructure();

  const mapEl = useRef(null);
  const zoomTimerRef = useRef<any | null>(null);
  const mapContainerRef = useRef<any | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [showOverlayText, setShowOverlayText] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);
  const [isFindingLocInProgress, setIsFindingLocInProgress] = useState(false);
  const [foundLocGraphics, setFoundLocGraphics] = useState<Graphic[]>([]);

  let intersectionHighlightedRefs: __esri.Handle[] = [];
  let roadSegmentHighlightedRefs: __esri.Handle[] = [];

  async function getLocationData(geoId: string, locationType: LocationType) {
    try {
      setIsNavigating(true);
      var postObj: VMLocation = {
        locationType: locationType,
        geoId: geoId,
        customerId: localStorage.getItem("selectedCustomerId") ?? "",
      };
      var res = (await TesPost(
        InfrastructureApiUrl() + "/api/Locations/GetLocationIdByGeoId",
        postObj,
        true
      )) as RequestResponseResult<VMLocation>;
      if (res.responseCode === ResponseCode.OK) {
        setIsNavigating(false);
        return res.results;
      } else if (res.responseCode === ResponseCode.InfrastructureNotExists) {
        await RequestErrorHandling(res);
        setIsNavigating(false);
      }
      await RequestErrorHandling(res);
    } catch (ex) {
      setIsNavigating(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  async function go2Intersection(geoId: string) {
    const res = await getLocationData(geoId, LocationType.Intersection);
    if (res) {
      history("/infrastructure/intersectionDetails/" + res.id);
    }
  }
  async function go2RoadSegment(geoId: string) {
    const res = await getLocationData(geoId, LocationType.Midblock);
    if (res) {
      history("/infrastructure/roadSegmentDetails/" + res.id);
    }
  }

  const withoutPermissionZoomTrying = (value: boolean) => {
    if (!value) {
      setShowOverlayText(false);
    } else {
      if (!showOverlayText) {
        setShowOverlayText(true);
        if (zoomTimerRef.current) {
          clearTimeout(zoomTimerRef.current);
        }
        zoomTimerRef.current = setTimeout(() => {
          setShowOverlayText(false);
          zoomTimerRef.current = null;
        }, 4000);
      }
    }
  };

  const {
    view,
    map,
    legend,
    legendExpand,
    isInitialLoading,
    measurementWidget,
  }: {
    view: any;
    map: any;
    legend: any;
    legendExpand: any;
    isInitialLoading: boolean;
    measurementWidget: any;
  } = useInitializeMap({
    mapEl,
    initDataGis,
    isMovingMapEnabled: true,
    hasLegend: true,
    hasLayerListWidget: true,
    hasPrintWidget: true,
    zoomBehavior: {
      isDefault: false,
      zoomOptions: {
        disableMouseWheel: true,
        disableDoubleClickZoom: true,
        disableDoubleClickWithControlPressed: false,
        disableKeyboardZoom: true,
        disablePopupZoom: true,
      },
      withoutPermissionZoomTryingCallback: withoutPermissionZoomTrying,
    },
  });

  const { intersectionLayer, intersectionLayerId, isIntersectionLoading } =
    useIntersectionLayer({
      view,
      map,
      mapEl,
      initDataGis,
      tesModule,
    });
  const { roadSegmentLayer, roadSegmentLayerId, isRoadSegmentLoading } =
    useRoadSegmentLayer({
      view,
      map,
      mapEl,
      initDataGis,
      tesModule,
    });

  const { isCustomLayerLoading } = useCustomLayers({ map, mapEl, initDataGis });

  const setRoadSegmentHighlightedRefs = (refs: __esri.Handle[]) => {
    roadSegmentHighlightedRefs = [...refs];
  };
  function setIntersectionHighlightedRefs(refs: __esri.Handle[]) {
    intersectionHighlightedRefs = [...refs];
  }
  const removeHighlights = () => {
    if (intersectionHighlightedRefs.length > 0) {
      intersectionHighlightedRefs.forEach((e) => e.remove());
    }
    if (roadSegmentHighlightedRefs.length > 0) {
      roadSegmentHighlightedRefs.forEach((e) => e.remove());
    }
  };

  const zoomToArea = (graphics: Graphic[]) => {
    const geometries = graphics.map((item) => item.geometry);
    zoomToAreaOfGeometries(view, geometries);
  };

  const findAndHighlightLocations = useCallback(() => {
    if (roadSegmentLayer && intersectionLayer && initDataGis) {
      removeHighlights();
      let roadSegmentGeoIds: string[] = [];
      let intersectionGeoIds: string[] = [];

      locations.forEach((loc) => {
        if (loc.locationType === LocationType.Intersection) {
          intersectionGeoIds.push(loc.geoId);
        } else if (loc.locationType === LocationType.Midblock) {
          roadSegmentGeoIds.push(loc.geoId);
        }
      });

      if (roadSegmentGeoIds.length > 0) {
        setIsFindingLocInProgress(true);
        findRoadSegments({
          view,
          roadSegmentLayer,
          initDataGis,
          shouldGoToFirstFoundItem: roadSegmentGeoIds.length === 1,
          lstRoadSegmentGeoIds: roadSegmentGeoIds,
          setRoadSegmentHighlightedRefs,
          callback: (results) => {
            setIsFindingLocInProgress(false);
            setFoundLocGraphics((prev) => {
              return [...prev, ...results];
            });
          },
        });
      }
      if (intersectionGeoIds.length > 0) {
        setIsFindingLocInProgress(true);
        findIntersections({
          view,
          intersectionLayer,
          initDataGis,
          shouldGoToFirstFoundItem: intersectionGeoIds.length === 1,
          lstIntersectionGeoIds: intersectionGeoIds,
          setIntersectionHighlightedRefs,
          callback: (results) => {
            setIsFindingLocInProgress(false);
            setFoundLocGraphics((prev) => {
              return [...prev, ...results];
            });
          },
        });
      }
    }
  }, [roadSegmentLayer, intersectionLayer, initDataGis]);

  useEffect(() => {
    if (
      view &&
      initDataGis &&
      roadSegmentLayer &&
      intersectionLayer &&
      !isInitialLoading &&
      !isRoadSegmentLoading &&
      !isIntersectionLoading &&
      !isCustomLayerLoading
    ) {
      adjustViewZooming({
        expand: 1,
        view,
        layers: [intersectionLayer, roadSegmentLayer],
        finishedZoomingCallback: () => {
          setIsLoading(false);
        },
      });
      registerPopupActionFunctions(
        {
          view,
          initDataGis,
          intersectionLayer,
          roadSegmentLayer,
        },
        {
          "street-view": {
            openStreetView: openStreetView,
          },
          "open-google-maps": {
            openInGoogleMap: openInGoogleMap,
          },
          "go-to-intersection": {
            go2Intersection: go2Intersection,
          },
          "go-to-roadSegment": {
            go2RoadSegment: go2RoadSegment,
          },
        }
      );
      findAndHighlightLocations();
    }
    return () => {
      removeHighlights();
    };
  }, [
    view,
    initDataGis,
    roadSegmentLayer,
    intersectionLayer,
    isInitialLoading,
    isRoadSegmentLoading,
    isIntersectionLoading,
    isCustomLayerLoading,
  ]);

  useEffect(() => {
    if (view && !isFindingLocInProgress && foundLocGraphics?.length > 0) {
      zoomToArea(foundLocGraphics);
    }
  }, [foundLocGraphics, isFindingLocInProgress, view]);

  useEffect(() => {
    const mapContainer = mapContainerRef.current;
    if (mapContainer) {
      mapContainer.addEventListener("mousewheel", (event: any) => {
        if (event.ctrlKey) {
          event.preventDefault();
        }
      });
      return () => {
        mapContainer.removeEventListener("mousewheel", (event: any) => {
          if (event.ctrlKey) {
            event.preventDefault();
          }
        });
      };
    }
  }, [mapContainerRef]);

  return (
    <div
      style={{
        position: "relative",
      }}
      ref={mapContainerRef}
    >
      {isLoading && <div className={"mapLoadingContainer"}>{t("loading")}</div>}
      {isFindingLocInProgress && (
        <div className={"mapLoadingRowContainer"}>{t("loading")}</div>
      )}
      {showOverlayText && (
        <div
          className={"text_box_v1"}
          onMouseOver={() => {
            view && view.focus();
          }}
        >
          {t("useCtrl+ScrollToZoomTheMap")}
        </div>
      )}
      <div
        ref={mapEl}
        onMouseOver={() => {
          view && view.focus();
        }}
        className={`custom-Height ${style.mapContainer}`}
      >
        <div
          id="distance"
          className="esri-widget esri-widget--button esri-widget esri-interactive esri-ui-top-right esri-ui-corner ToolsSquareShadow"
          style={{ marginTop: "6.1rem", marginRight: "0.9rem" }}
          title="Distance Measurement Tool"
          onClick={() => {
            measurementWidget.activeTool = "distance";
          }}
        >
          <span className="esri-icon-measure-line"></span>
        </div>

        <div
          id="clear-distance"
          className="esri-widget esri-widget--button esri-widget esri-interactive esri-ui-top-right esri-ui-corner ToolsSquareShadow"
          style={{ marginTop: "8.15rem", marginRight: "0.9rem" }}
          title="Clear Measurement Tool"
          onClick={() => {
            measurementWidget.clear();
          }}
        >
          <span className="esri-icon-trash"></span>
        </div>
        <div id="info" className="esri-widget"></div>
      </div>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={isNavigating}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
    </div>
  );
}
