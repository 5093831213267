import i18n from "../../../../react-i18next";
i18n.loadNamespaces("tabs");

const tabTitles = [
  {
    id: 0,
    text: "formatting",
    icon: "fa-solid fa-file-pen",
    content: "Formatting tab content",
  },
  {
    id: 1,
    text: "urls",
    icon: "fa-solid fa-globe",
    content: "GIS Layers tab content",
  },
  {
    id: 2,
    text: "identity",
    icon: "fa-solid fa-fingerprint",
    content: "Identity tab content",
  },
  {
    id: 3,
    text: "sign",
    icon: "fa-solid fa-triangle-exclamation",
    content: "Sign tab content",
  },
  {
    id: 4,
    text: "infrastructure",
    icon: "fa-solid fa-map-location-dot",
    content: "Infrastructure tab content",
  },
  {
    id: 5,
    text: "collision",
    icon: "fa-solid fa-car-burst",
    content: "Collision tab content",
  },
  {
    id: 6,
    text: "trafficStudy",
    icon: "fa-solid fa-traffic-light",
    content: "Traffic Study tab content",
  },
  {
    id: 7,
    text: "gisLayer",
    icon: "fa-solid fa-layer-group",
    content: "GIS Layer tab content",
  },
  {
    id: 8,
    text: "report",
    icon: "fa-solid fa-chart-line",
    content: "Report tab content",
  },
  {
    id: 9,
    text: "integration",
    icon: "fa-solid fa-chart-line",
    content: "Report tab content",
  },
];
export default tabTitles;
