import { useEffect, useRef, useState } from "react";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";

import { useTranslation } from "react-i18next";
import { isLayerExist, layerSuccessfullyLoadedInDomCallback } from "../utils";
import MapView from "@arcgis/core/views/MapView";
import Map from "@arcgis/core/Map";
import { MapSetting } from "../../../../types/infrastructure/infrastructureTypes";
import { TesMapModule } from "../../../../types/general/enums/generalEnums";
import { LocationType } from "../../../../types/infrastructure/enums/infrastructureEnums";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import { intersectionPopupActionBTNs } from "../utils/popupsActions";
import SizeVariable from "@arcgis/core/renderers/visualVariables/SizeVariable";

type TProps = {
  view: MapView | null;
  map: Map | null;
  mapEl: any;
  initDataGis?: MapSetting | null;
  tesModule: TesMapModule;
};
export function useIntersectionLayer({
  view,
  map,
  mapEl,
  initDataGis,
  tesModule,
}: TProps) {
  const { t } = useTranslation();

  const intersectionLayer = useRef<FeatureLayer | null>(null);
  const [intersectionLayerId, setIntersectionLayerId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getIntersectionFieldInfos = () => {
    const layerAttributes = initDataGis?.gisLayerAttributes;
    let filedInfos = [
      {
        fieldName: initDataGis?.intersectionGeoIdName,
        label: "Geo ID",
      },
    ];
    if (layerAttributes && layerAttributes?.length > 0) {
      layerAttributes.forEach((attribute) => {
        if (attribute.layerType === LocationType.Intersection) {
          filedInfos.push({
            fieldName: attribute.name,
            label: attribute.label,
          });
        }
      });
    }
    return filedInfos;
  };

  const createIntersectionLayer = () => {
    setIsLoading(true);

    const renderer = new SimpleRenderer({
      symbol: new SimpleMarkerSymbol({
        color: "#FFFF00",
        outline: { color: "#202020", width: 1 },
      }),
      visualVariables: [
        new SizeVariable({
          valueExpression: "$view.scale",
          stops: [
            { value: 500000, size: 4 }, // Smaller symbols at larger scales (zoomed out)
            { value: 100000, size: 6 }, // Medium symbols
            { value: 50000, size: 8 }, // Larger symbols at smaller scales (zoomed in)
          ],
        }),
      ],
    });

    const featureLayers: FeatureLayer = new FeatureLayer({
      title: "Intersection",
      id: "intersection-layer",
      outFields: [initDataGis!.intersectionGeoIdName],
      apiKey: initDataGis!.arcGisApiKey,
      url: initDataGis!.intersectionFileAddress,
      copyright: "TNS",
      minScale: 0, // Always visible
      maxScale: 0, // Always visible,
      popupTemplate: {
        actions: intersectionPopupActionBTNs(tesModule),
        content: [
          {
            type: "fields",
            fieldInfos: getIntersectionFieldInfos(),
          },
        ],
      },
      renderer: renderer,
    });

    featureLayers.load().then(() => {
      map!.add(featureLayers);
    });

    intersectionLayer.current = featureLayers;
    // setIntersectionLayerId(featureLayers.uid);
    setIntersectionLayerId(featureLayers.id);

    layerSuccessfullyLoadedInDomCallback(view!, featureLayers, () => {
      map?.reorder(featureLayers, 1);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (map && initDataGis !== null && mapEl && view) {
      if (isLayerExist(map, "intersection-layer")) return;
      createIntersectionLayer();
    }
    return () => {};
  }, [mapEl, initDataGis, map, view]);

  return {
    intersectionLayer: intersectionLayer.current,
    intersectionLayerId: intersectionLayerId,
    isIntersectionLoading: isLoading,
  };
}
