import { LocationType } from "../../../types/infrastructure/enums/infrastructureEnums";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import {TContextMenuAction} from "../types";


export const ContextMenuItemClick = (
  action: TContextMenuAction,
  rowId: any,
  url?: string,
  locationType?: LocationType,
  locationId?: string
) => {
  if (action === "Open In New Tab") {
    window.open(`${url}${rowId}`, "_blank")?.focus();
  } else if (action === "Open Location") {
    if (!locationType) {
      notify(t("locationTypeUndefined"), "error", 3000);
    } else if (!locationId) {
      notify(t("locationIdUndefined"), "error", 3000);
    } else if (locationType === LocationType.Intersection) {
      window
        .open(`/infrastructure/intersectionDetails/${locationId}`, "_blank")
        ?.focus();
    } else if (locationType === LocationType.Midblock) {
      window
        .open(`/infrastructure/roadSegmentDetails/${locationId}`, "_blank")
        ?.focus();
    }
  }
};
