//imports
import { ContextMenu, Form, NumberBox, ScrollView } from "devextreme-react";
import { Button } from "devextreme-react/button";
import { SimpleItem } from "devextreme-react/cjs/form";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  Selection,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GeneralGisMapManager from "../../../components/gisMap/generalGisMapManager";
import GridFilter from "../../../components/gridFilter/gridFilter";
import GridProfilePicker from "../../../components/gridProfilePicker/gridProfilePicker";
import LoadingScreen, { CheckLoadingStates } from "../../../components/loadingScreen/loadingScreen";
import Permission from "../../../components/permission/permision";
import getTitle from "../../../components/title/getTitle";
import { InfrastructurePermissions } from "../../../constants/Permissions";
import { useClientSetting } from "../../../contexts/clientSetting";
import { FieldApiUrl, TrafficApiUrl } from "../../../environment/routeSettings";
import { FieldCategoryType, FieldLocation2Show, FieldType } from "../../../types/field/enums/fieldEnums";
import { TesField } from "../../../types/field/fieldType";
import { GridType, ResponseCode, TesMapModule } from "../../../types/general/enums/generalEnums";
import { GridSetting, LazyLoadingRequest, NameValue, RequestResponseResult } from "../../../types/general/generalTypes";
import { CountStationType } from "../../../types/infrastructure/enums/infrastructureEnums";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { OnExporting } from "../../../utils/dataGridTools";
import { GridActualDateCalculator } from "../../../utils/dateTimeTools";
import { Enum2Array, EnumFlag2Array } from "../../../utils/enumTools";
import { RequestErrorHandling, TesPost } from "../../../utils/rest";
import { RightClickMenu } from "../../../utils/rightClickMenu";

const dataSource = new CustomStore({
  key: "_id",
  load: async (loadOption) => {
    return await TesPost(
      `${TrafficApiUrl()
      }/api/CountStation/GetCountStation`,
      {
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("selectedDivisionId"),
        loadOptions: loadOption,
      } as LazyLoadingRequest,
      true
    );
  },
});

const CountStation = () => {
  const [showMap, setShowMap] = useState(false);
  const [initDataFields, setInitDataFields] = useState<TesField[]>([]);
  const history = useNavigate();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [getFieldsLoading, setGetFieldsLoading] = useState<boolean>(false)
  const [recalculateLoading, setRecalculateLoading] = useState<boolean>(false)
  const { generalSetting } = useClientSetting();
  const { t } = useTranslation();
  const navigateToCountStationDetails = useCallback(() => {
    history("/infrastructure/countStationDetails/AddNew");
  }, [history]);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('other')}
  ];
  const [gridSetting, setGridSetting] = useState<GridSetting>(JSON.parse((localStorage.getItem("GridSetting")!)) || new GridSetting)
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const { customerDivisions } = useClientSetting();
  const [lstCountStation, setLstCountStation] = useState<NameValue[]>([])
  const [lstCountStationTypes, setLstCountStationTypes] = useState<NameValue[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [calcPostObj, setCalcPostObj] = useState<{customerId: string, year: number, pcsIds: string[]}>(
    { customerId: localStorage.getItem('selectedCustomerId')!,
      year: new Date().getFullYear(),
      pcsIds: []
    }
  );

  const title = getTitle('/infrastructure/countStation', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        setGetFieldsLoading(true);
        await getInitialDataFields();
        setLstCountStation(Enum2Array(CountStationType));
        setLstCountStationTypes(Enum2Array(CountStationType))
        setGetFieldsLoading(false);
      } catch (ex) {
        setGetFieldsLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitialDataFields() {
    const res = (await TesPost(FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory", {
      customerId: localStorage.getItem('selectedCustomerId')!,
      categoryTypes: [FieldCategoryType.CountingStationGeneral, FieldCategoryType.PermanentCountingStationGeneral]
    }, true)) as TesField[];

    setInitDataFields(
      res.filter((obj, _, arr) =>
        arr.filter(o => o.name === obj.name).length <= 1 || obj.fieldCategoryType !== FieldCategoryType.PermanentCountingStationGeneral
      ).sort((a, b) => a.detailViewIndex - b.detailViewIndex)
    );
  }

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data._id !== undefined) {

      if (isCtrlKeyPressed) {
        window.open(`/infrastructure/countStationDetails/${e.data._id}`, "_blank");
      } else {
        history(`/infrastructure/countStationDetails/${e.data._id}`);
      }
    }
  }

  async function onCalculatorClick() {
    const selectedRowIds = await dataGridRef.current?.instance().getSelectedRowKeys()
    if (selectedRowIds?.length === 0) {
        notify(t('noSelectedRows'), 'warning', 3000)
        return
    }
    setCalcPostObj({...calcPostObj, pcsIds: selectedRowIds!})
    setShowModal(true)
}

  async function onRecalculate() {
    try {
      setRecalculateLoading(true);
      const res: RequestResponseResult<null> = await TesPost(
          TrafficApiUrl() + "/api/DirSplit/CalculateDirSplit",
          calcPostObj,
          true
      );
      setRecalculateLoading(false);
      if (res.responseCode === ResponseCode.OK) {
          notify(t("dataSuccessfullyRecalculated"), "success", 5000);
          onCloseModal()
          dataGridRef.current?.instance().refresh()
          return;
      } else if (res.responseCode === ResponseCode.WorkOrderExist) {
          notify(t("workOrderExist"), "error", 5000);
      } else {
          await RequestErrorHandling(res);;
      }
    } catch {
        setRecalculateLoading(false);
        notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  function onCloseModal(){
    setCalcPostObj(
      { ...calcPostObj,
        year: new Date().getFullYear(),
        pcsIds: []
      }
    )
    setShowModal(false)
  }

  return (
    <Permission
      allowed={[
        InfrastructurePermissions.Infrastructure_E_CountStation,
        InfrastructurePermissions.Infrastructure_V_CountStation,
      ]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
      {CheckLoadingStates([getFieldsLoading, recalculateLoading]) && <LoadingScreen/>}
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={e => RightClickMenu(e, selectedRowData.row.data._id, "/infrastructure/countStationDetails/")}
          />
          <h2 className={"content-block"}>{t("countStation")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="leftColumn" style={{ display: "flex" }}>

                  <Button onClick={() => setShowMap(true)}
                    icon="fa-solid fa-map-location-dot"
                  />

                  <GridProfilePicker
                    customerId={localStorage.getItem("selectedCustomerId") ?? ""}
                    gridType={GridType.countStation}
                    dataGridRef={dataGridRef}
                    setGridSetting={setGridSetting}
                  />

                  <Button
                    style={{ marginLeft: 10 }}
                    onClick={() => setShowFilter(true)}
                    icon="fa-solid fa-filter"
                    hint={t("filter")}
                  />

                  <Button
                      onClick={() => onCalculatorClick()}
                      icon={'fa-solid fa-calculator'}
                      style={{ marginLeft: 5 }}
                      hint={t('recalculateCountStation')}
                  />

                </div>
                {/* <div className="rightColumn">
                  <Permission
                    allowed={[
                      InfrastructurePermissions.Infrastructure_D_Site,
                      InfrastructurePermissions.Infrastructure_R_Site,
                    ]}
                    hasFeedBackElement={false}
                  >
                    <Button
                      onClick={navigateToCountStationDetails}
                      icon="fa-solid fa-circle-plus"
                      text={t("countStation")}
                    />
                  </Permission>
                </div> */}
              </div>
              <DataGrid
                id="gridContainer"
                key={"_id"}
                ref={dataGridRef}
                dataSource={dataSource}
                rowAlternationEnabled={true}
                showBorders={true}
                onRowClick={onRowClicked}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                onContextMenuPreparing={e => { setSelectedRowData(e) }}
                columnAutoWidth={gridSetting.horizontalScrolling}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.countStation.toString()}
                  savingTimeout={500}
                />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <Selection
                  mode="multiple"
                  selectAllMode={"allPages"}
                  showCheckBoxesMode={"always"}
                  deferred={true}
                  // @ts-ignore
                  maxFilterLengthInRequest={10000}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column
                  alignment="left"
                  dataField="_id"
                  allowSorting={true}
                  caption={t("id")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="DivisionId"
                  allowSorting={true}
                  visible={false}
                  caption={t("division")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(customerDivisions!, "name")}
                    valueExpr="id"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="LocationId"
                  allowSorting={true}
                  caption={t("locationId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="Name"
                  allowSorting={true}
                  caption={t("name")}
                  width={400}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="Description"
                  allowSorting={true}
                  caption={t("description")}
                  width={400}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="CountStationNo"
                  allowSorting={true}
                  caption={t("countStationNo")}
                  width={400}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column alignment="left" dataField="CountStationType" width={200}
                  allowSorting={false}
                  caption={t('countStationType')}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstCountStationTypes, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  alignment="left"
                  dataField="InstallationDate"
                  allowSorting={true}
                  caption={t("installationDate")}
                  dataType="date"
                  format={generalSetting?.dateFormat}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="LHRS"
                  allowSorting={true}
                  caption={t("lhrs")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="Offset"
                  allowSorting={true}
                  caption={t("offset")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="Latitude"
                  allowSorting={true}
                  caption={t("latitude")}
                  format={{ type: 'fixedPoint', precision: 4 }}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="Longitude"
                  allowSorting={true}
                  caption={t("longitude")}
                  format={{ type: 'fixedPoint', precision: 4 }}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="aadtPatternId"
                  allowSorting={true}
                  caption={t("aadtPatternId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="IsDeleted"
                  allowSorting={true}
                  caption={t("isDeleted")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                {initDataFields && initDataFields.length > 0 && initDataFields.map((d) => {
                  if (d.fieldType === FieldType.List) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        allowFiltering={true}
                        visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                      >
                        <Search enabled />
                        <Lookup
                          dataSource={SortObjectByPropName(d.fieldValues, "name")}
                          valueExpr="id"
                          displayExpr="name"
                        />
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Number) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="number"
                        alignment="left"
                        visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Date) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="date"
                        calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                        format={generalSetting?.dateFormat}
                        visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Time) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="datetime"
                        calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                        format={generalSetting?.timeFormat}
                        visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.DateTime) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="datetime"
                        calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                        format={generalSetting?.dateTimeFormat}
                        visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Boolean) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="boolean"
                        visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        allowSorting={true}
                        visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  }
                })}
              </DataGrid>
            </div>
          </div>

          <GridFilter
            dataGridRef={dataGridRef}
            setShowModal={setShowFilter}
            showModal={showFilter}
            tesModule={TesMapModule.Collision}
          />

          {/* Map Popup */}
          <Popup
            width={"50%"}
            height={"auto"}
            visible={showMap}
            resizeEnabled={true}
            showTitle={true}
            title={t("map")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowMap(false)}
          >
            <GeneralGisMapManager
              tesModule={TesMapModule.Infrastructure}
              dataGridRef={dataGridRef}
              showGis={showMap}
              setShowMap={setShowMap}
              locId="_id"
            />
          </Popup>

          <Popup
                visible={showModal}
                width={"40%"}
                height={"auto"}
                resizeEnabled={true}
                showTitle={true}
                title={t("recalculateCountStations")}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => setShowModal(false)}
            >
                <ScrollView width="100%" height="100%">
                    <Form colCount={2}>
                        <SimpleItem colSpan={1}>
                          <NumberBox
                            label={t("year")}
                            labelMode="floating"
                            value={calcPostObj.year}
                            onValueChange={(e) => setCalcPostObj({...calcPostObj, year: e})}
                          />
                        </SimpleItem>
                        <SimpleItem colSpan={1}>
                          <Button
                              onClick={onRecalculate}
                              icon={"fa-solid fa-calculator"}
                              text={t('recalculate')}
                              style={{marginTop: '0.5rem'}}
                          />
                        </SimpleItem>
                    </Form>
                    <div>
                      <div className="rightColumn" style={{ marginTop: 30 }}>
                        <Button
                            className="tes-modal-btn-add"
                            onClick={() => onCloseModal()}
                            text={t("close")}
                        />
                      </div>
                    </div>
                </ScrollView>
            </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default CountStation;
