import i18n from "../../../../../react-i18next";

const tabTitles = [
    {
        id: 0,
        text: 'results',
        icon: 'fa-solid fa-file-lines',
        content: 'Details tab content',
    },
];
export default tabTitles;