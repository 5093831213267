import { t } from "i18next";

const tabTitles = [
    {
        id: 0,
        text: 'users',
        icon: 'fa-solid fa-users',
        content: 'Users',
    },
    {
        id: 1,
        text: 'permissions',
        icon: 'fa-solid fa-user-lock',
        content: 'Permissions',
    },
    {
        id: 2,
        text: 'fieldPermissions',
        icon: 'fa-solid fa-file-circle-minus',
        content: 'Field Permissions',
    },
  
];
export default tabTitles;

