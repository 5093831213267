import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import axios from "axios";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import styles from "./PdfViewer.module.scss";
import { t } from "i18next";
import notify from "devextreme/ui/notify";

// Configure PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs`;

// TypeScript Props Interface
interface IPros {
  url: string;
}

const PdfViewer = (props: IPros) => {
  const [pdfData, setPdfData] = useState<string | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [zoom, setZoom] = useState<number>(1.0);
  const [viewMode, setViewMode] = useState<"page" | "page-width">("page");
  const [pageInput, setPageInput] = useState<string>("");

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await axios.get(props.url, { responseType: "blob" });
        setPdfData(URL.createObjectURL(response.data));
        setLoading(false);
      } catch (error) {
        notify(t("errorInFetchingTheFile"), "error", 2500);
        setLoading(false);
      }
    };

    if (props.url) {
      fetchPdf();
    }
  }, [props.url]);

  const onLoadSuccess = ({ numPages }: { numPages: number }) => {
    setTotalPages(numPages);
  };

  const onLoadError = () => {
    notify(t("errorInLoadingTheFile"), "error", 2500);
  };

  const changePage = (direction: "prev" | "next") => {
    setPageNumber((prev) =>
      direction === "prev" ? Math.max(prev - 1, 1) : Math.min(prev + 1, totalPages)
    );
  };

  const goToFirstPage = () => {
    setPageNumber(1);
  };

  const goToLastPage = () => {
    setPageNumber(totalPages);
  };

  const zoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 2));
  };

  const zoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5));
  };

  const switchViewMode = (mode: "page" | "page-width") => {
    setViewMode(mode);
    setZoom(1); // Reset zoom when switching modes
  };

  const getPageWidthZoom = (): number => {
    const container = document.querySelector(`.${styles.pdfContentWrapper}`) as HTMLElement | null;
    if (!container) return 1.0;
    const containerWidth = container.offsetWidth; // Now TypeScript recognizes offsetWidth
    const pageWidth = 600; // Approximate page width
    return containerWidth / pageWidth;
  };
  
  const printPDF = async () => {
    // Check if the URL is provided
    if (props.url) {
      try {
        // Fetch the PDF file as an array buffer for accurate binary data handling
        const response = await axios.get(props.url, { responseType: "arraybuffer" });
  
        // Convert the array buffer into a Blob and specify the MIME type as PDF
        const blob = new Blob([response.data], { type: "application/pdf" });
  
        // Generate a temporary object URL for the Blob
        const blobUrl = URL.createObjectURL(blob);
  
        // Open the Blob URL in a new browser tab/window
        const printWindow = window.open(blobUrl, "_blank");
  
        // If the window opens successfully, wait for it to load and then trigger the print dialog
        if (printWindow) {
          printWindow.onload = () => {
            printWindow.print(); // Trigger the browser's print functionality
          };
        } else {
          // Notify the user if the print window could not be opened
          notify(t("unblockShowPopupWindowsInYourBrowser"), "error", 2500);
        }
      } catch (error) {
        // Notify the user if there is an error fetching the PDF
        notify(t("errorInFetchingTheFile"), "error", 2500);
      }
    } else {
      // Notify the user if the URL is not provided
      notify(t("errorInFetchingTheFile"), "error", 2500);
    }
  }; 

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const page = parseInt(e.target.value, 10);
    setPageInput(e.target.value);

    if (!isNaN(page) && page >= 1 && page <= totalPages) {
      setPageNumber(page);
    }
  };

  return (
    <div className={styles.pdfViewerWrapper}>
      {/* Controls */}
      <div className={styles.pdfControls}>
        {/* Navigation Controls */}
        <div
          style={{
            width: "33.333%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <button
            className="pdfViewerIcons fa-solid fa-angles-left"
            onClick={goToFirstPage}
            title={t("firstPage")}
          />
          <button
            className="pdfViewerIcons fa-solid fa-chevron-left"
            onClick={() => changePage("prev")}
            title={t("previous")}
          />
          <span>
            {pageNumber} of {totalPages}
          </span>
          <button
            className="pdfViewerIcons fa-solid fa-chevron-right"
            onClick={() => changePage("next")}
            title={t("next")}
          />
          <button
            className="pdfViewerIcons fa-solid fa-angles-right"
            onClick={goToLastPage}
            title={t("lastPage")}
          />
          <input
            type="number"
            value={pageInput}
            onChange={handlePageInputChange}
            placeholder="Go to page"
            min={1}
            max={totalPages}
          />
        </div>

        {/* Zoom and View Mode */}
        <div
          style={{
            width: "33.333%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            className="pdfViewerIcons fa-regular fa-file-lines"
            onClick={() => switchViewMode("page")}
            title={t("pageView")}
          />
          <button
            className="pdfViewerIcons fa-solid fa-arrows-left-right-to-line"
            onClick={() => switchViewMode("page-width")}
            title={t("pageWidthView")}
          />
          <button
            className="pdfViewerIcons fa-solid fa-magnifying-glass-plus"
            onClick={zoomIn}
            title={t("zoomIn")}
          />
          <button
            className="pdfViewerIcons fa-solid fa-magnifying-glass-minus"
            onClick={zoomOut}
            title={t("zoomOut")}
          />
        </div>

        {/* Print and Download */}
        <div
          style={{
            width: "33.333%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <button
            className="pdfViewerIcons fa-solid fa-print"
            onClick={printPDF}
            title={t("print")}
          />
          <button
            className="pdfViewerIcons fa-solid fa-download"
            onClick={e => window.open(props.url)}
            title={t("download")}
          />
          <button
            className="pdfViewerIcons fa-solid fa-copy"
            onClick={() => {
              navigator.clipboard.writeText(props.url)
              notify(t("linkCopiedToClipboard"), "success", 2000);
            }}
            title={t("copyLink")}
          />
        </div>
      </div>

      {/* PDF Viewer */}
      <div
        className={`${styles.pdfContentWrapper} ${
          viewMode === "page-width" ? styles.pageWidthMode : ""
        }`}
      >
        {loading ? (
          <p>{t("loading")}</p>
        ) : (
          pdfData && (
            <div
              className={styles.pdfContainer}
              style={{
                transform: `scale(${
                  viewMode === "page-width" ? getPageWidthZoom() : zoom
                })`,
                transformOrigin: "top center",
              }}
            >
              <Document
                file={pdfData}
                onLoadSuccess={onLoadSuccess}
                onLoadError={onLoadError}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default PdfViewer;
