//imports
import React, { useState, useEffect, useCallback } from 'react';
import design from './comparative.module.scss';
import CollapsibleCard from "../../../../../components/collapsibleCard/collapsibleCard";
import CheckBox from 'devextreme-react/check-box';
import DateBox from 'devextreme-react/date-box';
import notify from 'devextreme/ui/notify';
import { GeneralSettings, IDashboardResult, MainDashboardRequest } from '../../../../../types/collision/collisionTypes';
import { INameId, NameValue } from '../../../../../types/general/generalTypes';
import { TesPost } from '../../../../../utils/rest';
import TagBox from 'devextreme-react/tag-box';
import { Chart, Series, CommonSeriesSettings, Legend, ValueAxis, Title, Export, Tooltip } from 'devextreme-react/chart';
import { CircularGauge, Scale, RangeContainer, Range, Font, ValueIndicator, SubvalueIndicator, Text, Label } from 'devextreme-react/circular-gauge';
import { GeneralSetting } from '../../../../../types/setting/gridSettingTypes';
import { useTranslation } from 'react-i18next';
import { CollisionApiUrl, InfrastructureApiUrl } from '../../../../../environment/routeSettings';
import { useAuth } from '../../../../../contexts/auth';
import { DashboardReportType, QueryAvgType } from '../../../../../types/collision/enums/collisionEnums';
import { TesCodeValue } from '../../../../../types/infrastructure/infrastructureTypes';
import { GetCollisionDatePeriodRequestDTO } from '../../../../../types/collision/dto/collisionDtos';
import { SortObjectByPropName } from '../../../../../utils/arrayTools';
import { stream } from 'exceljs';
import { Enum2Array } from '../../../../../utils/enumTools';
import { SelectBox } from 'devextreme-react';
import { CreateDateAsUTC, DatePickerDateCalculator } from '../../../../../utils/dateTimeTools';
import { useScreenSize } from '../../../../../utils/media-query';
import RangeValidator from '../../../../../components/rangeValidator/rangeValidator';
import { IsNullOrEmpty } from '../../../../../utils/stringTools';
import LoadingScreen, { CheckLoadingStates } from '../../../../../components/loadingScreen/loadingScreen';

// props
interface IPros {
    initDataSiteList: INameId[] | undefined;
    initDataTesCodeValues: TesCodeValue;
    generalSettings: GeneralSetting | null;
    datePeriod: GetCollisionDatePeriodRequestDTO | undefined
    initDataCollisionGeneralSetting: GeneralSettings | undefined
}

const Comparative = (props: IPros) => {
    //states
    const [initData, setInitData] = useState<IDashboardResult | undefined>(undefined);
    const [lstQueryAvgType, setLstQueryAvgType] = useState<NameValue[]>([]);
    const [dashboardReq, setDashboardReq] = useState<MainDashboardRequest>({
        customerId: localStorage.getItem('selectedCustomerId')!,
        divisionId: localStorage.getItem("selectedDivisionId")!,
        reportType: DashboardReportType.Comparative,
        severityList: [1, 2, 3],
        emphasisAreaIds: [...props.initDataCollisionGeneralSetting?.dashboardSettings?.emphasisAreaSettings?.map(x => x.fieldId)!],
        beginDateFrom: new Date(new Date(props.datePeriod?.endDT!).getFullYear() - 5, 0, 1, 0, 0, 0),
        beginDateTo: new Date(new Date(props.datePeriod?.endDT!).getFullYear() - 3, 11, 31, 0, 0, 0),
        endDateFrom: new Date(new Date(props.datePeriod?.endDT!).getFullYear() - 2, 0, 1, 0, 0, 0),
        endDateTo: new Date(new Date(props.datePeriod?.endDT!).getFullYear(), 11, 31, 0, 0, 0),
        jurisdictionIds: [],
        municipalityIds: [],
        areaIds: [],
        topNLocation: 10,
        queryAvgType: QueryAvgType.AvgYearly
    });

    const [loading, setLoading] = useState<boolean>(false)
    const [filterLoading, setFilterLoading] = useState<boolean>(false)
    const { t } = useTranslation();
    const { isXLarge } = useScreenSize();


    //functions

    const applyFilter = useCallback(async () => {
        try {
            setFilterLoading(true)
            var postOb: MainDashboardRequest = {
                ...dashboardReq,
                beginDateFrom: CreateDateAsUTC(dashboardReq.beginDateFrom!),
                beginDateTo: CreateDateAsUTC(dashboardReq.beginDateTo!),
                endDateFrom: CreateDateAsUTC(dashboardReq.endDateFrom!),
                endDateTo: CreateDateAsUTC(dashboardReq.endDateTo!),
            }
            setInitData(await (TesPost(CollisionApiUrl() + "/api/Reports/GetMainDashboard", postOb, true)))
            setFilterLoading(false);
        } catch (ex) {
            setFilterLoading(false)
            notify(t("someErrorOccurred") + ex, "error", 5000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardReq]);
    
    useEffect(() => {
        async function fetchMyAPI() {
            try {
                setLoading(true);
                setLstQueryAvgType(Enum2Array(QueryAvgType));
                await applyFilter();
                setLoading(false);
            } catch (ex) {
                notify(t("someErrorOccurred") + ex, "error", 5000);
                setLoading(false)
            }
        }
        fetchMyAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyFilter]);




    // function onChangeDate(name: string, value: Date) {
    //     if (name === "beginDateFrom") {
    //         if (beginDateTo < value || endDateFrom < value || endDateTo < value) {
    //             notify('Select valid date range.', "error", 5000);
    //             return;
    //         }
    //         setBeginDateFrom(value);
    //     }
    //     if (name === "beginDateTo") {
    //         if (beginDateFrom > value || endDateFrom < value || endDateTo < value) {
    //             notify('Select valid date range.', "error", 5000);
    //             return;
    //         }
    //         setBeginDateTo(value);
    //     }

    //     if (name === "endDateFrom") {
    //         if (endDateTo < value || beginDateTo > value || beginDateFrom > value) {
    //             notify('Select valid date range.', "error", 5000);
    //             return;
    //         }
    //         setEndDateFrom(value);
    //     }
    //     if (name === "endDateTo") {
    //         if (endDateFrom > value || beginDateFrom > value || beginDateTo > value) {
    //             notify('Select valid date range.', "error", 5000);
    //             return;
    //         }
    //         setEndDateTo(value);
    //     }
    // }


    const onValueChange = (name: string, value: any) => {
        if (name === "severity") {
            var index = dashboardReq.severityList.indexOf(value)
            if (index !== -1) {
                var lstNewSeverity = dashboardReq.severityList.filter(x => x !== value)
                if (lstNewSeverity.length === 0) {
                    notify(t('oneItemShouldBeSelected'), 'warning', 5000);
                    return;
                }
                setDashboardReq({ ...dashboardReq, severityList: lstNewSeverity });
            } else {
                setDashboardReq({ ...dashboardReq, severityList: [...dashboardReq.severityList, value] });
            }
        } else if (name === "emphasisArea") {
            var index = dashboardReq.emphasisAreaIds.indexOf(value)
            if (index !== -1) {
                var lstNewEmphasisAreaIds = dashboardReq.emphasisAreaIds.filter(x => x !== value)
                if (lstNewEmphasisAreaIds.length === 0) {
                    notify(t('oneItemShouldBeSelected'), 'warning', 5000);
                    return;
                }
                setDashboardReq({ ...dashboardReq, emphasisAreaIds: lstNewEmphasisAreaIds });
            } else {
                setDashboardReq({ ...dashboardReq, emphasisAreaIds: [...dashboardReq.emphasisAreaIds, value] });
            }
        } else if (name === "beginDateFrom") {
            if (dashboardReq.beginDateTo! < value) {
                notify('Select valid date range.', "error", 5000);
                return;
            }
            setDashboardReq({ ...dashboardReq, beginDateFrom: value });
        } else if (name === "beginDateTo") {
            if (dashboardReq.beginDateFrom! > value) {
                notify('Select valid date range.', "error", 5000);
                return;
            }

            setDashboardReq({
                ...dashboardReq,
                beginDateTo: value
            })
        }
        else if (name === "endDateFrom") {
            if (dashboardReq.endDateTo! < value || dashboardReq.beginDateTo! > value || dashboardReq.beginDateFrom! > value) {
                notify('Select valid date range.', "error", 5000);
                return;
            }

            setDashboardReq({
                ...dashboardReq,
                endDateFrom: value
            })
        }
        else if (name === "endDateTo") {
            if (dashboardReq.endDateFrom! > value || dashboardReq.beginDateFrom! > value || dashboardReq.beginDateTo! > value) {
                notify('Select valid date range.', "error", 5000);
                return;
            }

            setDashboardReq({
                ...dashboardReq,
                endDateTo: value
            })
        }
        else if (name === "siteList") {
            setDashboardReq({
                ...dashboardReq,
                areaIds: value
            })
        }
        else if (name === "municipality") {
            setDashboardReq({
                ...dashboardReq,
                municipalityIds: value
            })
        }
        else if (name === "jurisdiction") {
            setDashboardReq({
                ...dashboardReq,
                jurisdictionIds: value
            })
        }
        else if (name === "queryAvgType") {
            setDashboardReq({
                ...dashboardReq,
                queryAvgType: value
            })
        }
    }

    function customizeText({ valueText }: any) {
        return `${valueText}%`;
    }

    return (
        <div className={`${design.row}`}>
            {CheckLoadingStates([loading, filterLoading]) && <LoadingScreen/>}
            <div className={`${design.column1}`}>
                <CollapsibleCard compact='dashboard' title={t("severity")}>
                    <div className={`${design.collapsibleContentHorizontal}`}>
                        <CheckBox text={t("fatal")} value={dashboardReq.severityList.includes(3)} onValueChanged={() => onValueChange("severity", 3)}></CheckBox>
                        <CheckBox text={t("injury")} value={dashboardReq.severityList.includes(2)} onValueChanged={() => onValueChange("severity", 2)}></CheckBox>
                        <CheckBox text={t("PDO")} value={dashboardReq.severityList.includes(1)} onValueChanged={() => onValueChange("severity", 1)}></CheckBox>
                    </div>
                </CollapsibleCard>
                <CollapsibleCard compact='dashboard' title={t("emphasisArea")}>
                    <div className={`${design.collapsibleContentHorizontal}`}>
                        {(props.initDataCollisionGeneralSetting?.dashboardSettings?.emphasisAreaSettings ?? []).map(x => (
                            <CheckBox
                                text={x.title}
                                value={dashboardReq.emphasisAreaIds.includes(x.fieldId)}
                                onValueChanged={() => onValueChange("emphasisArea", x.fieldId)}
                                style={isXLarge ? { flex: "0 0 calc(50% - 10px)" } : { marginBottom: "0.25rem" }}
                            />
                        ))}
                    </div>
                </CollapsibleCard>
                <CollapsibleCard compact='dashboard' title={t("filter")}>
                    <div className={`${design.collapsibleContentVertical}`}>
                        <span className='title' style={{ marginTop: "-0.5rem", marginBottom: "0.75rem" }}>{t("beforePeriod")}</span>
                        <div style={{ display: "flex" }}>
                            <span style={{ width: "20%", display: "inline-block" }}>{t("from")}:</span>
                            <DateBox
                                // label={t('from')}
                                value={dashboardReq.beginDateFrom}
                                dateSerializationFormat={"yyyy-MM-ddTHH:mm:ss"}
                                type="date"
                                onValueChange={e => onValueChange('beginDateFrom', e)}
                                pickerType="calendar"
                                placeholder={props.generalSettings?.dateFormat}
                                displayFormat={props.generalSettings?.dateFormat}
                                useMaskBehavior={true}
                                openOnFieldClick={true}
                                showClearButton={true}
                                style={{ height: "2rem", marginBottom: "0.5rem", marginTop: "-0.5rem", width: "80%" }}
                            />
                        </div>
                        <div style={{ display: "flex" }}>
                            <span style={{ width: "20%", display: "inline-block" }}>{t("to")}:</span>
                            <DateBox
                                // label={t('to')}
                                value={dashboardReq.beginDateTo}
                                dateSerializationFormat={"yyyy-MM-ddTHH:mm:ss"}
                                type="date"
                                onValueChange={e => onValueChange('beginDateTo', e)}
                                pickerType="calendar"
                                placeholder={props.generalSettings?.dateFormat}
                                displayFormat={props.generalSettings?.dateFormat}
                                useMaskBehavior={true}
                                openOnFieldClick={true}
                                showClearButton={true}
                                style={{ height: "2rem", marginBottom: "0.5rem", width: "80%" }}
                            />
                        </div>
                        <span className='title'>{t("afterPeriod")}</span>
                        <div style={{ display: "flex" }}>
                            <span style={{ width: "20%", display: "inline-block" }}>{t("from")}:</span>
                            <DateBox
                                // label={t('from')}
                                value={dashboardReq.endDateFrom}
                                type="date"
                                onValueChange={e => onValueChange('endDateFrom', e)}
                                dateSerializationFormat={"yyyy-MM-ddTHH:mm:ss"}
                                pickerType="calendar"
                                placeholder={props.generalSettings?.dateFormat}
                                displayFormat={props.generalSettings?.dateFormat}
                                useMaskBehavior={true}
                                openOnFieldClick={true}
                                showClearButton={true}
                                style={{ height: "2rem", marginBottom: "0.5rem", width: "80%" }}
                            />
                        </div>
                        <div style={{ display: "flex" }}>
                            <span style={{ width: "20%", display: "inline-block" }}>{t("to")}:</span>
                            <DateBox
                                // label={t('to')}
                                value={dashboardReq.endDateTo}
                                type="date"
                                onValueChange={e => onValueChange('endDateTo', e)}
                                dateSerializationFormat={"yyyy-MM-ddTHH:mm:ss"}
                                pickerType="calendar"
                                placeholder={props.generalSettings?.dateFormat}
                                displayFormat={props.generalSettings?.dateFormat}
                                useMaskBehavior={true}
                                openOnFieldClick={true}
                                showClearButton={true}
                                style={{ height: "2rem", marginBottom: "0.5rem", width: "80%" }}
                            />
                        </div>
                    </div>

                    <div className={`${design.collapsibleContentVertical}`}>
                        <SelectBox
                            label={t("collisionFrequency")}
                            value={dashboardReq.queryAvgType}
                            onValueChange={(e) => onValueChange("queryAvgType", e)}
                            className="modalInput"
                            valueExpr="value"
                            displayExpr="name"
                            items={SortObjectByPropName(lstQueryAvgType, "name")}
                            searchEnabled={true}
                            style={{ marginBottom: '5px' }}
                        />
                        <TagBox
                            label={t("siteList")}
                            dataSource={props.initDataSiteList}
                            valueExpr="id"
                            displayExpr="name"
                            onValueChanged={a => onValueChange("siteList", a.value)}
                            searchEnabled={true}
                            style={{ marginBottom: '5px' }}

                        />
                        {IsNullOrEmpty(localStorage.getItem("selectedDivisionId")) && props.initDataTesCodeValues.jurisdictions?.length !== 0 && props.initDataTesCodeValues.jurisdictions !== undefined &&
                            <TagBox
                                label={t("jurisdiction")}
                                dataSource={SortObjectByPropName(props.initDataTesCodeValues.jurisdictions, "name")}
                                valueExpr="id"
                                displayExpr="name"
                                onValueChanged={a => onValueChange("jurisdiction", a.value)}
                                searchEnabled={true}
                                style={{ marginBottom: '5px' }}
                            />
                        }
                        {IsNullOrEmpty(localStorage.getItem("selectedDivisionId")) && props.initDataTesCodeValues.municipalities?.length !== 0 && props.initDataTesCodeValues.municipalities !== undefined &&
                            <TagBox
                                label={t("municipalities")}
                                dataSource={SortObjectByPropName(props.initDataTesCodeValues.municipalities, "name")}
                                valueExpr="id"
                                displayExpr="name"
                                onValueChanged={a => onValueChange("municipality", a.value)}
                                searchEnabled={true}
                                style={{ marginBottom: '5px' }}
                            />
                        }
                    </div>
                </CollapsibleCard>
            </div>

            <div className={`${design.column2}`}>
                <CollapsibleCard title={t('collisionFrequency')}>

                    {(initData && initData.comparativeResults) &&
                        <Chart id="chart"
                            dataSource={initData.comparativeResults.map(x => ({ name: x.name, beforePDO: x.before.pdo, beforeFatalInjury: x.before.fatalInjury, afterPDO: x.after.pdo, afterFatalInjury: x.after.fatalInjury }))}
                        >
                            <CommonSeriesSettings argumentField="name" type="stackedbar" barWidth={60} />
                            <Series
                                valueField="beforePDO"
                                name={t("beforePDO")}
                                color="#00a9b5"
                                stack="before"
                            />
                            {(dashboardReq.severityList.includes(2) || dashboardReq.severityList.includes(3)) &&
                                <Series
                                    valueField={t("beforeFatalInjury")}
                                    name={(dashboardReq.severityList.includes(2) && dashboardReq.severityList.includes(3)) ? t("beforeFatal/Injury") : (dashboardReq.severityList.includes(2)) ? t("beforeInjury") : dashboardReq.severityList.includes(3) ? t("beforeFatal") : ""}
                                    color="#1357be"
                                    stack="before"
                                />
                            }
                            <Series
                                valueField="afterPDO"
                                name={t("afterPDO")}
                                color="#00c698"
                                stack="after"
                            />

                            {(dashboardReq.severityList.includes(2) || dashboardReq.severityList.includes(3)) &&
                                <Series
                                    valueField="afterFatalInjury"
                                    name={(dashboardReq.severityList.includes(2) && dashboardReq.severityList.includes(3)) ? t("afterFatal/Injury") : (dashboardReq.severityList.includes(2)) ? t("afterInjury") : dashboardReq.severityList.includes(3) ? t("afterFatal") : ""}
                                    color="#008ac5"
                                    stack="after"
                                />
                            }

                            <Legend position="inside" horizontalAlignment="right" columnCount={2}></Legend>
                            <ValueAxis
                                name="collisionAxix"
                                position="left"
                                showZero={true}
                                valueMarginsEnabled={false}
                                allowDecimals={false}
                            >
                                <Title text={dashboardReq.queryAvgType === QueryAvgType.Total ? t("numberOfCollisions") : t("avgNumberOfCollisions")} />
                            </ValueAxis>

                            <Export enabled={true} printingEnabled={false} />

                            <Tooltip
                                enabled={true}
                                location="edge"
                            />

                        </Chart>
                    }
                </CollapsibleCard>
                <div className={`${design.halfColumn}`} style={{ marginTop: "1rem" }}>
                    <div>
                        {initData && initData.comparativeResults.filter(x => x.upperRange !== 0).map((d, index) => {
                            var currenrtEmph = props.initDataCollisionGeneralSetting?.dashboardSettings?.emphasisAreaSettings?.find(x => x.title === d.name)
                            return (
                                <div key={index} style={{ marginTop: index === 0 ? '-8px' : '0' }}>
                                    <CollapsibleCard>
                                        <CircularGauge className={`${design.seperator}`}
                                            id="gauge"
                                            value={d.improvementPercentage}
                                        >
                                            <Scale startValue={currenrtEmph?.minPercentage ?? -100} endValue={currenrtEmph?.maxPercentage ?? 100} tickInterval={10}>
                                                <Label customizeText={customizeText} />
                                            </Scale>
                                            <RangeContainer palette="Pastel">
                                                <Range startValue={-1 * d.upperRange} endValue={0} color='#00ff00' />
                                                <Range startValue={0} endValue={d.upperRange} color='#cc0605' />
                                            </RangeContainer>
                                            <Title text={t(d.name)}>
                                                <Font color={'#424242'} size={20} />
                                            </Title>
                                            <Export enabled={true} />
                                        </CircularGauge>
                                        {d.improvementPercentage < 0 &&
                                            <div className={`${design.row}`} >
                                                <div className={`${design.gaugeDetailsCol1}`} >
                                                    <span className="dx-icon-arrowup" style={{ fontSize: '24px', color: '#00ff00' }} />
                                                </div>
                                                <div className={`${design.gaugeDetailsCol2}`} >
                                                    <p>{t("improvement")}: {Math.round(d.improvementPercentage * (-1)) + "%"}</p>
                                                </div>
                                            </div>
                                        }

                                        {d.improvementPercentage > 0 &&
                                            <div className={`${design.row}`} >
                                                <div className={`${design.gaugeDetailsCol1}`}>
                                                    <span className="dx-icon-arrowdown" style={{ fontSize: '24px', color: '#cc0605' }} />
                                                </div>
                                                <div className={`${design.gaugeDetailsCol2}`}>
                                                    <p>{t("setBack")}: {Math.round(d.improvementPercentage * (-1)) + "%"}</p>
                                                </div>
                                            </div>
                                        }
                                        {currenrtEmph?.goalTracking === true &&
                                            <div style={{ margin: "1.25rem 0.5rem 0.75rem 0.5rem" }}>
                                                <RangeValidator
                                                    minGoal={Math.round(d.improvementPercentage * (-1)) < (currenrtEmph?.goalMinPercentage ?? 0) ? Math.round(d.improvementPercentage * (-1)) - 2 : (currenrtEmph?.goalMinPercentage ?? -10)}
                                                    maxGoal={Math.round(d.improvementPercentage * (-1)) > (currenrtEmph?.goalMaxPercentage ?? 0) ? Math.round(d.improvementPercentage * (-1)) + 2 : (currenrtEmph?.goalMaxPercentage ?? 20)}
                                                    goal={currenrtEmph?.goal ?? 10}
                                                    value={Math.round(d.improvementPercentage * (-1))}
                                                    unit="%"
                                                    goalText={t("goal")}
                                                />
                                            </div>
                                        }
                                        <div className={`${design.row}`}>
                                            <div className={`${design.beforeValueCol}`} >
                                                <p className={`${design.titleDark}`}>{dashboardReq.queryAvgType === QueryAvgType.Total ? t("beforeCollisionValue") : t("beforeCollisionValueAvg")} <span style={{ fontWeight: 'normal' }}>{d.before.total}</span></p>
                                            </div>
                                            <div className={`${design.afterValueCol}`} >
                                                <p className={`${design.titleDark}`}>{dashboardReq.queryAvgType === QueryAvgType.Total ? t("afterCollisionValue") : t("afterCollisionValueAvg")} <span style={{ fontWeight: 'normal' }}>{d.after.total}</span></p>
                                            </div>
                                        </div>
                                    </CollapsibleCard>
                                </div>
                            )
                        }



                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Comparative;