import React, { useRef, useState, useEffect } from "react";
import "./gisMap.scss";
import { Button } from "devextreme-react/button";
import { TesMapModule } from "../../../types/general/enums/generalEnums";
import { useTranslation } from "react-i18next";
import { LocationType } from "../../../types/infrastructure/enums/infrastructureEnums";
import { useInitializeMap } from "../v3/hooks/useInitializeMap";
import { useIntersectionLayer } from "../v3/hooks/useIntersectionsLayer";
import { useRoadSegmentLayer } from "../v3/hooks/useRoadSegmentLayer";
import { useCustomLayers } from "../v3/hooks/useCustomLayers";
import Graphic from "@arcgis/core/Graphic";
import MapView from "@arcgis/core/views/MapView";
import {
  addClickOnMapTrigger,
  addDoubleClickWithControlOnMapTrigger,
  adjustViewZooming,
  createGraphic,
  findIntersections,
  findRoadSegments,
  onSelectedFeature_v1,
  openInGoogleMap,
  openStreetView,
} from "../v3/utils";
import { MapSetting } from "../../../types/infrastructure/infrastructureTypes";
import {
  VMCoordination,
  VMLocation,
} from "../../../types/infrastructure/dto/locationdDto";
import { VMMapLocation } from "../../../types/collision/dto/collisionDtos";
import Collection from "@arcgis/core/core/Collection";
import { registerPopupActionFunctions } from "../v3/utils/popupsActions";
import { addSketchViewModel } from "../v3/utils/addSketchViewModel";

let intersectionHighlightedRefs: __esri.Handle[] = [];
let roadSegmentHighlightedRefs: __esri.Handle[] = [];
let userInfrastructureSelectedHighlightedRef: __esri.Handle[] = [];

type TProps = {
  initDataGis: MapSetting | undefined;
  tesModule: TesMapModule;
  go2Intersection: (geoId: string) => void;
  go2RoadSegment: (geoId: string) => void;
  filterByGeoId?: (selectedInfrastructures: any[]) => void;
  addLocationFromMap?: (lstData: VMLocation[]) => void;
  filterByLatLong?: (selectedArea: any) => void;
  handleLocationChangeDirectly?: (value: {
    latitude: number;
    longitude: number;
    xCoord: number;
    yCoord: number;
  }) => void;
  go2Collision?: (id: string) => void;
  go2SignSupport: (id: string) => void;
  selectLocation?: (
    geoId: string,
    locationType: LocationType,
    coordinate: VMMapLocation
  ) => void;
  lstLatLng: VMCoordination[];
  geoCodingBehavior?: boolean;
  specifiedInfrastructureData: any;
};
export function GeneralGisMap_v3({
  initDataGis,
  tesModule,
  go2Intersection,
  go2RoadSegment,
  filterByGeoId,
  addLocationFromMap,
  filterByLatLong,
  handleLocationChangeDirectly,
  go2Collision,
  go2SignSupport,
  selectLocation,
  lstLatLng,
  geoCodingBehavior = false,
  specifiedInfrastructureData,
}: TProps) {
  const { t } = useTranslation();

  const mapEl = useRef(null);
  const zoomTimerRef = useRef<NodeJS.Timeout | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [showOverlayText, setShowOverlayText] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedArea, setSelectedArea] = useState({});
  const [lstLocalSelectedIntersection, setLstLocalSelectedIntersection] =
    useState<string[]>([]);
  const [lstLocalSelectedRoadSegment, setLstLocalSelectedRoadSegment] =
    useState<string[]>([]);
  const [isFindInfrastructureLoading, setIsFindInfrastructureLoading] =
    useState(false);

  let isMeasurementModeActive = false;
  let selectedLongitude = 0;
  let selectedLatitude = 0;
  let selectedXCoord = 0;
  let selectedYCoord = 0;

  const isSelectionEnabled =
    tesModule === TesMapModule.Collision ||
    tesModule === TesMapModule.Infrastructure ||
    tesModule === TesMapModule.AreaDetails ||
    tesModule === TesMapModule.Sign ||
    tesModule === TesMapModule.Support ||
    tesModule === TesMapModule.PatrolArea ||
    tesModule === TesMapModule.Study ||
    tesModule === TesMapModule.AADT;
  const isMovingMapEnabled =
    tesModule === TesMapModule.CollisionDetails ||
    tesModule === TesMapModule.InfrastructureDetails ||
    tesModule === TesMapModule.StudyDetails;
  const onClickedOnMap = (
    event: __esri.ViewClickEvent,
    hitTestResponse: __esri.MapViewViewHit
  ) => {
    if (
      tesModule === TesMapModule.CollisionDetails ||
      tesModule === TesMapModule.StudyDetails
    ) {
      selectedLongitude = event.mapPoint.longitude;
      selectedLatitude = event.mapPoint.latitude;
      selectedXCoord = event.mapPoint.x;
      selectedYCoord = event.mapPoint.y;
    }
    customizePopupByClickOnCollision(hitTestResponse);
  };

  const onDoubleClickedOnMap = (event: __esri.ViewDoubleClickEvent) => {
    if (view) {
      view.popup.close?.();
      view.graphics.removeAll();
      handleLocationChangeDirectly?.({
        latitude: event.mapPoint.latitude,
        longitude: event.mapPoint.longitude,
        xCoord: event.mapPoint.x,
        yCoord: event.mapPoint.y,
      });
      createGraphic(
        view,
        event.mapPoint.latitude,
        event.mapPoint.longitude,
        null
      );
    }
  };
  const foundInfrastructureLatLongCallback = ({
    lat,
    long,
    x,
    y,
  }: {
    lat: number;
    long: number;
    x: number;
    y: number;
  }) => {
    setIsFindInfrastructureLoading(false);
    if (
      view &&
      initDataGis &&
      geoCodingBehavior &&
      initDataGis?.setInfrastructureCoordinatesInGeoCoding
    ) {
      view.graphics.removeAll();
      handleLocationChangeDirectly?.({
        latitude: lat,
        longitude: long,
        xCoord: x,
        yCoord: y,
      });
      createGraphic(view, lat, long, null);
    }
  };

  const customizePopupByClickOnCollision = (
    response: __esri.MapViewViewHit
  ) => {
    const attributes = (response as __esri.MapViewGraphicHit).graphic
      .attributes;
    if (view && attributes.id) {
      setTimeout(() => {
        view.popup.close?.();
      }, 100);
      if (
        tesModule === TesMapModule.Collision ||
        tesModule === TesMapModule.Infrastructure ||
        tesModule === TesMapModule.Study ||
        tesModule === TesMapModule.AADT
      ) {
        setTimeout(() => {
          view.popup.actions = new Collection([
            {
              id: "go-to-collision",
              image: "https://staticfile.tes.ca/gisMap/car-collision.png",
              title: "Show Collision Detail",
              type: "button",
            },
          ]);
          view.popup.open({
            location: response.mapPoint,
            title: "Collision Data",
            content: attributes.id,
          });
        }, 200);
      }

      if (
        tesModule === TesMapModule.Sign ||
        tesModule === TesMapModule.Support
      ) {
        setTimeout(() => {
          view.popup.actions = new Collection([
            {
              id: "go-to-signSupport",
              image: "https://staticfile.tes.ca/gisMap/sign.png",
              title:
                tesModule === TesMapModule.Support
                  ? "Show Support Details"
                  : tesModule === TesMapModule.Sign
                    ? "Show Sign Details"
                    : "",
              type: "button",
            },
          ]);
          view.popup.open({
            location: response.mapPoint,
            title: "Sign/Support Data",
            content: attributes.id,
          });
        }, 200);
      }
    } else {
      const removedGoToAction = view?.popup.actions.filter((item) => {
        return item.id !== "go-to-collision" && item.id !== "go-to-signSupport";
      });
      if (view) view.popup.actions = new Collection(removedGoToAction);
    }
  };

  const removeAllAutomatedHighlight = () => {
    if (intersectionHighlightedRefs.length > 0) {
      intersectionHighlightedRefs.forEach((e) => e.remove());
    }
    if (roadSegmentHighlightedRefs.length > 0) {
      roadSegmentHighlightedRefs.forEach((e) => e.remove());
    }
  };
  const removeAllUserHighlight = () => {
    if (userInfrastructureSelectedHighlightedRef.length > 0) {
      userInfrastructureSelectedHighlightedRef.forEach((e) => e.remove());
    }
  };

  function setRoadSegmentHighlightedRefs(
    refs: __esri.Handle[],
    shouldOverRide?: boolean
  ) {
    if (shouldOverRide) {
      roadSegmentHighlightedRefs = [...refs];
    } else {
      refs.forEach((ref) => {
        roadSegmentHighlightedRefs.push(ref);
      });
    }
  }
  function setIntersectionHighlightedRefs(
    refs: __esri.Handle[],
    shouldOverRide?: boolean
  ) {
    if (shouldOverRide) {
      intersectionHighlightedRefs = [...refs];
    } else {
      refs.forEach((ref) => {
        intersectionHighlightedRefs.push(ref);
      });
    }
  }
  const withoutPermissionZoomTrying = (isTrying: boolean) => {
    if (!isTrying) {
      setShowOverlayText(false);
    } else {
      if (!showOverlayText) {
        setShowOverlayText(true);
        if (zoomTimerRef.current) {
          clearTimeout(zoomTimerRef.current);
        }
        zoomTimerRef.current = setTimeout(() => {
          setShowOverlayText(false);
          zoomTimerRef.current = null;
        }, 4000);
      }
    }
  };

  // Initialize map with the required configuration
  const {
    view,
    map,
    legend,
    legendExpand,
    isInitialLoading,
    measurementWidget,
  } = useInitializeMap({
    mapEl,
    initDataGis,
    isMovingMapEnabled,
    hasLegend: false,
    hasLayerListWidget: true,
    hasPrintWidget: true,
    zoomBehavior: {
      isDefault: false,
      zoomOptions: {
        disableMouseWheel: true,
        disableDoubleClickZoom: true,
        disableDoubleClickWithControlPressed: !isMeasurementModeActive,
        disableKeyboardZoom: true,
        disablePopupZoom: true,
      },
      withoutPermissionZoomTryingCallback: withoutPermissionZoomTrying,
    },
  });

  const { roadSegmentLayer, roadSegmentLayerId, isRoadSegmentLoading } =
    useRoadSegmentLayer({
      view,
      map,
      mapEl,
      initDataGis,
      tesModule,
    });

  const { intersectionLayer, intersectionLayerId, isIntersectionLoading } =
    useIntersectionLayer({
      view,
      map,
      mapEl,
      initDataGis,
      tesModule,
    });

  const { isCustomLayerLoading } = useCustomLayers({ map, mapEl, initDataGis });

  const onSelectedFeatureCallback = (graphic: Graphic, view: MapView) => {
    removeAllUserHighlight();
    view.whenLayerView(graphic.layer).then((layerView) => {
      const highlight = (layerView as __esri.GraphicsLayerView).highlight(
        graphic
      );
      // const graphicType = graphic?.geometry?.type;
      userInfrastructureSelectedHighlightedRef = [
        ...userInfrastructureSelectedHighlightedRef,
        highlight,
      ];
    });
  };

  useEffect(() => {
    if (
      !isInitialLoading &&
      !isRoadSegmentLoading &&
      !isIntersectionLoading &&
      !isCustomLayerLoading &&
      view &&
      roadSegmentLayer &&
      intersectionLayer
    ) {
      adjustViewZooming({
        expand: 1,
        view,
        layers: [intersectionLayer, roadSegmentLayer],
        finishedZoomingCallback: (zoomResult) => {
          setIsLoading(false);
        },
      });
    }
  }, [
    isInitialLoading,
    isRoadSegmentLoading,
    isIntersectionLoading,
    isCustomLayerLoading,
    view,
    roadSegmentLayer,
    intersectionLayer,
  ]);

  useEffect(() => {
    if (view) {
      onSelectedFeature_v1(view, (graphic) => {
        onSelectedFeatureCallback(graphic, view);
      });
    }
  }, [view]);

  useEffect(() => {
    if (
      map &&
      view &&
      intersectionLayer &&
      roadSegmentLayer &&
      initDataGis &&
      !isIntersectionLoading &&
      !isRoadSegmentLoading &&
      !isInitialLoading
    ) {
      isSelectionEnabled &&
        addSketchViewModel({
          view,
          map,
          layers: [intersectionLayer, roadSegmentLayer],
          initDataGis,
          setSelectedArea,
          setSelectedData,
        });
      addClickOnMapTrigger(view, onClickedOnMap);

      if (
        tesModule === TesMapModule.CollisionDetails ||
        tesModule === TesMapModule.CollisionGeoCode ||
        tesModule === TesMapModule.StudyDetails
      ) {
        addDoubleClickWithControlOnMapTrigger(view, onDoubleClickedOnMap);
      }
      registerPopupActionFunctions(
        { view, initDataGis, intersectionLayer, roadSegmentLayer },
        {
          "go-to-roadSegment": {
            go2RoadSegment,
          },
          "go-to-intersection": {
            go2Intersection,
          },
          "open-google-maps": {
            openInGoogleMap: openInGoogleMap,
          },
          "select-intersection": {
            selectLocation,
            selectedLatitude,
            selectedLongitude,
            selectedXCoord,
            selectedYCoord,
            setLstLocalSelectedIntersection,
            setLstLocalSelectedRoadSegment,
            removeAllHighlight: () => {
              removeAllUserHighlight();
              removeAllAutomatedHighlight();
            },
            setIntersectionHighlightedRefs,
          },
          "street-view": {
            openStreetView: openStreetView,
          },
          "intersection-show-all-data-of-geoId": {
            filterByGeoId,
          },
          "roadSegment-show-all-data-of-geoId": {
            filterByGeoId,
          },
          "select-roadSegment": {
            selectLocation,
            selectedLatitude,
            selectedLongitude,
            selectedXCoord,
            selectedYCoord,
            setLstLocalSelectedIntersection,
            setLstLocalSelectedRoadSegment,
            removeAllHighlight: () => {
              removeAllUserHighlight();
              removeAllAutomatedHighlight();
            },
            setRoadSegmentHighlightedRefs,
          },
          "go-to-collision": {
            go2Collision,
          },
          "go-to-signSupport": {
            go2SignSupport,
          },
        }
      );
    }
  }, [
    map,
    view,
    intersectionLayer,
    roadSegmentLayer,
    initDataGis,
    isIntersectionLoading,
    isRoadSegmentLoading,
    isInitialLoading,
  ]);

  useEffect(() => {
    const infrastructuresData = Array.isArray(specifiedInfrastructureData)
      ? specifiedInfrastructureData
      : [specifiedInfrastructureData];
    if (
      view &&
      roadSegmentLayer &&
      intersectionLayer &&
      initDataGis &&
      !isLoading &&
      ((infrastructuresData.length > 0 && infrastructuresData[0].geoId) ||
        (lstLatLng.length > 0 && lstLatLng[0]?.latitude))
    ) {
      removeAllUserHighlight();
      removeAllAutomatedHighlight();
      let lstRoadSegmentGeoIds: string[] = [];
      let lstIntersectionGeoIds: string[] = [];

      infrastructuresData.forEach((infrastructure) => {
        if (infrastructure.locationType === LocationType.Intersection) {
          lstIntersectionGeoIds.push(infrastructure.geoId);
        } else if (infrastructure.locationType === LocationType.Midblock) {
          lstRoadSegmentGeoIds.push(infrastructure.geoId);
        }
      });

      if (lstRoadSegmentGeoIds.length > 0) {
        setLstLocalSelectedRoadSegment(lstRoadSegmentGeoIds);
        setIsFindInfrastructureLoading(true);
        findRoadSegments({
          view,
          roadSegmentLayer,
          initDataGis,
          shouldGoToFirstFoundItem: geoCodingBehavior
            ? true
            : lstRoadSegmentGeoIds.length === 1 &&
              (lstLatLng.length === 0 ||
                !lstLatLng ||
                !lstLatLng[0].latitude ||
                !lstLatLng[0].longitude),
          lstRoadSegmentGeoIds,
          setRoadSegmentHighlightedRefs,
          foundRoadSegmentLatLongCallback: foundInfrastructureLatLongCallback,
        });
      }
      if (lstIntersectionGeoIds.length > 0) {
        setLstLocalSelectedIntersection(lstIntersectionGeoIds);
        setIsFindInfrastructureLoading(true);
        findIntersections({
          view,
          intersectionLayer,
          initDataGis,
          shouldGoToFirstFoundItem: geoCodingBehavior
            ? true
            : lstIntersectionGeoIds.length === 1 &&
              (lstLatLng.length === 0 ||
                !lstLatLng ||
                !lstLatLng[0].latitude ||
                !lstLatLng[0].longitude),
          lstIntersectionGeoIds,
          setIntersectionHighlightedRefs,
          foundIntersectionLatLongCallback: foundInfrastructureLatLongCallback,
        });
      }
    }
  }, [
    view,
    roadSegmentLayer,
    intersectionLayer,
    initDataGis,
    specifiedInfrastructureData,
    lstLatLng,
    isLoading,
  ]);

  useEffect(() => {
    if (!isLoading && lstLatLng.length > 0 && view) {
      view.graphics.removeAll();
      const interval = setInterval(() => {
        if (view.graphics.length === 0) {
          clearInterval(interval);
          lstLatLng.forEach((loc) => {
            if (loc.latitude !== 0.0 && loc.longitude !== 0.0) {
              createGraphic(view, loc.latitude, loc.longitude, loc.id);
            }
          });
        }
      }, 50);
      if (lstLatLng.length === 1 && lstLatLng[0].latitude) {
        view.goTo({
          center: [lstLatLng[0].longitude, lstLatLng[0].latitude],
          zoom: 15,
        });
      }
    }
  }, [lstLatLng, isLoading, view]);

  const MAP_TOP_BUTTON = {
    [TesMapModule.Collision.toString()]: {
      textKey: "showAllCollisions",
      onClick: () => filterByGeoId?.(selectedData),
    },
    [TesMapModule.Infrastructure.toString()]: {
      textKey: "showAllLocations",
      onClick: () => filterByGeoId?.(selectedData),
    },
    [TesMapModule.Study.toString()]: {
      textKey: "showAllStudies",
      onClick: () => filterByGeoId?.(selectedData),
    },
    [TesMapModule.AADT.toString()]: {
      textKey: "showAllAadts",
      onClick: () => filterByGeoId?.(selectedData),
    },
    [TesMapModule.AreaDetails.toString()]: {
      textKey: "addLocations",
      onClick: () => addLocationFromMap?.(selectedData),
    },
    [TesMapModule.PatrolArea.toString()]: {
      textKey: "addLocations",
      onClick: () => addLocationFromMap?.(selectedData),
    },
    [TesMapModule.Sign.toString()]: {
      textKey: "showSigns",
      onClick: () => filterByLatLong?.(selectedArea),
    },
    [TesMapModule.Support.toString()]: {
      textKey: "showSupports",
      onClick: () => filterByLatLong?.(selectedArea),
    },
  };
  const go2SelectedLocation = () => {
    if (
      view &&
      intersectionLayer &&
      initDataGis &&
      lstLocalSelectedIntersection.length === 1
    ) {
      setIsFindInfrastructureLoading(true);
      findIntersections({
        view,
        intersectionLayer,
        initDataGis,
        shouldGoToFirstFoundItem: true,
        lstIntersectionGeoIds: lstLocalSelectedIntersection,
        setIntersectionHighlightedRefs,
        foundIntersectionLatLongCallback: () => {
          setIsFindInfrastructureLoading(false);
        },
      });
    } else if (
      view &&
      roadSegmentLayer &&
      initDataGis &&
      lstLocalSelectedRoadSegment.length === 1
    ) {
      setIsFindInfrastructureLoading(true);
      findRoadSegments({
        view,
        roadSegmentLayer,
        initDataGis,
        shouldGoToFirstFoundItem: true,
        lstRoadSegmentGeoIds: lstLocalSelectedRoadSegment,
        setRoadSegmentHighlightedRefs,
        foundRoadSegmentLatLongCallback: () => {
          setIsFindInfrastructureLoading(false);
        },
      });
    }
  };
  const go2FirstLatLng = () => {
    setTimeout(() => {
      view?.goTo({
        center: [lstLatLng[0].longitude, lstLatLng[0].latitude],
        zoom: 15,
      });
    }, 300);
  };

  const renderTopButton = () => {
    if (!MAP_TOP_BUTTON?.[tesModule.toString()] || selectedData.length < 1)
      return null;
    return (
      <Button
        className="tes-modal-btn-add"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={MAP_TOP_BUTTON[tesModule].onClick}
        style={{ width: "10rem", margin: "-1rem 0.5rem 0.5rem 0" }}
      >
        {t(MAP_TOP_BUTTON[tesModule].textKey)}
      </Button>
    );
  };

  return (
    <div className="generalGISMapNew">
      <div className="mainContainer">{renderTopButton()}</div>
      <div
        style={{
          position: "relative",
        }}
      >
        {isFindInfrastructureLoading && (
          <div className={"mapLoadingRowContainer_fullHeight"}>
            {t("loading")}
          </div>
        )}
        {isLoading && (
          <div className={"mapLoadingContainer"}>{t("loading")}</div>
        )}
        {showOverlayText && (
          <div
            className={"text_box_v1"}
            onMouseOver={() => {
              view && view.focus();
            }}
          >
            {t("useCtrl+ScrollToZoomTheMap")}
          </div>
        )}
        <div
          ref={mapEl}
          onMouseOver={() => {
            view && view.focus();
          }}
          className={"map_box2"}
        />
        {isMovingMapEnabled && (
          <>
            <div
              id="go-to-location"
              className="esri-widget--button esri-widget esri-interactive"
              title="Go to Selected Location"
              onClick={go2SelectedLocation}
            >
              <span className="esri-icon-maps"></span>
            </div>

            <div
              id="go-to-coordinate"
              className="esri-widget--button esri-widget esri-interactive"
              title="Go to Original Coordinate"
              onClick={go2FirstLatLng}
            >
              <span className="esri-icon-north-navigation-filled"></span>
            </div>
          </>
        )}
        {isSelectionEnabled && (
          <>
            <div
              id="select-by-polygon"
              className="esri-widget esri-widget--button esri-interactive esri-ui-top-left esri-ui-corner"
              title="Select features by polygon"
              style={{ marginTop: "5.1rem", marginLeft: "0" }}
            >
              <span className="esri-icon-checkbox-unchecked" />
            </div>

            <div
              id="clear-polygon"
              className="esri-widget esri-widget--button esri-interactive esri-ui-top-left esri-ui-corner"
              title="Clear Selection"
              style={{ marginTop: "7.15rem", marginLeft: "0" }}
            >
              <span className="esri-icon-close-circled" />
            </div>
          </>
        )}
        <div
          id="distance"
          className="esri-widget--button esri-widget esri-interactive esri-ui-top-right esri-ui-corner ToolsSquareShadow"
          style={{ marginTop: "6.1rem", marginRight: "0.9rem" }}
          title="Distance Measurement Tool"
          onClick={() => {
            if (measurementWidget) measurementWidget.activeTool = "distance";
            isMeasurementModeActive = true;
          }}
        >
          <span className="esri-icon-measure-line" />
        </div>

        <div
          id="clear-distance"
          className="esri-widget esri-widget--button esri-widget esri-interactive esri-ui-top-right esri-ui-corner ToolsSquareShadow"
          style={{ marginTop: "8.15rem", marginRight: "0.9rem" }}
          title="Clear Measurement Tool"
          onClick={() => {
            if (measurementWidget) measurementWidget.clear();
            isMeasurementModeActive = false;
          }}
        >
          <span className="esri-icon-trash" />
        </div>

        <div id="info" className="esri-widget" />
      </div>
    </div>
  );
}
