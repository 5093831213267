import { Button, DropDownButton } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import Tabs from "devextreme-react/tabs";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Permission from "../../../../components/permission/permision";
import getTitle from "../../../../components/title/getTitle";
import { TrafficStudyPermissions } from "../../../../constants/Permissions";
import { useAuth } from "../../../../contexts/auth";
import { FieldApiUrl, TrafficApiUrl } from "../../../../environment/routeSettings";
import { PagePermission, ResponseCode } from "../../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../../types/general/generalTypes";
import { AADTType } from "../../../../types/trafficStudy/enums/trafficStudyEnums";
import { IAADTDetailsParams, TrafficSectionAADT } from "../../../../types/trafficStudy/trafficStudyTypes";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";
import AADT from "./components/aadt/aadt";
import tabTitles from "./data/index";
import "./trafficSectionAADTDetails.scss";
import TesTabs from "../../../../components/tesTab/tesTab";

const TrafficSectionAADTDetails = () => {
    const history = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [dataChanged, setDataChanged] = useState(false);
    const [initData, setInitData] = useState<TrafficSectionAADT>(new TrafficSectionAADT());
    const params = useParams<IAADTDetailsParams>();
    const validationRef = useRef<ValidationGroupRef>(null);
    const { t } = useTranslation();
    const { activeLoading } = useAuth();
    const [isLocked, setIsLocked] = useState(true);
    const didMount = useRef(false);
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
    const geoIdRef = useRef<HTMLSpanElement>(null);
    const yearRef = useRef<HTMLSpanElement>(null);
    const locDescriptionRef = useRef<HTMLSpanElement>(null);
    const [pagePermissionStatus, setPagePermissionStatus] = useState<PagePermission>(PagePermission.Deny);
    const { getPagePermission } = useAuth();

    const title = getTitle('/trafficStudy/aadts/trafficSectionAADTDetails', ``);
    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                if (!didMount.current) {
                    if (params.aadtId !== "AddNew") {
                        await getInitData(params.aadtId!, params.aadtType!);
                    } else {
                        setIsLocked(false)
                    }
                }
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.aadtId]);

    //function for changing the tabs
    function handleTabChange(index: number) {
        setSelectedIndex(index);
    }

    //General Functions
    async function getInitData(id: string, aadtType: string) {
        try {
            let res = await TesGet(
                TrafficApiUrl() + "/api/AADT/GetTrafficSectionAADTDetails/" + id,
                true
            ) as RequestResponseResult<TrafficSectionAADT>;
            if (res.responseCode === ResponseCode.OK) {
                if (getPagePermission) setPagePermissionStatus(getPagePermission([TrafficStudyPermissions.TrafficStudy_D_Study,
                TrafficStudyPermissions.TrafficStudy_E_Study], res.results));

                setInitData(res.results)
            }
            else if (res.responseCode === ResponseCode.IdNotExists) {
                notify(t("idNotExists"), "error", 5000)
                history(-1);
            }
            else {
                await RequestErrorHandling(res);;
            };
        } catch (ex) {
            notify(t("errorInFetchData") + ex, "error", 5000);
        }
    }

    function goBackPermission() {
        if (dataChanged === true) {
            //dialog to show if you want to save the changed the data or discard it.
            let myDialog = custom({
                title: t("warning"),
                messageHtml: t("unsavedDataWarningText"),
                buttons: [
                    {
                        text: t("yes"),
                        onClick: (e) => {
                            try {
                                if (params.aadtId !== "AddNew") {
                                    onUpdate(true);
                                    setDataChanged(false)
                                } else {
                                    onAdd();
                                    setDataChanged(false)
                                }
                                notify(t("dataSuccessfullyUpdated"), "success", 5000);
                            } catch {
                                notify(t("someErrorOccurred"), "error", 5000);
                            }
                            return { buttonText: e.component.option("text") };
                        },
                    },
                    {
                        text: t("no"),
                        onClick: (e) => {
                            history(-1);
                            return { buttonText: e.component.option("text") };
                        },
                    },
                    {
                        text: t("cancel"),
                        onClick: (e) => {
                            // setBackPermission(false);
                            return { buttonText: e.component.option("text") };
                        },
                    },
                ],
            });
            myDialog.show();
        } else {
            history(-1);
        }
    }
    function LockHandler() {
        setIsLocked(!isLocked);
    }

    //ask15
    async function onAdd() {
        try {
            const validationRes = validationRef.current?.instance().validate();
            if (validationRes?.isValid) {
                const postObj = {
                    ...initData,
                    customerId: localStorage.getItem("selectedCustomerId")
                };
                if (activeLoading) activeLoading(true);
                let res = (await TesPost(
                    TrafficApiUrl() + "/api/Setups/AddUpdateAADTPattern",
                    postObj,
                    true
                )) as RequestResponseResult<null>;
                if (res.responseCode === ResponseCode.OK) {
                    history(-1);
                    if (activeLoading) activeLoading(false);
                    notify(t("dataSuccessfullyAdded"), "success", 5000);
                } else {
                    await RequestErrorHandling(res);;
                }
            }
        } catch {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred"), "error", 5000);
        }
    }

    async function onUpdate(backPermission: boolean) {
        try {
            const postObj = {
                ...initData,
            };

            if (activeLoading) activeLoading(true);
            let res = (await TesPost(
                TrafficApiUrl() + "/api/AADT/update",
                postObj,
                true
            )) as RequestResponseResult<null>;
            if (activeLoading) activeLoading(false);
            if (res.responseCode === ResponseCode.OK) {
                if (backPermission === true) {
                    history(-1);
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
            } else if (res.responseCode === ResponseCode.AADTSourceIsWrong) {
                notify(t("aadtSourceIsWrong"), "error", 5000);

            }
            else {
                await RequestErrorHandling(res);;
            }
        } catch {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred"), "error", 5000);
        }
        setDataChanged(false)
    }

    const copyTextToClipboard = (option: string) => {
        if (geoIdRef.current && yearRef.current && locDescriptionRef.current) {
            let textToCopy: string;
            if (option === "Geo ID") {
                textToCopy = geoIdRef.current.innerText;
            } else if (option === "Year") {
                textToCopy = yearRef.current?.innerText!;
            } else {
                textToCopy = locDescriptionRef.current?.innerText!;
            }
            textToCopy = textToCopy.trim().replace(/^\[(.*?)\]\s*-?$/, "$1");
            navigator.clipboard.writeText(textToCopy);
            notify(t("textCopiedToClipboard"), "success", 2000);
        }
    };

    return (
        <Permission
            allowed={[
                TrafficStudyPermissions.TrafficStudy_E_TrafficSectionAADT,
                TrafficStudyPermissions.TrafficStudy_V_TrafficSectionAADT,
            ]}
            hasFeedBackElement={true}
        >
            <div className={`aadtDetails ${compactViewModel ? "compactStyle" : ""}`}>
                <React.Fragment>
                    <div className={"content-block"}>
                        <div className={"dx-card"} style={{ marginBottom: "-1rem" }}>
                            {params.intersectionId !== "AddNew" && (
                                <div style={{ marginTop: "1rem" }}>
                                    <p className={"detailsHeading"} style={{ display: "inline" }}>
                                        <span>{t('trafficSection')}: </span>
                                        {/* <span ref={geoIdRef}>[{params.aadtType === "1" ? initData?.intersectionAADT?.geoId : initData?.roadSegmentAADT?.geoId}] -</span> */}
                                        <span> {t('year')}: </span>
                                        <span ref={yearRef}>{initData?.year}</span>
                                        {/* <span> - {t('andLocation')}: {initData}</span> */}
                                        {/* <span
                                            ref={locDescriptionRef}
                                            onMouseOver={e => { setLocationColor("#F0B70d"); setLocationCursor("pointer") }}
                                            onMouseLeave={e => { setLocationColor("#424242"); setLocationCursor("default") }}
                                            style={{ color: locationColor, cursor: locationCursor }}
                                            onClick={onLocationDetails}
                                        >
                                            {params.aadtType === "1" ? initData?.intersectionAADT?.locationDescription : initData?.roadSegmentAADT?.locationDescription}
                                        </span> */}
                                    </p>
                                    <hr className="line" style={{ display: "block", marginTop: "1rem" }}></hr>
                                </div>
                            )}
                            <div className="row">
                                <div className="leftColumn">
                                    <Button
                                        style={{ marginTop: "-0.5rem" }}
                                        onClick={() => goBackPermission()}
                                        icon="fa-solid fa-arrow-left"
                                        hint={t("goBack")}
                                    />
                                </div>
                                <div className="rightColumn">
                                    {params.aadtId === "AddNew" && (
                                        <Button
                                            onClick={() => onAdd()}
                                            icon="fa-solid fa-floppy-disk"
                                            hint={t("save")}
                                        />
                                    )}
                                    {params.aadtId !== "AddNew" && isLocked === false && (
                                        <Button
                                            onClick={() => onUpdate(false)}
                                            icon="fa-solid fa-floppy-disk"
                                            hint={t("update")}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={"dx-card"}>
                            <TesTabs
                                width={"100%"}
                                dataSource={tabTitles}
                                selectedIndex={selectedIndex}
                                onTabChange={handleTabChange}
                            />
                            {selectedIndex === 0 && (
                                <AADT
                                    initData={initData}
                                    aadtType={AADTType.TrafficSection}
                                    setDataChanged={setDataChanged}
                                    setInitData={setInitData}
                                    isLocked={isLocked}
                                />
                            )}
                        </div>
                    </div>
                </React.Fragment>
            </div>
        </Permission>
    );
};
export default TrafficSectionAADTDetails;
