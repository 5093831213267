import { t } from "i18next";

const tabTitles = [
    {
        id: 0,
        text: 'logs',
        icon: 'fa-solid fa-ellipsis',
        content: 'Details tab content',
    }

];
export default tabTitles;

